
export const SMALL_TYPE = {
    type: 'small',
    title: 'Small',
    main_percent: 90,
    main_descriptions: `of thriving small businesses opt for a Sellavi plan`,
    why_points: [
        {
            title: `Savings of up to 85% on their budget`,
        },
        {
            title: `Automated business management processes save 75% of their time`,
        },
        {
            title: `Frequent platform updates, convenient sales analytics, and live support`,
        },
    ],
    clients_percent: 79,
}

export const MEDIUM_TYPE = {
    type: 'medium',
    title: 'Medium',
    main_percent: 74,
    main_descriptions: `of business owners generating over 30,000 AED monthly revenue choose the Sellavi Plus plan for achieving success in e-commerce.`,
    why_points: [
        {
            title: `Personal manager available for dedicated support and marketing consultations`,
        },
        {
            title: `Business process & logistics management from one convenient dashboard`,
        },
        {
            title: `Tailored store setup to align with the client's specific business needs`,
        },
    ],
    clients_percent: 17,
}

export const LARGE_TYPE = {
    type: 'large',
    title: 'Large',
    main_percent: 17,
    main_descriptions: `of large businesses have embraced Sellavi, achieving a remarkable growth of over 35% by leveraging the power of online commerce.`,
    why_points: [
        {
            title: `Total control and streamlined online business operations`,
        },
        {
            title: `Easy integration of any service via Sellavi's API`,
        },
        {
            title: `Adaptable terms of engagement to cater to the client's unique needs`,
        },
    ],
    clients_percent: 4,
}
