import { useContext } from 'react';
import { TranslationContext } from '../../../../assets/contexts/translationContext';
import './AdaptiveExample.css';
import {motion} from 'framer-motion'

function AdaptiveExample({ file }) {
    const { main } = useContext(TranslationContext);
    const { mainCreateAi } = main;
    const { adaptive } = mainCreateAi;

    return (
        <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1.2 }}
         className='adaptive-example'>
            <div className='adaptive-example__card'>
                <div className='adaptive-example__card__header-box'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="8" viewBox="0 0 17 8" fill="none">
                        <rect y="0.5" width="17" height="1" rx="0.5" fill="black" />
                        <rect y="3.5" width="17" height="1" rx="0.5" fill="black" />
                        <rect y="6.5" width="17" height="1" rx="0.5" fill="black" />
                    </svg>
                    <div className='adaptive-example__card__header-box__island'></div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="14" viewBox="0 0 11 14" fill="none">
                        <path d="M9.33333 3.79167H8.16667C8.16667 2.18167 6.86 0.875 5.25 0.875C3.64 0.875 2.33333 2.18167 2.33333 3.79167H1.16667C0.525 3.79167 0 4.31667 0 4.95833V11.9583C0 12.6 0.525 13.125 1.16667 13.125H9.33333C9.975 13.125 10.5 12.6 10.5 11.9583V4.95833C10.5 4.31667 9.975 3.79167 9.33333 3.79167ZM5.25 2.04167C6.21833 2.04167 7 2.82333 7 3.79167H3.5C3.5 2.82333 4.28167 2.04167 5.25 2.04167ZM9.33333 11.9583H1.16667V4.95833H9.33333V11.9583ZM5.25 7.29167C4.28167 7.29167 3.5 6.51 3.5 5.54167H2.33333C2.33333 7.15167 3.64 8.45833 5.25 8.45833C6.86 8.45833 8.16667 7.15167 8.16667 5.54167H7C7 6.51 6.21833 7.29167 5.25 7.29167Z" fill="black" />
                    </svg>
                </div>
                <img alt='' src={file} className='adaptive-example__card__img'></img>
                <div className='adaptive-example__card__info'>
                    <div className='adaptive-example__card__info_bottom'>
                        <div className='adaptive-example__card__info_bottom__line-1'></div>
                        <div className='adaptive-example__card__info_bottom__line-2'></div>
                    </div>
                    <p className='adaptive-example__card__info__price'>$ 65</p>
                    <div className='pay-example__card__info__button-block'>
                        <button className='pay-example__card__info__button'>
                            <p>-</p>
                            <p>1</p>
                            <p>+</p>
                        </button>
                        <button className='pay-example__card__info__button pay-example__card__info__button_black'>Add to card</button>
                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                            <g clip-path="url(#clip0_104_2301)">
                                <path d="M3.30069 0.00605392C3.2753 0.00807285 3.19304 0.0171556 3.11788 0.0262394C1.60968 0.199831 0.37163 1.33322 0.0699892 2.81581C-0.0650889 3.48192 0.00194235 4.33675 0.243661 5.05938C0.499599 5.81934 0.929208 6.48343 1.66249 7.25147C2.00983 7.6148 2.3064 7.8873 3.2753 8.73507C3.50991 8.93995 3.77093 9.16905 3.85421 9.24474C4.89421 10.1733 5.61632 10.9524 6.23686 11.8153L6.3689 12H6.50296L6.63702 11.999L6.76905 11.8052C7.33476 10.9756 7.99999 10.2308 8.98819 9.32145C9.18218 9.1418 9.40257 8.945 10.0312 8.38991C11.0022 7.53003 11.4988 7.03448 11.901 6.52279C12.5541 5.69016 12.8841 4.90496 12.9816 3.94617C13.0009 3.74836 12.9989 3.27502 12.9766 3.10345C12.8699 2.291 12.5287 1.5926 11.963 1.03045C11.3973 0.468291 10.6934 0.128174 9.87687 0.0232115C9.68085 -0.00101089 9.25429 -0.00101089 9.05421 0.0232115C8.30976 0.117072 7.65366 0.413792 7.10421 0.905297C6.91022 1.07889 6.69186 1.32616 6.56085 1.52094C6.53038 1.56636 6.50296 1.6037 6.49991 1.6037C6.49686 1.6037 6.46944 1.56636 6.43897 1.52094C6.30796 1.32616 6.0896 1.07889 5.89561 0.905297C5.35632 0.422875 4.70733 0.125146 3.98827 0.0302763C3.83999 0.0100918 3.41749 -0.00403786 3.30069 0.00605392Z" fill="black" />
                            </g>
                            <defs>
                                <clipPath id="clip0_104_2301">
                                    <rect width="13" height="12" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                </div>
            </div>
            <p className='adaptive-example__text'>{adaptive.textAfterCard}</p>
        </motion.div>
    );
}

export default AdaptiveExample