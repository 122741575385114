import { useContext, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Mousewheel, Navigation } from "swiper/modules";
import ReviewSlide from "./ReviewSlide/ReviewSlide";
// import slide_1 from '../../../assets/images/reviews/bogoleva.webp'
// import slide_1_mobile from '../../../assets/images/reviews/bogoleva-mobile.webp'

import author_1 from '../../../assets/images/reviews/author-1.webp'
import author_2 from '../../../assets/images/reviews/author-2.webp'
import author_3 from '../../../assets/images/reviews/author-3.webp'
import author_4 from '../../../assets/images/reviews/author-4.webp'
import author_5 from '../../../assets/images/reviews/author-5.webp'
import author_6 from '../../../assets/images/reviews/author-6.webp'
import author_7 from '../../../assets/images/reviews/author-7.webp'
import author_8 from '../../../assets/images/reviews/author-8.webp'
import author_9 from '../../../assets/images/reviews/author-9.webp'
import author_10 from '../../../assets/images/reviews/author-10.webp'

import author_logo_1_black from '../../../assets/images/reviews/author-logo-1-black.webp'
import author_logo_1_white from '../../../assets/images/reviews/author-logo-1-white.webp'
import author_logo_2_black from '../../../assets/images/reviews/author-logo-2-black.webp'
import author_logo_2_white from '../../../assets/images/reviews/author-logo-2-white.webp'
import author_logo_3_black from '../../../assets/images/reviews/author-logo-3-black.webp'
import author_logo_3_white from '../../../assets/images/reviews/author-logo-3-white.webp'
import author_logo_4_black from '../../../assets/images/reviews/author-logo-4-black.webp'
import author_logo_4_white from '../../../assets/images/reviews/author-logo-4-white.webp'
import author_logo_5_black from '../../../assets/images/reviews/author-logo-5-black.webp'
import author_logo_5_white from '../../../assets/images/reviews/author-logo-5-white.webp'
import author_logo_6_black from '../../../assets/images/reviews/author-logo-6-black.webp'
import author_logo_6_white from '../../../assets/images/reviews/author-logo-6-white.webp'
import author_logo_7_black from '../../../assets/images/reviews/author-logo-7-black.webp'
import author_logo_7_white from '../../../assets/images/reviews/author-logo-7-white.webp'
import author_logo_8_black from '../../../assets/images/reviews/author-logo-8-black.webp'
import author_logo_8_white from '../../../assets/images/reviews/author-logo-8-white.webp'
import author_logo_9_black from '../../../assets/images/reviews/author-logo-9-black.webp'
import author_logo_9_white from '../../../assets/images/reviews/author-logo-9-white.webp'
import author_logo_10_black from '../../../assets/images/reviews/author-logo-10-black.webp'
import author_logo_10_white from '../../../assets/images/reviews/author-logo-10-white.webp'

import card_pc_1 from '../../../assets/images/reviews/cards/pc1.webp'
import card_pc_2 from '../../../assets/images/reviews/cards/pc2.webp'
import card_pc_3 from '../../../assets/images/reviews/cards/pc3.webp'
import card_pc_4 from '../../../assets/images/reviews/cards/pc4.webp'
import card_pc_5 from '../../../assets/images/reviews/cards/pc5.webp'
import card_pc_6 from '../../../assets/images/reviews/cards/pc6.webp'
import card_pc_7 from '../../../assets/images/reviews/cards/pc7.webp'
import card_pc_8 from '../../../assets/images/reviews/cards/pc8.webp'
import card_pc_9 from '../../../assets/images/reviews/cards/pc9.webp'
import card_pc_10 from '../../../assets/images/reviews/cards/pc10.webp'

import card_mobile_1 from '../../../assets/images/reviews/cards/phone1.webp'
import card_mobile_2 from '../../../assets/images/reviews/cards/phone2.webp'
import card_mobile_3 from '../../../assets/images/reviews/cards/phone3.webp'
import card_mobile_4 from '../../../assets/images/reviews/cards/phone4.webp'
import card_mobile_5 from '../../../assets/images/reviews/cards/phone5.webp'
import card_mobile_6 from '../../../assets/images/reviews/cards/phone6.webp'
import card_mobile_7 from '../../../assets/images/reviews/cards/phone7.webp'
import card_mobile_8 from '../../../assets/images/reviews/cards/phone8.webp'
import card_mobile_9 from '../../../assets/images/reviews/cards/phone9.webp'
import card_mobile_10 from '../../../assets/images/reviews/cards/phone10.webp'

import "swiper/css";
import "swiper/css/pagination";
import './Reviews.css';
import { TranslationContext } from "../../../assets/contexts/translationContext";

function Reviews({ theme }) {
    const { main } = useContext(TranslationContext);
    const { mainReviews } = main;
    const { revFirst, revSecond, revThird, revFour, revFive, revSix, revSeven, revEight, revNine, revTen } = mainReviews;

    const REVIEWS = [
        {
            author: {
                name: `${revFirst.name}`,
                site: 'maison.bg',
                img: author_1,
                logo: author_logo_1_black,
                logo_theme_white: author_logo_1_white,
            },
            description: `${revFirst.description}`,
            img_pc: card_pc_1,
            img_mobile: card_mobile_1,

        },
   
        {
            author: {
                name: `${revThird.name}`,
                site: 'houseoflememe.com',
                img: author_3,
                logo: author_logo_3_black,
                logo_theme_white: author_logo_3_white
            },
            description: `${revThird.description}`,
            img_pc: card_pc_3,
            img_mobile: card_mobile_3,
        },
        {
            author: {
                name: `${revFour.name}`,
                site: 'trendyconfession.com',
                img: author_4,
                logo: author_logo_4_black,
                logo_theme_white: author_logo_4_white
            },
            description: `${revFour.description}`,
            img_pc: card_pc_4,
            img_mobile: card_mobile_4,
        },
        {
            author: {
                name: `${revFive.name}`,
                site: 'invoke.bg',
                img: author_5,
                logo: author_logo_5_black,
                logo_theme_white: author_logo_5_white
            },
            description: `${revFive.description}`,
            img_pc: card_pc_5,
            img_mobile: card_mobile_5,
        },
        {
            author: {
                name: `${revSecond.name}`,
                site: 'roue.bg',
                img: author_2,
                logo: author_logo_2_black,
                logo_theme_white: author_logo_2_white
            },
            description: `${revSecond.description}`,
            img_pc: card_pc_2,
            img_mobile: card_mobile_2,

        },
        {
            author: {
                name: `${revSix.name}`,
                site: 'bolgeva.com',
                img: author_6,
                logo: author_logo_6_black,
                logo_theme_white: author_logo_6_white
            },
            description: `${revSix.description}`,
            img_pc: card_pc_6,
            img_mobile: card_mobile_6,
        },
        {
            author: {
                name: `${revSeven.name}`,
                site: 'altinbas.bg',
                img: author_7,
                logo: author_logo_7_black,
                logo_theme_white: author_logo_7_white
            },
            description: `${revSeven.description}`,
            img_pc: card_pc_7,
            img_mobile: card_mobile_7,
        },
        {
            author: {
                name: `${revEight.name}`,
                site: 'bambinibg.com',
                img: author_8,
                logo: author_logo_8_black,
                logo_theme_white: author_logo_8_white
            },
            description: `${revEight.description}`,
            img_pc: card_pc_8,
            img_mobile: card_mobile_8,
        },
        {
            author: {
                name: `${revNine.name}`,
                site: 'scrapbooky.net',
                img: author_9,
                logo: author_logo_9_black,
                logo_theme_white: author_logo_9_white
            },
            description: `${revNine.description}`,
            img_pc: card_pc_9,
            img_mobile: card_mobile_9,
        },
        {
            author: {
                name: `${revTen.name}`,
                site: 'gabrielleshop.eu',
                img: author_10,
                logo: author_logo_10_black,
                logo_theme_white: author_logo_10_white
            },
            description: `${revTen.description}`,
            img_pc: card_pc_10,
            img_mobile: card_mobile_10,
        },
    ]
    // const [my_swiper, set_my_swiper] = useState({});

    // Preload images
    useEffect(() => {
        const imagesToPreload = [card_pc_1, card_pc_2, card_pc_3, card_pc_4, card_pc_5, card_pc_6, card_pc_7, card_pc_8, card_pc_9, card_pc_10, card_mobile_1, card_mobile_2, card_mobile_3, card_mobile_4, card_mobile_5, card_mobile_6, card_mobile_7, card_mobile_8, card_mobile_9, card_mobile_10];
        imagesToPreload.forEach((imageSrc) => {
            const img = new Image();
            img.src = imageSrc;
        });
    }, []);

    return (
        <div className='reviews' id='reviews' data-section>
            <div className='reviews__content'>
                <Swiper
                    slidesPerView={1}
                    loop={true}
                    spaceBetween={16}
                    pagination={{
                        clickable: true,
                    }}
                    mousewheel={{
                        forceToAxis: true,
                    }}
                    modules={[Mousewheel, Navigation, Pagination]}
                    preventInteractionOnTransition={true}
                    className="reviews__slides reviews__slides__pagination"
                // onInit={(ev) => {
                //     set_my_swiper(ev)
                // }}
                >
                    {
                        REVIEWS.map((slide, i) => (
                            <SwiperSlide className="reviews__slide" key={`review-slide-${i}`}>
                                <ReviewSlide index={i + 1} {...{ slide, theme }} />
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
        </div>
    );
}

export default Reviews