import "./CustomInput.css";

function CustomInput({ onChange, onBlur, isPrelouderVisible, ...props }) {
  return (
    <div className={`custom-input`}>
      {props.label || props.hint ? (
        <div className="custom-input__input-heading">
          {props.label ? (
            <label className="custom-input__label" htmlFor={props.name}>
              {props.label}
            </label>
          ) : null}
          {props.error ? (
            !props.show_eror_color_only ? (
              <span
                className="custom-input__error"
                dangerouslySetInnerHTML={{ __html: props.error }}
              />
            ) : null
          ) : props.hint ? (
            <span
              className="custom-input__hint"
              dangerouslySetInnerHTML={{ __html: props.hint }}
            />
          ) : null}
        </div>
      ) : null}

      <input
        className={`custom-input__input`}
        id={props.name}
        name={props.name}
        placeholder={props.placeholder}
        type={props.type ? props.type : "text"}
        autoComplete="off"
        value={props.value || ""}
        onChange={onChange}
      />
    </div>
  );
}

export default CustomInput;
