import "./AuthInput.css";
import { createRef, useEffect, useRef, useState } from 'react';

function AuthInput({
  onChange,
  name,
  type = "text",
  inputMode = "text",
  placeholder = "",
  value,
  error,
  icon,
}) {
  const [focused, setFocused] = useState(true)
  const ref = createRef(null);

  const handleMouseOut = (currentRef) => {
    setFocused(false)
  };

  const handleMouseOn = (currentRef) => {
    if (document.activeElement === currentRef) {
      setFocused(true)
    }
  };

  return (
    <div
      className={`auth-input ${value ? 'auth-input_inactive' : ''} ${error?.validState ? 'auth-input_valid' : ''} ${!focused && !error?.validState && value ? 'auth-input_not-valid' : ''}`}
    >
      {icon({
        mainClassName: "auth-input__icon",
        fillClassName: "auth-input__icon-fill",
      })}
      <input
        ref={ref}
        onMouseEnter={() => handleMouseOn(ref.current)}
        onFocus={() => handleMouseOn(ref.current)}
        onBlur={() => handleMouseOut(ref.current)}
        className="auth-input__input"
        name={name}
        id={name}
        type={type}
        inputMode={inputMode}
        placeholder={placeholder}
        value={value || ""}
        onChange={onChange}
      />
    </div>
  );
}

export default AuthInput;
