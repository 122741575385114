import CreateWithAI from './CreateWithAI/CreateWithAI';
import HeadBanner from './HeadBanner/HeadBanner';
import Join from './Join/Join';
import Logistics from './Logistics/Logistics';
import Magnati from './Magnati/Magnati';
import './Main.css';
import MainNav from './MainNav/MainNav';
import Marketing from './Marketing/Marketing';
import MobileFirst from './MobileFirst/MobileFirst';
import Operates from './Operates/Operates';
import Reviews from './Reviews/Reviews';
import SayAboutUs from './SayAboutUs/SayAboutUs';
import Steps from './Steps/Steps';
import Store from './Store/Store';

function Main({ theme, onSignupOpen, onEmailChange, email, emailValidity, language, lang }) {
    return (
        <div className='main'>
            <HeadBanner {...{ onSignupOpen, onEmailChange, email, emailValidity }} />
            <Reviews theme={theme} />
            <Steps />
            <MobileFirst />
            <Store {...{ onSignupOpen }} />
            <CreateWithAI {...{ onSignupOpen, onEmailChange, email, emailValidity }} theme={theme} />
            <Operates />
            <Magnati theme={theme} language={language} lang={lang}/>
            <Join />
            <SayAboutUs />
            <Logistics />
            <Marketing />
            <MainNav {...{ onSignupOpen }} />
        </div>
    );
}

export default Main