import { useEffect, useState } from "react";
import "./ImageElement.css";
import { PHOTO_URL } from "../../../../assets/utils/constants";

function ImageElement({ data }) {
  const { alternativeText, ext, hash } = data;
  const [isImgLoaded, setIsImgLoaded] = useState(false);

  useEffect(() => {
    const url = `${PHOTO_URL}/${hash}${ext}`;
    loadImage(url);
  }, [ext, hash]);

  const loadImage = (imageUrl) => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      setIsImgLoaded(true);
    };
    img.onerror = (err) => {
      console.log("img error:", err);
    };
  };

  return (
    <div className="img-element">
      {isImgLoaded ? (
        <img
          className="img-element__picture"
          src={`${PHOTO_URL}/${hash}${ext}`}
          alt={alternativeText}
        />
      ) : null}
    </div>
  );
}

export default ImageElement;
