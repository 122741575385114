import './SayAboutUs.css';
import pc from '../../../assets/images/sayaboutus/pc.webp';
import pc2 from '../../../assets/images/sayaboutus/pc2.webp';
import pc3 from '../../../assets/images/sayaboutus/pc3.webp';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import { useContext } from 'react';

function SayAboutUs() {
    const { main } = useContext(TranslationContext);
    const { mainSayAboutUs } = main;

    return (
        <div className='say-about-us'>
            <h3 className='say-about-us__title'>{mainSayAboutUs.title}</h3>
            <p className='say-about-us__subtitle'>{mainSayAboutUs.subtitle}</p>
            <div className='say-about-us__box-card'>
                <div className='say-about-us__card say-about-us__card_big '>
                    <div className='say-about-us__card__text-box'>
                        <p className='say-about-us__card__title'>{mainSayAboutUs.cardFirstTitle}</p>
                        <p className='say-about-us__card__author'>{mainSayAboutUs.authorFirst}</p>
                    </div>
                    <img alt='' src={pc} className='say-about-us__card__img'></img>
                </div>
                <div className='say-about-us__card say-about-us__card_small say-about-us__card_small-first'>
                    <div className='say-about-us__card__text-box'>
                        <p className='say-about-us__card__title'>{mainSayAboutUs.cardSecondTitle}</p>
                        <p className='say-about-us__card__author'>{mainSayAboutUs.authorSecond}</p>
                    </div>
                    <img alt='' src={pc2} className='say-about-us__card__img'></img>
                </div>
                <div className='say-about-us__card say-about-us__card_small say-about-us__card_small-second'>
                    <div className='say-about-us__card__text-box'>
                        <p className='say-about-us__card__title'>{mainSayAboutUs.cardThirdTitle}</p>
                        <p className='say-about-us__card__author'>{mainSayAboutUs.authorThird}</p>
                    </div>
                    <img alt='' src={pc3} className='say-about-us__card__img'></img>
                </div>
            </div>
        </div>
    );
}

export default SayAboutUs