import {
  HEADING_BLOCK,
  PROMO_START_VAR,
} from "../../../../assets/utils/constants";
import { renderArticleChildren } from "../../../../assets/utils/utils";
import PromoStart from "../PromoStart/PromoStart";
import "./TextElement.css";

function TextElement({ data, index, onSignupOpen }) {
  const { type, level, children } = data;

  return (
    <div className="text-element">
      {type === HEADING_BLOCK && level === 1 ? (
        <h2 className="text-element__title" id={`${type}-${level}${index}`}>
          {renderArticleChildren(children)}
        </h2>
      ) : type === HEADING_BLOCK && level === 2 ? (
        <h3 className="text-element__subtitle" id={`${type}-${level}${index}`}>
          {renderArticleChildren(children)}
        </h3>
      ) : children.some((el) => el.text?.includes(PROMO_START_VAR)) ? (
        <PromoStart {...{ onSignupOpen }} />
      ) : (
        <p className="text-element__text">{renderArticleChildren(children)}</p>
      )}
    </div>
  );
}

export default TextElement;
