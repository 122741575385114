export const EN_TRANSLATION = {
    header: {
        headerLink: {
            aboutUs: {
                name: 'About us'
            },
            prices: {
                name: 'Prices'
            },
            affiliateProgram: {
                name: 'Affiliate program'
            },
            howToThriveInEcomm: {
                name: 'How to thrive in e-commerce'
            }
        },
        signIn: "Sign In",
        language: "Language",
    },

    footer: {
        greenTitle: 'Enjoy a free 14-day trial!',
        whiteTitle: 'No credit card required',
        subtitle: 'Launch your online store and kickstart sales today!',
        textBtn: 'START NOW',
        freeTrial: 'Free trial',
        rightsReserved: 'All rights reserved 2024 | Sellavi',
        footerLink: {
            title1: 'About us',
            title2: 'Prices',
            title3: 'Affiliate program',
            title4: 'How to thrive in e-commerce',
            mobileTitle4: 'How to do business',
        },
        mail: 'info.bg@sellavi.com',
    },

    blogArticle: {
        content: 'Content',
        bannerTitleFirstLine: 'START SELLING',
        bannerTitleSecondLine: 'ON SELLAVI',
        bannerBtnText: 'RIGHT NOW!',
        greenLineText: 'If you have any questions, our qualified support team is always happy to help.',
        like: 'Like?',
        readTime: 'Reading time',
        shareSocial: 'Share in social media',
        min: 'min'
    },
    popupSignUp: {
        back: 'Back',
        signupTitle: 'Start a 14-day free trial right now!',
        signupText: {
            preambulaTermsService: 'By creating an account, you agree to our',
            termsService: 'Terms of Service ↗',
            preambulaPrivacyPolicy: 'and have read and understood the',
            privacyPolicy: 'Privacy Policy ↗'
        },
        inputEmail: 'your email',
        inputPass: 'password',
        submitBtn: 'Create account'
    },
    main: {
        mainBanner: {
            bannerTitle: 'Your online store is 5 minutes away!',
            bannerPlaceholder: 'Email address',
            bannerBtn: 'Start free trial'
        },
        mainNav: {
            navLink: {
                start: {
                    name: 'Start trial'
                },
                reviews: {
                    name: 'Customer reviews'
                },
                steps: {
                    name: 'Setup steps'
                },
                features: {
                    name: 'Features'
                },
                ai: {
                    name: 'AI commerce'
                },
                examples: {
                    name: 'Examples'
                },
                benefits: {
                    name: 'Benefits'
                },
            },
            navBtn: 'Start free trial'
        },
        mainReviews: {
            revFirst: {
                name: 'Anna',
                description: `We opted for Sellavi due to the platform's reliability and security. The store maintenance used to be our biggest challenge, but Sellavi has made the process smooth and hassle-free. The support we received from the team while setting up the online store left a lasting impression. The Sellavi team's remarkable patience and commitment truly stand out, and the outcome reflects the exceptional quality of their services.`,
            },
            revSecond: {
                name: 'Isabelle',
                description: `I chose Sellavi for my online store because they truly listened to my ideas.
                I submitted a request, spoke with a manager about my needs, paid for the Sellavi Plus plan, and within a couple of weeks, I had a ready-made website that perfectly reflected my brand. The visual design and the functionality, especially for my customizable products, were top-notch. I’ve been a client for some time now and have no plans to switch.`,
            },
            revThird: {
                name: 'Nia Nikolova',
                description: `The interaction with the Sellavi team is effortless and enjoyable; they provide essential instructions for the workflow and help us bring our desires and vision to life. Working on the platform is incredibly easy due to the user-friendly interface. With minimal effort, we accomplish remarkable outcomes.`,
            },
            revFour: {
                name: 'Klementina Mileva',
                description: `Choosing Sellavi to create my online store has been an absolute breeze. The platform's user-friendly interface made the entire process smooth and fast. Not only that, but the end result exceeded my expectations – the store looks amazing! My online shop received amazing reviews from customers right after launching!`,
            },
            revFive: {
                name: 'Tоdor Angelov',
                description: `I chose Sellavi to power my online store because it delivers everything I envisioned in a website-building platform: a diverse range of themes tailored for different store types, sleek and clean design, seamless integration with Google Analytics, automatic listings on Facebook and Instagram catalogs, and reliable sales analysis tools. It was the best decision - my store is thriving like never before!`,
            },
            revSix: {
                name: 'Borislava Georgieva',
                description: `I came across Sellavi when I was on the hunt for the perfect platform to build my brand's online store. What stood out to me was the ease and user-friendliness the platform offered, as well as the team's quick response and dedication. I'm absolutely delighted with the final product and have received a ton of compliments from clients and friends.`,
            },
            revSeven: {
                name: 'Altinbas',
                description: `The Sellavi platform operates flawlessly! I'm particularly fond of the product section; it's user-friendly, and the outcome on the website looks stunning. Our customers effortlessly pick their desired items and proceed to place their orders directly, reducing the need for inquiries through contact forms. Honestly, collaborating with Sellavi is the best you can do for your business.`,
            },
            revEight: {
                name: 'Miglena',
                description: `I created my online store using the Sellavi platform, and I confidently state that I'm absolutely delighted with everything it offers: the communication with the team, the diverse array of features, and the user-friendly nature of the platform itself. I wholeheartedly recommend this platform to anyone seeking a straightforward and effective solution for establishing an online shop.`,
            },
            revNine: {
                name: 'Kremena Ivanova-Milusheva',
                description: `Discovering Sellavi while searching for a platform for our online store has been an absolute happiness! I had experimented with various options before and was almost ready to give up, but then I found this platform. Sellavi responded positively to all my queries and requests, displaying patience and empathy. Each team member who contributed to our store possesses a distinct persona and a customer-centric approach that ensures every client feels valued!`,
            },
            revTen: {
                name: 'Gabriella',
                description: `What truly stood out to me about the Sellavi platform is the wide variety of functionalities and extensions. My customers are happier than ever: their feedback is extremely positive, and they find the shop so easy to work with. This is what matters to me the most!`,
            }
        },
        mainSteps: {
            step1Text: "Choose a template",
            step2Text: "Upload your products",
            step3Text: "Sell to everyone, everywhere",
            step4Text: "Track and analyze your profits",
            step: 'Step'
        },
        mainMobileFirst: {
            title: 'Mobile-first approach',
            text: `Sellavi is built for mobile commerce. We provide free advanced mobile
            optimization, ensuring your store functions flawlessly and looks
            stunning across all portable devices.`
        },
        mainFeatures: {
            titleFirstLine: `A store that has`,
            titleSecondLine: `everything`,
            text: 'Manage your store from one place: all you need is already in your admin panel',
            iconText: {
                text1: 'Unlimited Hosting',
                text2: 'Customizable Templates',
                text3: 'Advanced CRM',
                text4: 'Free SSL',
                text5: 'Built-in SEO',
                text6: 'Multiple Currencies',
                text7: 'Multiple Languages',
                text8: 'Multi-User Admin Panel',
                text9: 'Integrated Online Payments',
                text10: 'Apple Pay & Google Pay',
                text11: 'Invoice Management',
                text12: 'Advanced Reports',
                text13: 'Advanced Promotion System',
                text14: 'SMS Notifications',
                text15: 'Facebook Pixel API',
                text16: 'Google Analytics API',
                text17: 'Abandoned Cart Management',
                text18: 'Customer Groups',
                text19: 'Subscription Management',
                text20: 'Booking',
                text21: 'Product Reviews & Rating',
                text22: 'Fulfillment',
                text23: 'Coupon Generator',
                text24: 'Blog',
                text25: 'Advanced Delivery Management',
                text26: 'JS and CSS Editors',
                text27: 'Digital Products',
                text28: 'Checkout Customization',
                text29: 'Rich API with Webhooks',
                text30: 'Analytics Dashboard',
                text31: 'Modular Page Builder',
                text32: 'Google ADS Connection',
                text33: 'Exclusive Offers from Partners',
                text34: 'Instagram Shopping & Facebook Catalog',
                text35: 'Google Shopping',
                text36: 'TikTok Shop',
                text37: 'Complementary Products',
                text38: 'Sales Pages',
                text39: 'Video and Image Banners',
                text40: 'Product Filters & Sorting',
                text41: 'Google Rich Snippets',
                text42: 'Email Marketing',
            },
            textBtn: 'Start 14-day trial'
        },
        mainCreateAi: {
            titleFirstLine: 'Create your store with the power of',
            discriptionBeforeBtn: 'Simply upload your product photo and witness as your online store prototype comes to life',
            textBtn: 'Upload your product photo',
            orangeTextBtn: 'see the magic',
            loading: 'Loading...',
            startTrialTextBtn: 'Start free trial',
            placeholderEmail: 'Email address',
            facebook: {
                textAfterCard: 'Your Facebook campaign is up & running'
            },
            instagram: {
                textAfterCard: 'Your Instagram catalog is filled with products'
            },
            pay: {
                textAfterCard: 'Your products are so easy to buy now that you can accept online payments worldwide'
            },
            adaptive: {
                textAfterCard: 'Your content adapts instantly to any screen size'
            },
            google: {
                textAfterCard: 'You can sell right in Google Shopping'
            }
        },
        mainOperates: {
            title: 'We operate in Bulgaria, so you can go global',
            errorVideo: 'Your browser does not support the video tag.'
        },
        mainMagnati: {
            title: 'is our trusted partner',
            text: 'for your secure online payments',
            animateText: {
                first: 'Verification of counterparties',
                second: ' Remote signing of documents',
                third: 'Online banking',
                forth: 'ATMs with a deposit function',
                five: 'Consultation with experts',
                six: 'Brokerage services',
                seven: 'Business lawyer',
                eight: 'Administration',
                nine: 'Document builder'
            }
        },
        mainJoin: {
            title: 'Join the ranks of thousands of businesses that already thrive with Sellavi',
            joinCards: {
                column1: {
                    title1: 'Swimsuit store',
                    title2: 'Cases store',
                    title3: 'Jewelry store',
                    title4: 'Flower store',
                    title5: 'Home appliance store',
                },
                column2: {
                    title1: 'Fashion jewelry store',
                    title2: 'Clothes store',
                    title3: 'Digital postcard store',
                    title4: 'Fashion store',
                    title5: 'Aluminum can company',
                    title6: 'Chandeliers and night lights',
                },
                column3: {
                    title1: 'Furniture store',
                    title2: 'Perfume store',
                    title3: 'Figurines and playing cards store',
                    title4: 'Clothes store',
                    title5: 'Cleaning services',
                },
                column4: {
                    title1: 'Fashion store',
                    title2: 'Clothes store',
                    title3: 'Store for sailors',
                    title4: 'Custom accessories store',
                    title5: 'Clothes store',
                },
                column5: {
                    title1: 'Jewelry store',
                    title2: `Children's clothing store`,
                    title3: 'Pets shop',
                    title4: 'Healthy food store',
                    title5: 'Swimsuit store',
                }
            }
        },
        mainSayAboutUs: {
            title: 'Grow your business with Sellavi',
            subtitle: 'This is what our successful customers say about us',
            cardFirstTitle: 'I raised my sales by 70% thanks to Sellavi',
            authorFirst: 'Klementina Mileva',
            cardSecondTitle: 'Everything for smooth business operations is already here',
            authorSecond: 'Altinbas',
            cardThirdTitle: 'Within one week, we were ready to sell',
            authorThird: 'DB Prive'
        },
        mainLogistic: {
            titleFirstLine: `Built-in logistics`,
            titleSecondLine: 'solutions',
            subtitle: 'Global delivery services, warehouses, and fulfillment solutions - all in one place',
            errorVideo: 'Your browser does not support the video tag.'
        },
        mainMarketing: {
            titleFirstLine: `Sell to everyone,`,
            titleSecondLine: `everywhere`,
            subtitle: 'Diverse marketing channels to increase your sales and audience reach',
            errorVideo: 'Your browser does not support the video tag.'
        }
    },
    aboutUs: {
        mainTitleNumber: '250 000+',
        mainTitle: 'online stores thrive with Sellavi',
        mainText: `Welcome to Sellavi, a client-oriented global e-commerce platform. We are here to liberate any kind of business from the never-ending hustle with online stores. We prioritize our merchants, ensuring a seamless and trouble-free experience in the world of e-commerce. Say goodbye to the constant challenges, and let us simplify and optimize your online business journey.`,
        mainTextLastLine: 'Embrace the ease, embrace Sellavi.',
        successNumbers: {
            title: 'Success in numbers',
            conversionRate: 'Conversion rate',
            monthlyVisitors: 'Monthly visitors',
            averageTransaction: 'Average transaction',
            transactionsPerDay: 'Transactions per day',
            productsStorage: 'Products in storage',
            million: 'million',
            over: 'over'
        },
        ourStory: {
            title: 'Our story',
            textFirstPart: `Our journey began in 2015 with a vision to revolutionize e-commerce. We assembled a high-tech, international team to develop a game-changing solution — a one-stop shop transforming online businesses worldwide. Thus, Sellavi was born.`,
            textSecondPart: `Today, we're proudly operating worldwide, serving over 250,000+ customers globally. Our open SaaS platform empowers businesses of all sizes, providing essential tools to start, run, and expand effortlessly. But it's not just about business growth — it's about creating unforgettable shopping experiences for your customers. Join us as we redefine the future of e-commerce together.`
        },
        mission: {
            title: 'Our mission',
            text1: 'Help businesses of all sizes to sell effortlessly',
            text2: 'Provide a comfortable start and fast growth',
            text3: 'Give everything a business needs for success, fast and right on the spot',
            text4: 'Automate technical processes and optimize online business management',
        },
        bestEcomm: {
            titleFirstLine: 'Best',
            titleSecondLine: 'E-commerce technology'
        },
        globalBlock: {
            title: 'Those who started selling with us locally are now successful globally',
            textLeft: 'Sellavi works across the globe',
            textRight: 'With us, you can expand your presence and reach a wider audience day by day'
        },
        departments: {
            title: 'Departments',
            joinBtn: 'Join our team',
            cards: {
                card1: 'Research & Development',
                card2: 'Quality Assurance',
                card3: 'Support',
                card4: 'E-commerce Specialists',
                card5: 'Marketing & Design',
                card6: 'Partnership',
                card7: 'Legal Department',
                card8: 'Cyber Security',
                card9: 'Logistics'
            },
            form: {
                title: 'Registration form',
                textBtn: 'Become our partner',
                textBtnResumeFile: 'Resume/CV Upload',
                fullName: 'Full name',
                email: 'E-mail',
                phone: 'Phone number',
                linkedin: 'LinkedIn Profile (optional)',
                position: 'Position You’re Applying For',
                why: 'Why Do You Want to Join Our Team?',
                comments: 'Additional Comments (optional)'
            }
        },
        ourPartners: {
            title: 'Our partners'
        }
    },
    prices: {
        choosePlan: {
            title: 'Choose your plan',
            trialBtn: 'Start free trial',
            pricePer: 'month',
            standartPlan: {
                priceNumber: 32,
                priceCurrency: 'BGN',
                description: 'An online store with favorable conditions for sales: online payment solutions, integration with advertising tools, logistic solutions, social media, advanced analytics, and live support.',
                keyPoints: {
                    point1: 'Unlimited products',
                    point2: 'Unlimited storage',
                    point3: 'Unlimited bandwidth',
                    point4: 'Online payments',
                    point5: 'No additional transaction fees',
                    point6: 'Real-time shipping rates',
                    point7: 'Design templates',
                    point8: 'Sales channels: Facebook, Instagram, Google, TikTok, WhatsApp',
                    point9: 'Products & Orders management',
                    point10: 'CRM system',
                    point11: 'Wholesale prices and discounts',
                    point12: 'Promotions and coupons',
                    point13: 'Multi-currency',
                    point14: 'Multi-language',
                    point15: 'Multi user admin panel',
                    point16: 'Real-time dashboard',
                    point17: 'Connect your own domain',
                    point18: 'Free SSL',
                    point19: 'Analytical tools',
                },
                keyPointStar: 'when purchased for 3 years',
                keyPointLast: 'All future updates are free!'
            },
            plusPlan: {
                priceNumber: 299,
                priceCurrency: 'BGN',
                description: `Empower your business possibilities with a personal e-commerce specialist, custom design, and personal support manager',
                `,
                keyPointsPlus: {
                    title: 'Everything in Sellavi, plus:',
                    point1: 'Personal e-commerce specialist',
                    point2: 'Individual design of the store',
                    point3: 'Multichannel live support',
                }
            }
        },
        features: {
            title: 'Compare plan features',
            textBtnCreateAcc: 'Create account',
            textBtnTrial: 'Free 14-days trial',
            sales: {
                title: 'Sales Channels',
                items: {
                    title1: 'Online store',
                    title2: 'Instagram Shop',
                    title3: 'Facebook Shop',
                    title4: 'TikTok Shop',
                    title5: 'Google Shopping',
                    title6: 'WhatsApp',
                    title7: 'SMS',
                    title8: 'Newsletters'
                }
            },
            onlineStore: {
                title: 'Online Store',
                items: {
                    title1: 'Order management',
                    title2: 'Product management',
                    title3: 'Inventory tracking',
                    title4: 'Client management',
                    title5: 'Booking management',
                    title6: 'Product review management',
                    title7: 'Design templates',
                    title8: 'Multi-language online store',
                    title9: 'Personal accounts for customers',
                    title10: 'Buy/Connect your own Domain',
                    title11: 'Free SSL certificate',
                    title12: 'Product sorting and filtering options',
                    title13: 'Persistent cart',
                    title14: 'Single-page checkout',
                    title15: 'Guest checkout',
                    title16: 'Custom fields at checkout',
                    title17: 'Purchase restriction mode',
                    title18: 'GDPR compliance'
                }
            },
            admin: {
                title: 'Admin Panel',
                items: {
                    title1: 'Real-time dashboard',
                    title2: 'Store extensions',
                    title3: 'Multi-language admin panel',
                    title4: 'Multi-user admin panel',
                    title5: 'User roles with 100+ permissions',
                    title6: 'Maintenance mode',
                    title7: "Access to Sellavi's API",
                    title8: 'Access to Webhooks'
                }
            },
            productPrice: {
                title: 'Products, Prices, and Inventory',
                items: {
                    title1: 'Unlimited products',
                    title2: 'Unlimited categories',
                    title3: 'Unlimited media storage',
                    title4: 'Digital products',
                    title5: 'Advanced inventory tracking',
                    title6: 'Complementary products',
                    title7: 'Related products',
                    title8: 'Product rating and reviews',
                    title9: 'Hidden products',
                    title10: 'Hidden categories',
                    title11: 'Hidden prices',
                    title12: 'Scheduled discounts',
                    title13: 'Wholesale prices',
                    title14: 'Multi-currency online store',
                    title15: 'Product options',
                    title16: 'Product filters',
                    title17: 'Stickers',
                    title18: 'Brands',
                    title19: 'Import / Export',
                    title20: 'Bulk update'
                }
            },
            shipping: {
                title: "Shipping & Delivery",
                items: {
                    title1: 'Built-in shipping services',
                    title2: 'International shipping',
                    title3: 'Real-time shipping rates',
                    title4: 'Custom delivery methods',
                    title5: 'Conditional delivery',
                    title6: 'Zonal delivery',
                    title7: 'Deliveries, bound to products',
                    title8: 'Quick delivery order from the interface',
                    title9: 'Built-in shipment tracking system',
                    title10: 'Fulfillment',
                    title11: 'Warehouses',
                    title12: 'Pickup points'
                }
            },
            payments: {
                title: 'Payments',
                items: {
                    title1: 'Online payment solution',
                    title2: 'Apple Pay and Google Pay',
                    title3: 'Free vPOS setup',
                    title4: 'Special rate on transaction fees',
                    title5: 'Online invoicing',
                    title6: 'Cash on delivery',
                    title7: 'Bank wire',
                    title8: 'Recurring payments',
                    title9: 'Custom fields on checkout'
                }
            },
            design: {
                title: "Design",
                items: {
                    title1: 'Variety of design templates',
                    title2: 'Modular page builder',
                    title3: 'CSS/JS editors',
                    title4: 'Video banners',
                    title5: 'Content management tool',
                    title6: 'Quick styling options',
                    title7: 'Settings for Mobile display',
                    title8: 'Personalized store design',
                }
            },
            orders: {
                title: 'Orders',
                items: {
                    title1: 'Unlimited orders',
                    title2: 'Direct order management',
                    title3: 'No additional fees',
                    title4: 'Manual order creation',
                    title5: 'Order editor',
                    title6: 'Order statuses',
                    title7: 'Digital invoice generator',
                    title8: 'Notifications about new orders via email, SMS, and Telegram',
                    title9: 'Booking management',
                    title10: 'Order export',
                    title11: '30-day abandoned cart lifetime'
                }
            },
            clients: {
                title: 'Clients',
                items: {
                    title1: 'Management system',
                    title2: 'Purchase history',
                    title3: 'Personal accounts for customers',
                    title4: 'Quick order repeat',
                    title5: 'Multiple saved addresses',
                    title6: 'Customer groups',
                    title7: 'Personalized purchase experience',
                    title8: 'Custom fields',
                    title9: 'Import / Export',
                    title10: 'Bulk update'
                }
            },
            marketing: {
                title: 'Marketing & Ads',
                items: {
                    title1: 'Built-in SEO',
                    title2: 'Google Ads',
                    title3: 'Facebook Pixel',
                    title4: 'Facebook Conversions API',
                    title5: 'Promotional scenarios',
                    title6: 'Discounts and promo codes',
                    title7: 'Promo code generator',
                    title8: 'Gift vouchers',
                    title9: 'Sales pages',
                    title10: 'Built-in blog',
                    title11: 'Automatic "Sale" category',
                    title12: 'SEO meta-tags editing',
                    title13: 'Robots.txt editor',
                    title14: 'Autogenerated sitemap.xml',
                    title15: '301 redirects management'
                }
            },
            analitics: {
                title: 'Analytics',
                items: {
                    title1: 'Real-time dashboard',
                    title2: 'Reports on abandoned carts',
                    title3: 'Promo code usage reports',
                    title4: 'Google Analytics GA4',
                    title5: 'Google Analytics API',
                    title6: 'Google tag manager'
                }
            },
            security: {
                title: 'Security',
                items: {
                    title1: 'Built-in Firewall',
                    title2: 'PCI DSS Level 1',
                    title3: 'SSL certificate',
                    title4: 'DDoS protection',
                    title5: 'Spam protection',
                    title6: 'Online store monitoring 24/7',
                    title7: 'GDPR compliance'
                }
            },
            communication: {
                title: 'Communications',
                items: {
                    title1: 'WhatsApp widget',
                    title2: 'Facebook widget',
                    title3: 'Message templates',
                    title4: 'Personalized dynamic customer data',
                    title5: 'Order status update notifications',
                    title6: 'SMS notifications',
                    title7: 'Email notifications',
                    title8: 'Email marketing tools connection',
                    title9: 'Custom widgets connection'
                }
            },
            serviceSupport: {
                title: 'Service & Support',
                items: {
                    title1: 'Large knowledge base',
                    title2: 'Live chat support',
                    title3: 'Email support',
                    title4: 'Multichannel support',
                    title5: 'Personal e-commerce specialist',
                    title6: 'Rapid first-line support'
                }
            }
        },
        faq: {
            title: 'Frequently asked questions',
            text: 'Everything you need to know about the platform and payments',
            questionAnswer: {
                question1: 'Do I need to be a designer or developer to use Sellavi?',
                answer1: `Absolutely not! Sellavi's intuitive interface lets you customize your online store in minutes, even without programming knowledge. This means you can focus on what matters most your products and customers. 
            
                Need a hand? Our comprehensive knowledge base offers answers to most questions, and our friendly Technical Support team is always available via the green chat icon in the bottom right corner of your store.`
                ,
                question2: 'Can I migrate my store to Sellavi?',
                answer2: `Sure! Our technical experts team is always ready and equipped to assist you with moving your online store to our platform. All we need from you is your product and client export table, and your migration will be done in no time! Don't worry, you will not lose any data during this process.
                `,
                question3: 'How do I upgrade my plan?',
                answer3: `Upgrading your Sellavi plan is easy! Just follow these 4 steps:

                1) Log in to your Admin Panel.
                2) Click on your store number located at the bottom left corner.
                3) Select ""Manage your plan"" from the menu. This will open your plan options.
                4) Choose the plan* you'd like to upgrade to and confirm your selection.
                
                That's it!  Your plan will be upgraded immediately.
                
                *You can also combine our plans to your liking. Reach out to the support team to know more!`,
                question4: 'Can I use my own domain?',
                answer4: `Of course! You can add an existing domain to your online store  regardless of the plan you choose.
                `,
                question5: 'Can I manage my store from a mobile device?',
                answer5: `Yes! Sellavi's platform is mobile-first, ensuring convenience for both customers and merchants. The responsive design focuses on the mobile version, enhancing the shopping experience and boosting sales.
                `,
                question6: 'I sell a large amount of products. Is there a limit on hosting and the number of products that can be uploaded to the store?',
                answer6: 'Sellavi boasts unlimited hosting and allows an unrestricted number of product uploads for each store. Whether you sell a handful or an extensive range of products, our platform has you covered!',
                question7: 'Are there integrated analytical tools to track my business processes?',
                answer7: 'Absolutely! Sellavi provides an intuitive dashboard displaying real-time income, sales reports, and essential statistics to keep you informed about your business.',
                question8: 'Do I have to pay for the traffic I generate and for data storage?',
                answer8: "We don't charge money for the traffic you generate. Also, regardless of which plan you have, you can download and store an unlimited amount of information and data for free.",
                question9: 'Are non-profit organizations eligible for discounts?',
                answer9: `Yes, non-profit organizations can receive a discount. Please send us an email via <a class="faq__item-info-answer-link" href="mailto:uae@sellavi.com" target="_blank">support.bg@sellavi.com</a> and tell us about your organization. We will provide you with a special offer.`,
            }
        },
        benefits: {
            title: 'With any plan, you get',
            text: 'No small print: you get what you see',
            cards: {
                card1: 'Unlimited hosting and data storage space',
                card2: 'Integration with powerful marketing tools',
                card3: 'Comprehensive data protection and secure payments',
                card4: 'Live technical support',
                card5: 'Built-in logistics solution',
                card6: 'Built-in online payment solutions'
            },
            textTrialBtn: 'Start 14-day free trial'
        },
        suitable: {
            title: 'Perfect for any business',
            chartText: 'of clients in the selected segment',
            small: {
                title: 'Small',
                mainDescriptions: `of thriving small businesses opt for a Sellavi plan`,
                whyPoints1: `Savings of up to 85% on their budget`,
                whyPoints2: `Automated business management processes save 75% of their time`,
                whyPoints3: `Frequent platform updates, convenient sales analytics, and live support`,
            },
            medium: {
                title: 'Medium',
                mainDescriptions: `of business owners generating over 14 000 BGN monthly revenue choose the Sellavi Plus plan for achieving success in e-commerce.`,
                whyPoints1: `Personal manager available for dedicated support and marketing consultations`,
                whyPoints2: `Business process & logistics management from one convenient dashboard`,
                whyPoints3: `Tailored store setup to align with the client's specific business needs`,
            },
            large: {
                title: 'Large',
                mainDescriptions: `of large businesses have embraced Sellavi, achieving a remarkable growth of over 35% by leveraging the power of online commerce.`,
                whyPoints1: `Total control and streamlined online business operations`,
                whyPoints2: `Easy integration of any service via Sellavi's API`,
                whyPoints3: `Adaptable terms of engagement to cater to the client's unique needs`,
            },
            why: 'Why?'
        }
    },
    affiliateProgram: {
        title: "Sellavi's affiliate program",
        text: "Get to know our affiliate program and become Sellavi's business partner!",
        mobileCards: {
            card1: {
                text: 'Total sales'
            },
            card2: {
                text: 'Abandoned carts'
            },
            card3: {
                text: 'Number of orders'
            },
            card4: {
                text: 'Abandoned items'
            },
        },
        pros: {
            title: "Become Sellavi's business partner",
            cardsTitle: {
                card1: 'Commission for every new client',
                card2: 'Priority guidance and tech support',
                card3: 'Special marketing campaigns',
                card4: 'Opportunity to grow any business'
            }
        },
        overview: {
            title: 'Program overview',
            titleSecond: 'How you will make money with Sellavi',
            textFirst: `Our program is accessible to a wide range of professions, from
            developers to digital agencies, and offers special service
            conditions for your clients. It equips you with the tools to help
            your audience launch and grow successful online businesses on our platform.`,
            textSecond: `While you guide their journey, you'll unlock opportunities for
            competitive referral commissions and establish yourself as a
            leader in your field. Enrollment is completely free, so join today
            and unlock the potential to thrive together`,
            cardsMakeMoney: {
                card1: 'Invite your clients to create an online store on our platform',
                card2: "Give them your coupon code so that we know it's your clients",
                card3: "Once they use your code and pay for one of our plans, you'll get a 20% commission for each client"
            }
        },
        target: {
            title: 'Who is our program for?',
            cardsTarget: {
                title1: "Website Developers",
                title2: "Design Professionals",
                title3: "Copywriters and Content Creators",
                title4: "Business Consultants",
                title5: "Marketing Consultants",
                title6: "Personal Trainers",
                title7: "Affiliate Program Specialists",
                title8: "Entrepreneurs",
                title9: "SEO Agencies",
                title10: "SEM (Search Engine Marketing) Companies",
                title11: "Social Media Marketing Companies",
                title12: "Customer Club Managers",
                title13: "Influencers",
                title14: "E-commerce Site Managers",
            }
        },
        form: {
            title: 'open new horizons',
            text: 'for your business and partners',
            registration: 'Registration form',
            fullName: 'Full name',
            email: 'E-mail',
            phone: 'Phone number',
            business: 'Business name (optional)',
            occupation: 'Occupation (optional)',
            checkLabel: `I confirm that I have read and agree with  <a href='/#' target='_blank'>the cooperation agreement</a>`,
            textBtn: 'Become our partner',
            successText: 'successfully sent your application for partnership'
        }
    },
    blog: {
        title: 'How to thrive in e-commerce',
        subscribeForm: {
            title: `Want to stay updated with the latest e-commerce news?
            Subscribe to our newsletter!`,
            textBtn: 'Subscribe',
            placeholder: 'Email address',
        },
        tags: {
            smm: 'SMM',
            ecomm: 'E-commerce',
            startup: 'Starting up',
            onlinestore: 'Online store',
            tech: 'Tech',
            marketing: 'Marketing & Sales'
        },
        followInSocial: 'Follow Sellavi on social media',
        emptyStatus: 'Empty'
    }
}