export function SalesIcon({ mainClassName, fillClassName }) {
    return (
        <svg
            width={40}
            height={40}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox="0 0 40 40"
        >
            <path
                d="M23.125 6.25l-7.5.917 2.282 2.281L6.25 18.773l1.563 1.952 11.872-9.498 2.523 2.523.917-7.5zm3.125 0V32.5h-2.5V20h-7.5v12.5h-2.5v-6.25h-7.5v6.25H5V35h30v-2.5h-1.25V6.25h-7.5z"
                fill="#15AA31"
                className={fillClassName}
            />
        </svg>
    );
}

export function OnlineStoreIcon({ mainClassName, fillClassName }) {
    return (
        <svg
            width={40}
            height={40}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox="0 0 40 40"
        >
            <path
                d="M5 1.25a1.25 1.25 0 00-1.187.85l-2.5 7.35A1.26 1.26 0 002.5 11.102V27.5a3.761 3.761 0 003.75 3.75h7.5v5h-2.5a1.25 1.25 0 000 2.5H27.5a1.25 1.25 0 000-2.5H25v-5h8.75a3.761 3.761 0 003.75-3.75V11.101a1.26 1.26 0 001.186-1.65l-2.5-7.351a1.25 1.25 0 00-1.187-.85H5zm.9 2.5h5.125l-.85 4.963a5.106 5.106 0 00-1.787.787 2.34 2.34 0 01-1.513.5 2.34 2.34 0 01-1.514-.5 5.717 5.717 0 00-1.211-.625L5.9 3.75zm7.65 0h5.187v5.012a5.58 5.58 0 00-1.613.738 2.537 2.537 0 01-3.025 0 5.498 5.498 0 00-1.412-.687l.862-5.063zm7.687 0h5.2l.862 5.063c-.505.16-.985.392-1.425.687a2.537 2.537 0 01-3.025 0 5.424 5.424 0 00-1.612-.738V3.75zm7.737 0H34.1l1.75 5.112c-.429.16-.836.375-1.21.638a2.556 2.556 0 01-3.04 0 5.107 5.107 0 00-1.788-.787l-.837-4.963zM11.25 11.101a2.305 2.305 0 011.5.498c.836.594 1.838.91 2.863.901a4.806 4.806 0 002.862-.9 2.538 2.538 0 013.024 0 5 5 0 005.726 0 2.538 2.538 0 013.024 0c.317.21.652.39 1.002.539V27.5A1.25 1.25 0 0130 28.75H6.25A1.25 1.25 0 015 27.5V12.139c.593.247 1.231.37 1.875.361a4.839 4.839 0 002.86-.9 2.34 2.34 0 011.514-.499zM8.75 15a1.25 1.25 0 000 2.5h.733l.642.64 1.162 4.663a1.25 1.25 0 001.213.947 1.25 1.25 0 000 2.5 1.25 1.25 0 00.052-2.5H17.5a1.25 1.25 0 001.213-.947l1.25-5a1.25 1.25 0 00-1.213-1.553h-6.983l-.884-.884A1.25 1.25 0 0010 15H8.75zm8.75 8.75a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zM23.75 15a1.25 1.25 0 000 2.5h3.75a1.25 1.25 0 000-2.5h-3.75zm0 3.75a1.25 1.25 0 000 2.5h3.75a1.25 1.25 0 000-2.5h-3.75zm0 3.75a1.25 1.25 0 000 2.5h3.75a1.25 1.25 0 000-2.5h-3.75zm-7.5 8.75h2.5v5h-2.5v-5z"
                fill="var(--primary-active)"
                className={fillClassName}
            />
        </svg>
    );
}

export function AdminPanelIcon({ mainClassName, fillClassName }) {
    return (
        <svg
            width={40}
            height={40}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox="0 0 40 40"
        >
            <path
                d="M39.2 6.4v27.2a.8.8 0 01-.8.8H1.6a.8.8 0 01-.8-.8V6.4a.8.8 0 01.8-.8h36.8a.8.8 0 01.8.8zM12 19.2c-.44 0-.8-.36-.8-.8v-7.824c-3.2.736-5.6 3.608-5.6 7.024 0 3.968 3.232 7.2 7.2 7.2 3.416 0 6.288-2.4 7.024-5.6H12zm6.792-7.592A9.732 9.732 0 0012.8 8.832V17.6h8.768a9.732 9.732 0 00-2.776-5.992zM33.6 28.8a.8.8 0 00-.8-.8H21.6v1.6h11.2a.8.8 0 00.8-.8zm-7.2-15.2h6.4a.8.8 0 100-1.6h-6.4a.8.8 0 100 1.6zm6.4 3.2h-6.4a.8.8 0 100 1.6h6.4a.8.8 0 100-1.6zm0 4.8h-6.4a.8.8 0 100 1.6h6.4a.8.8 0 100-1.6zm-13.6 5.2a.8.8 0 00-.8.8v.4H7.2a.8.8 0 100 1.6h11.2v.4a.8.8 0 101.6 0v-2.4a.8.8 0 00-.8-.8z"
                fill="var(--primary-active)"
                className={fillClassName}
            />
        </svg>
    );
}

export function ProductPricesIcon({ mainClassName, fillClassName }) {
    return (
        <svg
            width={40}
            height={40}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox="0 0 40 40"
        >
            <path
                d="M36 5.6H4c-.44 0-.8.359-.8.8v27.2c0 .443.36.8.8.8h32c.444 0 .8-.357.8-.8V6.4a.8.8 0 00-.8-.8zm-8.8 19.2h-.8v2.4h-1.6v-2.4H24l1.6-2.4 1.6 2.4zM16.8 13.6c-.881 0-1.6-.72-1.6-1.6 0-.882.719-1.6 1.6-1.6h6.4c.881 0 1.6.718 1.6 1.6 0 .88-.719 1.6-1.6 1.6h-6.4zm15.2 16h-8V28h8v1.6zm-.8-4.8v2.4h-1.6v-2.4h-.8l1.6-2.4 1.6 2.4h-.8z"
                fill="var(--primary-active)"
                className={fillClassName}
            />
        </svg>
    );
}

export function ShippingIcon({ mainClassName, fillClassName }) {
    return (
        <svg
            width={40}
            height={40}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox="0 0 40 40"
        >
            <path
                d="M2.32 6.4C1.04 6.4 0 7.44 0 8.72V29.6C0 30.96 1.04 32 2.4 32h3.272c.38 2.275 2.342 4 4.728 4a4.784 4.784 0 004.728-4H24.8V8.72c0-1.28-1.04-2.32-2.32-2.32H2.32zM26.4 12v20h.88c.4 2.24 2.32 4 4.72 4s4.32-1.76 4.72-4h.88c1.36 0 2.4-1.04 2.4-2.4V21.92c0-1.6-1.12-3.2-1.28-3.44l-3.28-4.4C34.8 13.28 33.6 12 32 12h-5.6zm4 1.6H32c.64 0 1.44.561 2.16 1.441l3.28 4.4c.24.32.8 1.36.96 2.16h-7.2c-.32 0-.8-.48-.8-.8v-7.2zM10.4 28c1.821 0 3.2 1.38 3.2 3.2 0 1.821-1.379 3.2-3.2 3.2-1.82 0-3.2-1.379-3.2-3.2 0-1.82 1.38-3.2 3.2-3.2zm21.684.047a3.187 3.187 0 013.036 4.033 3.478 3.478 0 01-2.24 2.24 3.19 3.19 0 01-3.92-3.92c.32-1.04 1.12-1.92 2.24-2.24.3-.08.597-.115.884-.113z"
                fill="var(--primary-active)"
                className={fillClassName}
            />
        </svg>
    );
}

export function PaymentsIcon({ mainClassName, fillClassName }) {
    return (
        <svg
            width={40}
            height={40}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox="0 0 40 40"
        >
            <path
                d="M5.6 7.2c-2.205 0-4 1.794-4 4v17.6c0 2.206 1.794 4 4 4h18.44c.405 4.028 3.83 7.2 7.96 7.2 4.4 0 8-3.6 8-8a7.94 7.94 0 00-1.6-4.77V11.2c0-2.206-1.794-4-4-4H5.6zm1.6 6.4h3.2a.8.8 0 01.8.8v2.4a.8.8 0 01-.8.8H7.2a.8.8 0 01-.8-.8v-2.4a.8.8 0 01.8-.8zm15.2.8h10.4a.8.8 0 110 1.6H22.4a.8.8 0 110-1.6zm-15.2 8h4a.8.8 0 110 1.6h-4a.8.8 0 110-1.6zm7.2 0h4a.8.8 0 110 1.6h-4a.8.8 0 110-1.6zm7.2 0h4a.8.8 0 110 1.6h-4a.8.8 0 110-1.6zm7.2 0h4a.8.8 0 110 1.6h-4a.8.8 0 110-1.6zm3.2 3.2c3.52 0 6.4 2.88 6.4 6.4 0 3.52-2.88 6.4-6.4 6.4-3.52 0-6.4-2.88-6.4-6.4 0-3.52 2.88-6.4 6.4-6.4zm-24.8.8h10.4a.8.8 0 110 1.6H7.2a.8.8 0 110-1.6zm25.44 2.4l-3.04.96v1.12l1.6-.4v4.72h1.6v-6.4h-.16z"
                fill="var(--primary-active)"
                className={fillClassName}
            />
        </svg>
    );
}

export function DesignIcon({ mainClassName, fillClassName }) {
    return (
        <svg
            width={40}
            height={40}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox="0 0 40 40"
        >
            <path
                d="M5 5v30h15v-2.5H7.5V15H35V5H5zm2.5 2.5h25v5h-25v-5zm1.25 1.25v2.5h2.5v-2.5h-2.5zm3.75 0v2.5H15v-2.5h-2.5zm3.75 0v2.5h15v-2.5h-15zm15 8.75a2.5 2.5 0 00-2.5 2.5c0 .641.54 3.09 1.118 5H26.25a2.519 2.519 0 00-2.5 2.5v3.75a1.23 1.23 0 01-1.25 1.25V35H35c2.056 0 3.75-1.694 3.75-3.75V27.5c0-1.366-1.134-2.5-2.5-2.5h-3.618c.578-1.91 1.118-4.359 1.118-5a2.5 2.5 0 00-2.5-2.5zm-5 10h10v3.75A1.23 1.23 0 0135 32.5h-9.27c.171-.428.52-.765.52-1.25V27.5z"
                fill="var(--primary-active)"
                className={fillClassName}
            />
        </svg>
    );
}

export function OrdersIcon({ mainClassName, fillClassName }) {
    return (
        <svg
            width={40}
            height={40}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox="0 0 40 40"
        >
            <path
                d="M2.667 2.667a1.333 1.333 0 100 2.666h2.505c.301 0 .553.189.638.48l5.682 19.317A4.008 4.008 0 0015.328 28h14.589a4.011 4.011 0 003.88-3.029v-.002l3.497-13.98A1.335 1.335 0 0036 9.334H9.625L8.37 5.06h-.003a3.342 3.342 0 00-3.195-2.393H2.667zm20 9.333c.736 0 1.333.596 1.333 1.333v4h4A1.333 1.333 0 1128 20h-4v4a1.333 1.333 0 11-2.667 0v-4h-4a1.333 1.333 0 110-2.667h4v-4c0-.737.598-1.333 1.334-1.333zM16 30.667A2.667 2.667 0 1016 36a2.667 2.667 0 000-5.333zm13.333 0a2.667 2.667 0 100 5.333 2.667 2.667 0 000-5.333z"
                fill="var(--primary-active)"
                className={fillClassName}
            />
        </svg>
    );
}

export function ClientsIcon({ mainClassName, fillClassName }) {
    return (
        <svg
            width={40}
            height={40}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox="0 0 40 40"
        >
            <path
                d="M12.5 8.75A6.256 6.256 0 006.25 15a6.256 6.256 0 006.25 6.25A6.256 6.256 0 0018.75 15a6.256 6.256 0 00-6.25-6.25zm0 12.5c-6.201 0-11.25 5.044-11.25 11.25h22.5c0-1.226-.225-2.412-.586-3.516.127-.278.264-.556.43-.82A6.826 6.826 0 0129.375 25a6.854 6.854 0 016.875 6.875v.625h2.5v-.625c0-3.628-2.085-6.802-5.117-8.36a6.872 6.872 0 002.617-5.39c0-3.784-3.09-6.875-6.875-6.875-3.784 0-6.875 3.09-6.875 6.875 0 2.197 1.04 4.17 2.656 5.43a9.37 9.37 0 00-3.281 2.734c-2.017-3.032-5.469-5.039-9.375-5.039zm16.875-7.5a4.355 4.355 0 014.375 4.375 4.355 4.355 0 01-4.375 4.375A4.355 4.355 0 0125 18.125a4.355 4.355 0 014.375-4.375z"
                fill="var(--primary-active)"
                className={fillClassName}
            />
        </svg>
    );
}

export function MarketingAndAdsIcon({ mainClassName, fillClassName }) {
    return (
        <svg
            width={40}
            height={40}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox="0 0 40 40"
        >
            <path
                d="M33.077 0c-.258 0-.517.072-.77.144-.817.24-1.598.842-2.307 1.78-.078.101-.168.18-.24.288-3.174 3.533-7.362 10.096-17.452 10.096H4.615A4.617 4.617 0 000 16.923V20a4.617 4.617 0 004.615 4.615h7.693c10.312 0 14.182 5.487 16.923 9.135.012.018.036.03.048.048.895 1.599 1.905 2.65 3.029 2.98.252.073.51.145.769.145 3.816 0 6.923-8.263 6.923-18.462C40 8.264 36.893 0 33.077 0zm0 3.125c1.19.703 3.846 5.877 3.846 15.337 0 9.459-2.656 14.633-3.846 15.336-.974-.577-2.933-4.207-3.606-10.721h1.298a4.617 4.617 0 000-9.23h-1.298c.673-6.515 2.632-10.145 3.606-10.722zM26.683 10c-.319 2.38-.53 5.198-.53 8.462 0 2.974.163 5.552.434 7.788-2.59-2.145-6.13-4.026-11.202-4.567v-6.49c5.006-.644 8.617-2.85 11.298-5.193zm-2.068 3.942s-1.742 2.981-7.692 2.981v1.539c5.914 0 7.644-1.443 7.644-1.443l.048-3.077zM6.923 15.385h5.385v3.077H4.615c0-3.06 2.308-3.077 2.308-3.077zM4.856 26.154l2.5 9.23c.198.848 1.027 1.54 1.875 1.54h3.077c2.482.077 3.239-2.705.432-4.953-1.947-1.556-.529-5.817-.529-5.817H4.856z"
                fill="var(--primary-active)"
                className={fillClassName}
            />
        </svg>
    );
}

export function AnaliticsIcon({ mainClassName, fillClassName }) {
    return (
        <svg
            width={40}
            height={40}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox="0 0 40 40"
        >
            <path
                d="M18.787 3.987c-1.354 0-2.771 1.321-2.771 2.666H2.682a1.332 1.332 0 100 2.667h1.334V28.23c0 1.333 1.093 2.425 2.466 2.425h3.174l1.079-1.79-1.076 1.792-2.786 4.646a1.334 1.334 0 002.286 1.375l3.612-6.02.25-.418-.245.415h14.48l-.35-.592.355.591 3.612 6.021a1.329 1.329 0 001.828.456 1.332 1.332 0 00.458-1.828l-2.789-4.648-1.099-1.831 1.104 1.83h3.175c1.36 0 2.466-1.09 2.466-2.424V9.32h1.333a1.332 1.332 0 100-2.667H24.016c0-1.345-1.415-2.666-2.768-2.666h-2.461zm9.229 9.333c.733 0 1.333.6 1.333 1.333v9.334c0 .733-.6 1.333-1.333 1.333s-1.333-.6-1.333-1.333v-9.333c0-.734.6-1.334 1.333-1.334zm-10.667 1.333c.733 0 1.334.6 1.334 1.334v8c0 .733-.6 1.333-1.334 1.333-.733 0-1.333-.6-1.333-1.333v-8c0-.733.6-1.334 1.333-1.334zm5.334 1.334c.733 0 1.333.6 1.333 1.333v6.667c0 .733-.6 1.333-1.333 1.333-.734 0-1.334-.6-1.334-1.333V17.32c0-.733.6-1.333 1.334-1.333zm-10.667 4c.733 0 1.333.6 1.333 1.333v2.667c0 .733-.6 1.333-1.333 1.333s-1.334-.6-1.334-1.333V21.32c0-.733.6-1.333 1.334-1.333zm.294 8.052c.132.03.263.063.385.135l.006.006a1.296 1.296 0 00-.39-.141zm15.383.01c-.123.031-.247.062-.362.13l.005-.005c.113-.067.236-.094.357-.125zm-14.383.948z"
                fill="var(--primary-active)"
                className={fillClassName}
            />
        </svg>
    );
}

export function SecurityIcon({ mainClassName, fillClassName }) {
    return (
        <svg
            width={40}
            height={40}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox="0 0 40 40"
        >
            <path
                d="M19.994 2.5c-.29.001-.572.104-.795.29-.046.038-4.718 3.877-10.449 3.877a1.25 1.25 0 00-1.25 1.25v8.061c0 3.956 1.562 11.242 12.02 15.593a1.25 1.25 0 00.96 0C30.938 27.22 32.5 19.934 32.5 15.978V7.917a1.25 1.25 0 00-1.25-1.25c-5.696 0-10.405-3.84-10.45-3.877a1.249 1.249 0 00-.806-.29zm-.095 9.152a3.334 3.334 0 011.732 6.239v2.978a1.63 1.63 0 01-3.262 0v-2.978a3.333 3.333 0 011.53-6.239zM3.333 21.667a3.333 3.333 0 103.088 4.583h1.496a1.248 1.248 0 001.17-1.733 1.25 1.25 0 00-1.17-.767H6.419a3.333 3.333 0 00-3.086-2.083zm33.334 0a3.333 3.333 0 00-3.088 2.083h-1.496A1.252 1.252 0 0030.816 25a1.25 1.25 0 001.267 1.25h1.498a3.334 3.334 0 003.086 2.083 3.333 3.333 0 100-6.666zm-27.917 7.5c-.332 0-.65.131-.884.366L6.3 31.1A3.333 3.333 0 005 30.833a3.333 3.333 0 102.776 5.174 3.334 3.334 0 00.29-3.14l1.202-1.2h1.982a1.252 1.252 0 001.268-1.25 1.249 1.249 0 00-1.268-1.25h-2.5zm20 0a1.249 1.249 0 00-1.17 1.732 1.25 1.25 0 001.17.768h1.982l1.202 1.2a3.333 3.333 0 101.765-1.768l-1.565-1.566a1.25 1.25 0 00-.884-.366h-2.5z"
                fill="var(--primary-active)"
                className={fillClassName}
            />
        </svg>
    );
}

export function CommunicationsIcon({ mainClassName, fillClassName }) {
    return (
        <svg
            width={40}
            height={40}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox="0 0 40 40"
        >
            <path
                d="M11.2 3.192c-4.409 0-8 3.591-8 8v17.6c0 4.41 3.591 8 8 8h17.6c4.41 0 8-3.59 8-8v-17.6c0-4.409-3.59-8-8-8H11.2zm3.205 6.435c.502.021.964.304 1.242.739.317.494.809 1.261 1.449 2.262.584.913.624 2.092.103 3.044l-1.172 1.667a1.597 1.597 0 00-.167 1.538c.33.792.973 2.009 2.114 3.15a9.917 9.917 0 003.15 2.114c.508.213 1.086.15 1.537-.167l1.667-1.172a3.004 3.004 0 013.044.103l2.263 1.448c.434.279.717.74.739 1.242.123 2.852-2.086 4.007-2.729 4.007-.444 0-5.792.607-11.823-5.424C9.791 18.148 10.4 12.8 10.4 12.355c0-.643 1.155-2.851 4.006-2.728z"
                fill="var(--primary-active)"
                className={fillClassName}
            />
        </svg>
    );
}

export function ServiceAndSupportIcon({ mainClassName, fillClassName }) {
    return (
        <svg
            width={40}
            height={40}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox="0 0 40 40"
        >
            <path
                d="M6.667 6.667c-2.206 0-4 1.794-4 4V28c0 1.028.4 1.957 1.039 2.666H0a4 4 0 004 4h15.117a5.745 5.745 0 011.242-1.864l3.47-3.469H6.666A1.335 1.335 0 015.333 28V10.666c0-.734.598-1.333 1.334-1.333h26.666c.736 0 1.334.599 1.334 1.333v8.151a9.543 9.543 0 011.815.495c.325.124.605.322.851.552v-9.198c0-2.205-1.794-4-4-4H6.667zm25.96 14.687a6.895 6.895 0 00-4.492 2.016c-1.922 1.921-1.78 3.99-.573 6l-5.317 5.317a3.113 3.113 0 004.4 4.401l5.319-5.317c2.008 1.206 4.077 1.35 6-.573 2-2 2.515-4.915 1.567-7.396L35.333 30 32 29.333 31.333 26l4.198-4.198a7.003 7.003 0 00-2.903-.448z"
                fill="var(--primary-active)"
                className={fillClassName}
            />
        </svg>
    );
}



export function TrueTickIcon({ mainClassName, strokeClassName }) {
    return (
        <svg
            width={24}
            height={24}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox="0 0 24 24"
        >
            <path
                d="M7.5 12l3 3 6-6m5.5 3c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10z"
                stroke="var(--primary-active)"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className={strokeClassName}
            />
        </svg>
    );
}

export function FalseTickIcon({ mainClassName, strokeClassName }) {
    return (
        <svg
            width={40}
            height={2}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox="0 0 40 2"
        >
            <path
                d="M1.917 1h36.166"
                stroke="#98A2B3"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className={strokeClassName}

            />
        </svg>
    );
}



