export const TemplateOne = ({ mainClassName, bgClassName, fillClassName }) => {
  return (
<svg className={mainClassName} width="461" height="377" viewBox="0 0 461 377" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_173_2562)">
<path className={bgClassName} d="M25 32.5958C25 26.1916 30.1916 21 36.5957 21H423.894C430.298 21 435.489 26.1916 435.489 32.5957V336.404C435.489 342.808 430.298 348 423.894 348H36.5958C30.1916 348 25 342.808 25 336.404V32.5958Z" fill="white"/>
</g>
<mask id="mask0_173_2562" maskUnits="userSpaceOnUse" x="51" y="72" width="360" height="248">
<path d="M51 72H411V320H51V72Z" fill="#C4C4C4"/>
</mask>
<g mask="url(#mask0_173_2562)">
<path d="M411.85 72.0213H50.5112V266.83H411.85V72.0213Z" fill="url(#paint0_radial_173_2562)"/>
<path d="M234.21 182.114C238.494 182.114 245.649 185.685 248.691 187.47L254.643 188.859L48.328 194.025C66.1161 192.306 134.623 185.495 144.145 183.701C147.654 183.04 167.356 176.163 176.878 174.774C186.4 173.385 187.194 168.632 194.335 168.632C201.477 168.632 215.562 164.863 217.942 166.45C220.323 168.037 228.853 182.114 234.21 182.114Z" fill="url(#paint1_linear_173_2562)"/>
<path d="M290.352 187.478V189.859H225.68V185.296H229.251V187.676H230.838V186.883H232.028V187.676H235.202V185.296H236.988V180.138H240.757V188.668H244.526V187.676H247.502V185.693H249.485V187.676H250.279V186.883H255.238V182.32H256.23V187.676H257.421V185.296H258.412V188.271H261.983V186.089H266.546V185.296H271.902V184.304H279.441V188.668H280.036V183.51L281.028 182.717L281.424 164.466L284.4 167.243V189.66H285.59V188.073H286.186V187.478V181.725H287.574V173.988H289.36V187.478H290.352Z" fill="#B968EA"/>
<path d="M388.351 176.567C389.739 175.377 396.484 173.393 404.023 172.997C407.339 172.822 409.968 171.559 411.85 170.139V201.632L317.926 191.644C322.224 190.785 331.654 188.867 334.987 188.073C339.152 187.082 341.136 186.09 342.327 186.09C343.517 186.09 344.707 187.082 348.873 184.899C353.039 182.717 367.124 182.122 371.687 182.122C376.249 182.122 386.962 177.758 388.351 176.567Z" fill="url(#paint2_linear_173_2562)"/>
<path d="M50.5108 266.83V193.462C50.5108 193.462 118.851 188.245 233.94 188.278C349.028 188.311 414.991 198.446 414.991 198.446V266.83L50.5108 266.83Z" fill="url(#paint3_radial_173_2562)"/>
<path d="M298.807 193.578C291.872 192.788 300.195 190.813 312.084 191.603C301.978 191.577 289.137 192.551 326.55 193.183C361.388 193.771 396.466 198.742 411.85 206.119V320.17H50.5112V237.016C166.039 227.736 306.813 213.164 335.665 207.794C371.731 201.081 311.574 195.031 298.807 193.578Z" fill="url(#paint4_linear_173_2562)"/>
<path d="M192.164 106.437L189.315 108.811L192.164 109.523V106.437Z" fill="url(#paint5_linear_173_2562)"/>
<path d="M246.992 102.165L249.365 104.301L246.992 105.25V102.165Z" fill="url(#paint6_linear_173_2562)"/>
<path d="M209.023 161.503C211.871 165.538 215.432 171.709 215.669 172.896L214.007 139.904L199.054 138.242C199.054 139.825 199.434 143.939 200.953 147.737C202.852 152.484 206.175 157.468 209.023 161.503Z" fill="url(#paint7_linear_173_2562)"/>
<path d="M191.932 116.644C190.983 112.086 191.537 107.308 191.932 105.488C192.249 104.46 192.882 102.213 192.882 101.453C192.882 100.694 203.958 97.339 209.496 95.7567L212.819 116.644V134.92L213.769 140.853C212.107 140.932 207.17 141.043 204.512 140.853C201.854 140.663 198.974 139.825 197.866 139.429C196.917 136.264 194.97 129.413 194.78 127.324C194.543 124.713 193.119 122.34 191.932 116.644Z" fill="url(#paint8_linear_173_2562)"/>
<path d="M240.354 183.102C239.974 179.114 238.771 172.738 238.217 170.048C234.024 178.83 227.299 197.343 227.299 197.343C227.299 197.343 230.755 203.039 229.673 212.059C228.961 217.992 229.673 225.113 231.809 231.047C233.945 236.98 236.319 239.354 238.93 242.202C241.54 245.05 244.151 250.035 246.05 254.544C247.569 258.152 249.056 265.383 249.61 268.548C250.243 268.944 252.174 269.64 254.832 269.26C257.49 268.88 259.421 267.045 260.054 266.175C259.183 262.219 256.921 251.886 254.832 242.202C252.221 230.097 248.186 210.635 247.237 206.125C246.287 201.615 245.813 197.106 244.389 193.545C242.964 189.985 240.828 188.086 240.354 183.102Z" fill="url(#paint9_linear_173_2562)"/>
<path d="M258.865 260.715C260.046 258.579 260.758 269.023 261.714 270.921C262.67 272.82 259.103 274.956 255.543 275.194C252.694 275.384 250.09 275.906 250.321 273.532C250.551 271.159 246.754 262.786 248.422 263.089C250.09 263.392 251.603 263.469 253.881 263.089C256.16 262.709 257.685 262.851 258.865 260.715Z" fill="white"/>
<path d="M280.228 264.038C281.747 263.658 282.918 264.987 283.313 265.699C284.738 269.022 283.313 271.871 281.889 274.956C280.465 278.042 277.142 280.415 274.057 281.365C270.971 282.314 269.784 281.365 266.224 282.789C262.664 284.213 262.664 287.061 257.68 289.197C252.695 291.333 249.372 288.722 248.898 288.01C248.423 287.298 249.372 283.501 249.372 281.839C249.372 280.178 248.423 278.754 248.423 277.567C248.423 276.618 249.847 274.64 250.559 273.769C250.717 274.007 251.414 274.576 252.933 274.956C254.831 275.431 258.392 274.481 260.528 272.82C262.237 271.491 261.715 270.051 261.24 269.497C262.031 269.418 264.468 269.212 267.886 269.022C272.158 268.785 278.329 264.513 280.228 264.038Z" fill="url(#paint10_linear_173_2562)"/>
<path d="M228.009 136.581C234.276 138.29 241.697 142.831 244.624 144.888C243.596 150.426 241.301 162.025 240.352 164.113C239.165 166.724 231.332 188.798 228.484 194.494C225.636 200.191 221.364 199.241 217.566 198.529C213.768 197.817 212.819 195.918 202.613 193.545C194.448 191.646 187.502 191.646 185.049 191.883L188.372 167.674L191.932 169.098C195.35 169.667 196.194 167.5 202.613 167.674C211.395 167.911 216.908 176.693 215.43 171.234C212.344 159.841 212.819 149.16 212.819 143.227C212.819 138.48 212.186 135.236 211.87 134.207C214.639 134.286 221.743 134.872 228.009 136.581Z" fill="url(#paint11_linear_173_2562)"/>
<path d="M186.949 165.538C188.658 166.297 191.142 168.386 190.984 168.861C188.373 170.285 188.373 180.253 188.373 181.678V181.678C188.373 183.102 192.171 190.46 190.984 191.884C189.798 193.308 182.439 191.884 182.439 189.985C182.439 188.086 179.591 180.966 176.506 176.693C173.42 172.421 175.319 167.911 177.692 166.487C180.066 165.063 180.778 161.266 181.015 162.69C181.253 164.114 184.813 164.589 186.949 165.538Z" fill="url(#paint12_linear_173_2562)"/>
<path d="M186.475 196.63C186.854 195.111 183.785 191.567 182.202 189.985L171.047 200.428L154.195 235.081C154.195 236.11 153.001 238.357 155.85 238.167C159.41 237.929 162.251 236.031 164.632 233.42C167.635 230.125 170.097 221.79 171.996 215.618C173.895 209.447 176.506 207.311 178.167 204.938C179.829 202.564 186 198.529 186.475 196.63Z" fill="#E64715"/>
<path d="M176.03 155.331C174.321 154.572 170.255 152.325 168.435 151.296L161.552 165.775V203.039L158.697 235.793C159.251 235.239 162.969 232.47 165.112 229.147C166.568 226.891 168.91 221.552 169.385 219.179C169.859 216.805 174.844 205.412 175.556 203.039C176.268 200.665 181.727 194.257 182.439 189.747C183.151 185.238 175.081 175.744 174.844 172.421C174.606 169.098 176.03 167.911 178.879 165.775C181.727 163.639 180.777 162.452 180.777 159.841C180.777 157.23 178.167 156.281 176.03 155.331Z" fill="url(#paint13_linear_173_2562)"/>
<path d="M168.435 151.296L160.128 149.16L161.315 199.716C161.315 210.634 161.694 231.758 163.213 228.91C165.587 226.062 168.198 214.432 168.198 210.871C168.198 207.311 167.96 194.969 168.198 190.222C168.435 185.475 169.622 182.864 172.707 169.098C175.176 158.085 170.888 152.641 168.435 151.296Z" fill="url(#paint14_linear_173_2562)"/>
<path d="M148.497 163.164C148.117 153.67 155.143 148.686 159.89 149.16C173.419 151.534 168.672 172.895 167.01 178.829C165.681 183.576 164.716 190.301 164.399 193.07C163.45 206.125 167.97 208.023 165.349 220.84C162.728 233.657 157.042 239.828 153.244 237.93C149.446 236.031 142.801 220.128 142.563 212.058C142.326 203.988 148.497 193.782 149.684 185C150.87 176.218 148.972 175.032 148.497 163.164Z" fill="#1E1C51"/>
<path d="M257.672 289.198C252.688 291.334 249.365 288.723 248.89 288.011V286.112C249.837 286.745 252.111 287.963 253.63 287.773C255.529 287.536 258.615 286.587 260.514 284.688C262.412 282.789 264.074 281.128 267.871 280.89C271.669 280.653 279.515 279.466 281.882 274.956C280.458 278.042 277.135 280.416 274.049 281.365C270.964 282.314 269.777 281.365 266.217 282.789C262.656 284.213 262.656 287.061 257.672 289.198Z" fill="url(#paint15_linear_173_2562)"/>
<path d="M260.758 269.497C260.948 268.358 260.837 267.599 260.758 267.361C261.074 267.599 262.319 268.592 265.03 268.785C268.353 269.023 270.378 268.26 272.626 267.361C274.999 266.412 277.847 264.75 280.221 264.038C282.594 263.326 283.781 266.412 282.832 265.225C281.882 264.038 279.984 265.7 278.085 266.649C276.584 267.4 275.474 268.548 272.626 269.735C269.778 270.922 266.929 271.871 262.419 272.108C259.717 272.251 260.521 270.922 260.758 269.497Z" fill="#E64715"/>
<path d="M218.753 134.92C227.677 135.489 239.877 141.803 244.862 144.888C245.415 141.091 246.76 131.597 247.71 124.001C248.897 114.507 247.947 110.71 247.947 106.438C247.947 103.02 247.71 100.82 247.71 99.0796L241.539 93.8579L219.465 92.6712L207.36 99.0796V105.488C207.36 109.998 210.208 114.033 209.734 120.441C209.354 125.568 206.885 131.28 205.699 133.495C206.332 133.733 209.829 134.35 218.753 134.92Z" fill="url(#paint16_linear_173_2562)"/>
<path d="M247.941 82.7023V74.6324C247.941 73.8297 248.019 72.9415 248.15 72.0213H191.425C191.587 72.9636 191.689 73.8567 191.689 74.6324C191.689 78.9047 190.502 82.7023 190.502 86.4999C190.502 90.2976 189.316 94.3325 189.316 100.266V108.811C189.711 108.099 193.873 106.58 196.911 104.301C200.708 101.453 212.101 98.6048 225.155 97.6554C235.599 96.8959 246.042 101.77 249.365 104.301C249.365 102.323 249.08 97.6554 247.941 94.8072C246.517 91.247 247.941 87.6867 247.941 82.7023Z" fill="url(#paint17_linear_173_2562)"/>
<path d="M169.141 85.3133C167.432 83.0348 166.818 75.819 167.135 72.0214H152.182C153.527 75.9772 156.704 83.8892 155.374 87.6868C153.713 92.4338 155.612 93.8579 155.374 98.3676C155.137 102.877 157.985 109.048 159.884 109.523C161.783 109.998 163.207 111.897 164.869 111.897C166.53 111.897 170.565 113.083 172.464 113.321C174.363 113.558 175.312 112.134 176.973 111.897C178.635 111.659 177.923 108.574 179.822 106.675C181.72 104.776 180.059 102.403 179.822 101.216C179.584 100.029 176.261 94.8073 175.312 91.7218C174.362 88.6362 171.277 88.1615 169.141 85.3133Z" fill="url(#paint18_linear_173_2562)"/>
<path d="M171.758 92.3288C173.467 92.7086 176.901 98.8164 178.404 101.823C175.952 101.348 170.619 100.019 168.91 98.5C166.774 96.6012 163.214 96.3638 159.654 96.1265C156.093 95.8891 163.451 94.9397 166.062 94.7024C168.673 94.465 167.249 90.9047 167.249 90.1927C167.249 89.4806 169.622 91.8541 171.758 92.3288Z" fill="url(#paint19_linear_173_2562)"/>
<path d="M285.416 87.4434C286.128 84.5952 290.875 81.5096 293.961 80.3229C295.981 79.5456 299.625 75.2157 302.052 72.0213H282.764C281.841 74.0116 282.216 76.6524 279.245 80.3229C275.21 85.3073 273.548 83.8832 270.938 83.8832C268.327 83.8832 264.292 86.2567 264.292 87.4434C264.292 88.6302 263.817 94.0892 264.292 95.7507C264.766 97.4121 267.14 100.023 269.276 101.447C271.412 102.871 274.26 101.684 276.159 101.447C278.058 101.21 280.194 96.4627 280.906 95.7507C281.618 95.0386 284.704 90.2916 285.416 87.4434Z" fill="url(#paint20_linear_173_2562)"/>
</g>
<path d="M352 53.3222C352 49.5603 355.05 46.5107 358.812 46.5107H405.13C408.892 46.5107 411.942 49.5603 411.942 53.3222V53.3222C411.942 57.0842 408.892 60.1338 405.13 60.1338H358.812C355.05 60.1338 352 57.0842 352 53.3222V53.3222Z" fill="#FF4F17"/>
<path className={fillClassName} d="M50.5107 49.9894C50.5107 48.0681 52.0682 46.5107 53.9895 46.5107H121.245C123.166 46.5107 124.724 48.0681 124.724 49.9894V49.9894C124.724 51.9106 123.166 53.4681 121.245 53.4681H53.9895C52.0682 53.4681 50.5107 51.9106 50.5107 49.9894V49.9894Z" fill="#D4DCE1"/>
<path className={fillClassName} d="M129.361 49.9894C129.361 48.0681 130.919 46.5107 132.84 46.5107H160.67C162.591 46.5107 164.149 48.0681 164.149 49.9894V49.9894C164.149 51.9106 162.591 53.4681 160.67 53.4681H132.84C130.919 53.4681 129.361 51.9106 129.361 49.9894V49.9894Z" fill="#D4DCE1"/>
<defs>
<filter id="filter0_d_173_2562" x="0" y="0" width="460.489" height="377" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="12.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.823529 0 0 0 0 0.87451 0 0 0 0 0.909804 0 0 0 0.44 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_173_2562"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_173_2562" result="shape"/>
</filter>
<radialGradient id="paint0_radial_173_2562" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(312.373 193.033) rotate(-89.301) scale(145.026 498.893)">
<stop offset="0.10248" stopColor="#FCF1D4"/>
<stop offset="0.525154" stopColor="#E880E4"/>
<stop offset="1" stopColor="#4200FF"/>
</radialGradient>
<linearGradient id="paint1_linear_173_2562" x1="191.36" y1="146.017" x2="189.318" y2="188.859" gradientUnits="userSpaceOnUse">
<stop stopColor="#4200FF"/>
<stop offset="1" stopColor="#EC95E1"/>
</linearGradient>
<linearGradient id="paint2_linear_173_2562" x1="384.767" y1="159.641" x2="375.34" y2="207.341" gradientUnits="userSpaceOnUse">
<stop stopColor="#4200FF"/>
<stop offset="1" stopColor="#EC95E1"/>
</linearGradient>
<radialGradient id="paint3_radial_173_2562" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(242.655 188.278) rotate(92.2055) scale(51.4754 443.346)">
<stop stopColor="#20FFBC"/>
<stop offset="1" stopColor="#4200FF"/>
</radialGradient>
<linearGradient id="paint4_linear_173_2562" x1="270.871" y1="191.603" x2="254.596" y2="292.34" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFACCB"/>
<stop offset="1" stopColor="#4200FF"/>
</linearGradient>
<linearGradient id="paint5_linear_173_2562" x1="196.673" y1="110.472" x2="189.266" y2="109.678" gradientUnits="userSpaceOnUse">
<stop stopColor="#CF3200"/>
<stop offset="1" stopColor="#FFB7A0"/>
</linearGradient>
<linearGradient id="paint6_linear_173_2562" x1="242.482" y1="106.2" x2="250.731" y2="105.375" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFB7A0"/>
<stop offset="1" stopColor="#CF3200"/>
</linearGradient>
<linearGradient id="paint7_linear_173_2562" x1="245.331" y1="152.009" x2="209.491" y2="151.297" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="1" stopColor="#CE7DB0"/>
</linearGradient>
<linearGradient id="paint8_linear_173_2562" x1="193.909" y1="114.248" x2="239.543" y2="112.435" gradientUnits="userSpaceOnUse">
<stop stopColor="#CF3200"/>
<stop offset="1" stopColor="#FF7F57"/>
</linearGradient>
<linearGradient id="paint9_linear_173_2562" x1="252.214" y1="232.708" x2="235.41" y2="236.39" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="1" stopColor="#D987BA"/>
</linearGradient>
<linearGradient id="paint10_linear_173_2562" x1="273.575" y1="285.874" x2="274.287" y2="265.699" gradientUnits="userSpaceOnUse">
<stop stopColor="#4200FF"/>
<stop offset="1" stopColor="#20FFBC"/>
</linearGradient>
<linearGradient id="paint11_linear_173_2562" x1="240.345" y1="175.506" x2="215.898" y2="159.604" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="1" stopColor="#D987BA"/>
</linearGradient>
<linearGradient id="paint12_linear_173_2562" x1="182.614" y1="168.624" x2="195.348" y2="192.35" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFD7F3"/>
<stop offset="0.359375" stopColor="#FCF1D4"/>
<stop offset="1" stopColor="white"/>
</linearGradient>
<linearGradient id="paint13_linear_173_2562" x1="188.366" y1="181.677" x2="143.507" y2="200.428" gradientUnits="userSpaceOnUse">
<stop stopColor="#20FFBC"/>
<stop offset="1" stopColor="#4200FF"/>
</linearGradient>
<linearGradient id="paint14_linear_173_2562" x1="166.788" y1="149.16" x2="166.788" y2="229.172" gradientUnits="userSpaceOnUse">
<stop stopColor="#1E1C51"/>
<stop offset="1" stopColor="#1E1C51" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint15_linear_173_2562" x1="265.386" y1="274.956" x2="265.386" y2="290.021" gradientUnits="userSpaceOnUse">
<stop stopColor="#1E1C51"/>
<stop offset="1" stopColor="#1E1C51" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint16_linear_173_2562" x1="210.676" y1="114.033" x2="247.228" y2="119.967" gradientUnits="userSpaceOnUse">
<stop stopColor="#CF3200"/>
<stop offset="1" stopColor="#FF7F57"/>
</linearGradient>
<linearGradient id="paint17_linear_173_2562" x1="212.045" y1="96.3591" x2="246.949" y2="94.3317" gradientUnits="userSpaceOnUse">
<stop stopColor="#CF3200"/>
<stop offset="0.478853" stopColor="#E36842"/>
<stop offset="1" stopColor="#FFB7A0"/>
</linearGradient>
<linearGradient id="paint18_linear_173_2562" x1="178.73" y1="97.9896" x2="160.231" y2="108.922" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="0.565975" stopColor="#D693B6"/>
<stop offset="1" stopColor="#C875A9"/>
</linearGradient>
<linearGradient id="paint19_linear_173_2562" x1="168.668" y1="112.134" x2="171.854" y2="91.9249" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="1" stopColor="#CE7DB0"/>
</linearGradient>
<linearGradient id="paint20_linear_173_2562" x1="286.284" y1="89.5856" x2="268.725" y2="78.559" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="0.565975" stopColor="#D693B6"/>
<stop offset="1" stopColor="#C875A9"/>
</linearGradient>
</defs>
</svg>
  )
}

export const TemplateTwo = ({ mainClassName, bgClassName, fillClassName, boxBgClassName }) => {
  return (
    <svg className={mainClassName} width="462" height="377" viewBox="0 0 462 377" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_173_2707)">
<path className={bgClassName} d="M25.8301 32.5958C25.8301 26.1916 31.0217 21 37.4258 21H424.724C431.128 21 436.319 26.1916 436.319 32.5957V336.404C436.319 342.808 431.128 348 424.724 348H37.4258C31.0217 348 25.8301 342.808 25.8301 336.404V32.5958Z" fill="white"/>
</g>
<path className={fillClassName} d="M125.554 221.606C125.554 219.685 127.111 218.128 129.032 218.128H330.798C332.72 218.128 334.277 219.685 334.277 221.606V221.606C334.277 223.528 332.72 225.085 330.798 225.085H129.032C127.111 225.085 125.554 223.528 125.554 221.606V221.606Z" fill="#D4DCE1"/>
<mask id="mask0_173_2707" maskUnits="userSpaceOnUse" x="51" y="74" width="363" height="131">
<path d="M51.3408 74.3404H413.128V204.213H51.3408V74.3404Z" fill="#C4C4C4"/>
</mask>
<g mask="url(#mask0_173_2707)">
<path d="M418.523 21H53.5462V217.77H418.523V21Z" fill="url(#paint0_radial_173_2707)"/>
<path d="M239.095 132.201C243.423 132.201 250.65 135.808 253.722 137.612L259.733 139.014L51.3412 144.232C69.3083 142.495 138.505 135.616 148.123 133.804C151.668 133.137 171.567 126.19 181.186 124.787C190.804 123.385 191.605 118.584 198.819 118.584C206.032 118.584 220.259 114.777 222.664 116.38C225.068 117.983 233.684 132.201 239.095 132.201Z" fill="url(#paint1_linear_173_2707)"/>
<path d="M295.802 137.619V140.024H230.479V135.415H234.086V137.82H235.689V137.018H236.891V137.82H240.097V135.415H241.9V130.205H245.707V138.821H249.515V137.82H252.52V135.816H254.524V137.82H255.326V137.018H260.335V132.409H261.337V137.82H262.539V135.415H263.541V138.421H267.148V136.217H271.757V135.415H277.167V134.413H284.781V138.821H285.382V133.612L286.384 132.81L286.785 114.375L289.79 117.181V139.823H290.993V138.22H291.594V137.619V131.808H292.996V123.994H294.8V137.619H295.802Z" fill="#B968EA"/>
<path d="M394.788 126.599C396.19 125.397 403.003 123.393 410.618 122.992C413.968 122.816 416.623 121.54 418.523 120.106V151.916L323.654 141.827C327.995 140.959 337.52 139.022 340.886 138.221C345.094 137.219 347.098 136.217 348.3 136.217C349.503 136.217 350.705 137.219 354.913 135.015C359.121 132.811 373.347 132.209 377.956 132.209C382.565 132.209 393.385 127.801 394.788 126.599Z" fill="url(#paint2_linear_173_2707)"/>
<path d="M53.5464 217.77V143.663C53.5464 143.663 122.575 138.394 238.822 138.428C355.07 138.461 421.697 148.698 421.697 148.698V217.77L53.5464 217.77Z" fill="url(#paint3_radial_173_2707)"/>
<path d="M304.343 143.78C297.337 142.983 305.744 140.988 317.753 141.786C307.545 141.759 294.575 142.743 332.365 143.381C367.553 143.976 402.985 148.997 418.523 156.448V217.77H53.5462V187.656C170.238 178.282 312.429 163.564 341.572 158.139C378.001 151.359 317.238 145.249 304.343 143.78Z" fill="url(#paint4_linear_173_2707)"/>
<path d="M160.827 94.1115L159.19 95.475L160.827 95.884V94.1115Z" fill="url(#paint5_linear_173_2707)"/>
<path d="M192.324 91.657L193.688 92.8841L192.324 93.4295V91.657Z" fill="url(#paint6_linear_173_2707)"/>
<path d="M170.511 125.745C172.147 128.063 174.193 131.608 174.329 132.29L173.375 113.337L164.785 112.382C164.785 113.291 165.003 115.655 165.875 117.836C166.966 120.563 168.875 123.427 170.511 125.745Z" fill="url(#paint7_linear_173_2707)"/>
<path d="M160.694 99.9744C160.149 97.3565 160.467 94.6113 160.694 93.5659C160.876 92.9751 161.239 91.6843 161.239 91.248C161.239 90.8116 167.602 88.8846 170.784 87.9756L172.693 99.9744V110.473L173.238 113.882C172.284 113.928 169.448 113.991 167.921 113.882C166.393 113.773 164.739 113.291 164.103 113.064C163.557 111.246 162.439 107.31 162.33 106.11C162.194 104.61 161.376 103.247 160.694 99.9744Z" fill="url(#paint8_linear_173_2707)"/>
<path d="M188.51 138.153C188.292 135.862 187.601 132.199 187.283 130.653C184.874 135.698 181.011 146.334 181.011 146.334C181.011 146.334 182.996 149.606 182.374 154.787C181.965 158.196 182.374 162.287 183.601 165.695C184.829 169.104 186.192 170.468 187.692 172.104C189.192 173.74 190.692 176.603 191.782 179.194C192.655 181.267 193.51 185.421 193.828 187.239C194.191 187.466 195.3 187.866 196.827 187.648C198.355 187.43 199.464 186.375 199.827 185.875C199.327 183.603 198.027 177.667 196.827 172.104C195.328 165.15 193.01 153.969 192.464 151.379C191.919 148.788 191.646 146.197 190.828 144.152C190.01 142.107 188.783 141.016 188.51 138.153Z" fill="url(#paint9_linear_173_2707)"/>
<path d="M199.144 182.739C199.822 181.512 200.231 187.511 200.781 188.602C201.33 189.693 199.281 190.92 197.236 191.056C195.599 191.165 194.103 191.465 194.236 190.102C194.368 188.738 192.187 183.928 193.145 184.102C194.103 184.277 194.972 184.321 196.281 184.102C197.59 183.884 198.467 183.966 199.144 182.739Z" fill="white"/>
<path d="M211.417 184.648C212.29 184.43 212.962 185.193 213.189 185.602C214.008 187.511 213.189 189.147 212.371 190.92C211.553 192.693 209.644 194.056 207.872 194.602C206.099 195.147 205.417 194.602 203.372 195.42C201.327 196.238 201.327 197.874 198.464 199.101C195.6 200.328 193.691 198.828 193.419 198.419C193.146 198.01 193.691 195.829 193.691 194.874C193.691 193.92 193.146 193.102 193.146 192.42C193.146 191.875 193.964 190.738 194.373 190.238C194.464 190.375 194.864 190.702 195.737 190.92C196.827 191.193 198.873 190.647 200.1 189.693C201.082 188.929 200.782 188.102 200.509 187.784C200.963 187.739 202.363 187.62 204.327 187.511C206.781 187.375 210.326 184.921 211.417 184.648Z" fill="url(#paint10_linear_173_2707)"/>
<path d="M181.418 111.428C185.018 112.409 189.281 115.018 190.963 116.2C190.372 119.381 189.054 126.044 188.509 127.244C187.827 128.744 183.327 141.425 181.691 144.697C180.055 147.969 177.601 147.424 175.419 147.015C173.237 146.606 172.692 145.515 166.829 144.152C162.138 143.061 158.148 143.061 156.739 143.197L158.648 129.289L160.693 130.108C162.657 130.435 163.141 129.19 166.829 129.289C171.874 129.426 175.041 134.471 174.192 131.335C172.419 124.79 172.692 118.654 172.692 115.245C172.692 112.518 172.328 110.655 172.147 110.064C173.737 110.11 177.819 110.446 181.418 111.428Z" fill="url(#paint11_linear_173_2707)"/>
<path d="M157.83 128.063C158.812 128.499 160.239 129.699 160.148 129.972C158.648 130.79 158.648 136.516 158.648 137.334V137.335C158.648 138.153 160.83 142.38 160.148 143.198C159.467 144.016 155.24 143.198 155.24 142.107C155.24 141.016 153.603 136.925 151.831 134.471C150.058 132.017 151.149 129.426 152.513 128.608C153.876 127.79 154.285 125.608 154.422 126.427C154.558 127.245 156.603 127.517 157.83 128.063Z" fill="url(#paint12_linear_173_2707)"/>
<path d="M157.559 145.924C157.777 145.052 156.013 143.015 155.104 142.106L148.696 148.106L139.015 168.013C139.015 168.604 138.329 169.895 139.966 169.786C142.011 169.649 143.643 168.558 145.011 167.059C146.736 165.166 148.15 160.377 149.241 156.832C150.332 153.287 151.832 152.06 152.786 150.696C153.741 149.333 157.286 147.015 157.559 145.924Z" fill="#E64715"/>
<path d="M151.559 122.199C150.577 121.763 148.241 120.472 147.195 119.881L143.241 128.199V149.606L141.601 168.422C141.919 168.104 144.055 166.513 145.286 164.604C146.123 163.308 147.468 160.241 147.741 158.878C148.013 157.514 150.877 150.969 151.286 149.606C151.695 148.242 154.831 144.561 155.24 141.97C155.649 139.38 151.013 133.925 150.877 132.017C150.741 130.108 151.559 129.426 153.195 128.199C154.831 126.972 154.286 126.29 154.286 124.79C154.286 123.29 152.786 122.745 151.559 122.199Z" fill="url(#paint13_linear_173_2707)"/>
<path d="M147.195 119.881L142.423 118.654L143.105 147.697C143.105 153.969 143.323 166.104 144.196 164.468C145.559 162.832 147.059 156.151 147.059 154.105C147.059 152.06 146.923 144.97 147.059 142.243C147.195 139.516 147.877 138.016 149.65 130.108C151.068 123.781 148.604 120.654 147.195 119.881Z" fill="url(#paint14_linear_173_2707)"/>
<path d="M135.742 126.699C135.524 121.245 139.56 118.382 142.287 118.654C150.058 120.018 147.332 132.289 146.377 135.698C145.614 138.425 145.059 142.289 144.877 143.879C144.332 151.379 146.928 152.469 145.423 159.832C143.917 167.195 140.65 170.74 138.469 169.649C136.287 168.559 132.469 159.423 132.333 154.787C132.197 150.151 135.742 144.288 136.423 139.243C137.105 134.198 136.014 133.517 135.742 126.699Z" fill="#1E1C51"/>
<path d="M198.459 199.101C195.596 200.328 193.687 198.828 193.414 198.419V197.329C193.959 197.692 195.265 198.392 196.138 198.283C197.228 198.147 199.001 197.601 200.092 196.51C201.183 195.42 202.137 194.465 204.319 194.329C206.5 194.192 211.008 193.511 212.367 190.92C211.549 192.693 209.64 194.056 207.868 194.602C206.095 195.147 205.413 194.602 203.368 195.42C201.323 196.238 201.323 197.874 198.459 199.101Z" fill="url(#paint15_linear_173_2707)"/>
<path d="M200.231 187.784C200.34 187.13 200.277 186.693 200.231 186.557C200.413 186.693 201.128 187.264 202.686 187.375C204.595 187.511 205.758 187.073 207.049 186.557C208.412 186.012 210.048 185.057 211.412 184.648C212.775 184.239 213.457 186.012 212.912 185.33C212.366 184.648 211.276 185.603 210.185 186.148C209.323 186.579 208.685 187.239 207.049 187.92C205.413 188.602 203.776 189.148 201.186 189.284C199.633 189.366 200.095 188.602 200.231 187.784Z" fill="#E64715"/>
<path d="M176.101 110.473C181.228 110.801 188.237 114.428 191.1 116.2C191.418 114.019 192.191 108.565 192.736 104.201C193.418 98.7473 192.873 96.5657 192.873 94.1114C192.873 92.1479 192.736 90.8844 192.736 89.8845L189.191 86.8848L176.511 86.2031L169.557 89.8845V93.566C169.557 96.1566 171.193 98.4746 170.92 102.156C170.702 105.101 169.284 108.383 168.602 109.655C168.966 109.792 170.975 110.146 176.101 110.473Z" fill="url(#paint16_linear_173_2707)"/>
<path d="M192.868 80.4763V75.8403C192.868 75.3792 192.913 74.869 192.988 74.3404H160.402C160.495 74.8817 160.553 75.3947 160.553 75.8403C160.553 78.2947 159.872 80.4763 159.872 82.6579C159.872 84.8395 159.19 87.1574 159.19 90.5662V95.4748C159.417 95.0658 161.808 94.1931 163.553 92.8841C165.735 91.2479 172.28 89.6117 179.779 89.0663C185.778 88.63 191.778 91.4297 193.687 92.8841C193.687 91.7479 193.523 89.0663 192.868 87.4301C192.05 85.3849 192.868 83.3396 192.868 80.4763Z" fill="url(#paint17_linear_173_2707)"/>
<path d="M147.601 81.9761C146.619 80.6672 146.267 76.5219 146.448 74.3403H137.858C138.631 76.6128 140.456 81.158 139.692 83.3396C138.738 86.0667 139.829 86.8848 139.692 89.4754C139.556 92.0661 141.192 95.6112 142.283 95.8839C143.374 96.1566 144.192 97.2474 145.146 97.2474C146.101 97.2474 148.419 97.9291 149.51 98.0655C150.6 98.2018 151.146 97.3837 152.1 97.2474C153.055 97.111 152.646 95.3385 153.736 94.2477C154.827 93.1569 153.873 91.7934 153.736 91.1116C153.6 90.4299 151.691 87.4302 151.146 85.6576C150.6 83.885 148.828 83.6123 147.601 81.9761Z" fill="url(#paint18_linear_173_2707)"/>
<path d="M149.104 86.0063C150.086 86.2245 152.058 89.7332 152.922 91.4603C151.513 91.1876 148.45 90.4241 147.468 89.5514C146.241 88.4606 144.195 88.3243 142.15 88.1879C140.105 88.0516 144.332 87.5062 145.832 87.3698C147.332 87.2335 146.513 85.1882 146.513 84.7791C146.513 84.3701 147.877 85.7336 149.104 86.0063Z" fill="url(#paint19_linear_173_2707)"/>
<path d="M214.397 83.1999C214.806 81.5637 217.533 79.7911 219.306 79.1093C220.467 78.6628 222.56 76.1754 223.954 74.3404H212.874C212.344 75.4837 212.559 77.0007 210.852 79.1093C208.534 81.9727 207.58 81.1546 206.08 81.1546C204.58 81.1546 202.262 82.5181 202.262 83.1999C202.262 83.8816 201.989 87.0177 202.262 87.9721C202.535 88.9266 203.898 90.4264 205.126 91.2445C206.353 92.0626 207.989 91.3809 209.08 91.2445C210.17 91.1082 211.398 88.3812 211.807 87.9721C212.216 87.5631 213.988 84.8361 214.397 83.1999Z" fill="url(#paint20_linear_173_2707)"/>
</g>
<path d="M202.085 53.3222C202.085 49.5603 205.135 46.5107 208.897 46.5107H255.215C258.977 46.5107 262.027 49.5603 262.027 53.3222V53.3222C262.027 57.0842 258.977 60.1338 255.215 60.1338H208.897C205.135 60.1338 202.085 57.0842 202.085 53.3222V53.3222Z" fill="#FF4F17"/>
<path className={fillClassName} d="M51.3408 52.3085C51.3408 50.3873 52.8983 48.8298 54.8195 48.8298H122.075C123.996 48.8298 125.554 50.3873 125.554 52.3085V52.3085C125.554 54.2297 123.996 55.7872 122.075 55.7872H54.8195C52.8983 55.7872 51.3408 54.2297 51.3408 52.3085V52.3085Z" fill="#D4DCE1"/>
<path className={fillClassName} d="M130.191 52.3085C130.191 50.3873 131.749 48.8298 133.67 48.8298H161.5C163.421 48.8298 164.979 50.3873 164.979 52.3085V52.3085C164.979 54.2297 163.421 55.7872 161.5 55.7872H133.67C131.749 55.7872 130.191 54.2297 130.191 52.3085V52.3085Z" fill="#D4DCE1"/>
<path className={fillClassName} d="M380.66 52.3085C380.66 50.3873 382.218 48.8298 384.139 48.8298H411.969C413.89 48.8298 415.447 50.3873 415.447 52.3085V52.3085C415.447 54.2297 413.89 55.7872 411.969 55.7872H384.139C382.218 55.7872 380.66 54.2297 380.66 52.3085V52.3085Z" fill="#D4DCE1"/>
<path className={boxBgClassName} d="M51 252.915C51 246.511 56.1916 241.319 62.5957 241.319H118.062C124.466 241.319 129.658 246.511 129.658 252.915V308.371C129.658 314.775 124.466 319.966 118.062 319.966H62.5957C56.1916 319.966 51 314.775 51 308.371V252.915Z" fill="#F4F7FA"/>
<path className={boxBgClassName} d="M146 252.596C146 246.192 151.192 241 157.596 241H213.062C219.466 241 224.658 246.192 224.658 252.596V308.051C224.658 314.456 219.466 319.647 213.062 319.647H157.596C151.192 319.647 146 314.456 146 308.051V252.596Z" fill="#F4F7FA"/>
<path className={boxBgClassName} d="M239 252.915C239 246.511 244.192 241.319 250.596 241.319H306.062C312.466 241.319 317.658 246.511 317.658 252.915V308.371C317.658 314.775 312.466 319.966 306.062 319.966H250.596C244.192 319.966 239 314.775 239 308.371V252.915Z" fill="#F4F7FA"/>
<path className={boxBgClassName} d="M334 252.915C334 246.511 339.192 241.319 345.596 241.319H401.062C407.466 241.319 412.658 246.511 412.658 252.915V308.371C412.658 314.775 407.466 319.966 401.062 319.966H345.596C339.192 319.966 334 314.775 334 308.371V252.915Z" fill="#F4F7FA"/>
<path fillRule="evenodd" clipRule="evenodd" d="M90 292L90 270L92 270L92 292L90 292Z" fill="#4FC865"/>
<path fillRule="evenodd" clipRule="evenodd" d="M102 282H80V280H102V282Z" fill="#4FC865"/>
<path fillRule="evenodd" clipRule="evenodd" d="M185 292L185 270L187 270L187 292L185 292Z" fill="#4FC865"/>
<path fillRule="evenodd" clipRule="evenodd" d="M197 282H175V280H197V282Z" fill="#4FC865"/>
<path fillRule="evenodd" clipRule="evenodd" d="M278 292L278 270L280 270L280 292L278 292Z" fill="#4FC865"/>
<path fillRule="evenodd" clipRule="evenodd" d="M290 282H268V280H290V282Z" fill="#4FC865"/>
<path fillRule="evenodd" clipRule="evenodd" d="M373 292L373 270L375 270L375 292L373 292Z" fill="#4FC865"/>
<path fillRule="evenodd" clipRule="evenodd" d="M385 282H363V280H385V282Z" fill="#4FC865"/>
<defs>
<filter id="filter0_d_173_2707" x="0.830078" y="0" width="460.489" height="377" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="12.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.823529 0 0 0 0 0.87451 0 0 0 0 0.909804 0 0 0 0.44 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_173_2707"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_173_2707" result="shape"/>
</filter>
<radialGradient id="paint0_radial_173_2707" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(318.045 143.23) rotate(-89.301) scale(146.487 503.917)">
<stop offset="0.10248" stopColor="#FCF1D4"/>
<stop offset="0.525154" stopColor="#E880E4"/>
<stop offset="1" stopColor="#4200FF"/>
</radialGradient>
<linearGradient id="paint1_linear_173_2707" x1="195.813" y1="95.7408" x2="193.751" y2="139.014" gradientUnits="userSpaceOnUse">
<stop stopColor="#4200FF"/>
<stop offset="1" stopColor="#EC95E1"/>
</linearGradient>
<linearGradient id="paint2_linear_173_2707" x1="391.168" y1="109.502" x2="381.646" y2="157.683" gradientUnits="userSpaceOnUse">
<stop stopColor="#4200FF"/>
<stop offset="1" stopColor="#EC95E1"/>
</linearGradient>
<radialGradient id="paint3_radial_173_2707" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(247.626 138.428) rotate(92.2055) scale(51.9938 447.81)">
<stop stopColor="#20FFBC"/>
<stop offset="1" stopColor="#4200FF"/>
</radialGradient>
<linearGradient id="paint4_linear_173_2707" x1="276.125" y1="141.786" x2="276.125" y2="217.77" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFACCB"/>
<stop offset="1" stopColor="#4200FF"/>
</linearGradient>
<linearGradient id="paint5_linear_173_2707" x1="163.417" y1="96.4294" x2="159.162" y2="95.9728" gradientUnits="userSpaceOnUse">
<stop stopColor="#CF3200"/>
<stop offset="1" stopColor="#FFB7A0"/>
</linearGradient>
<linearGradient id="paint6_linear_173_2707" x1="189.733" y1="93.9749" x2="194.472" y2="93.5008" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFB7A0"/>
<stop offset="1" stopColor="#CF3200"/>
</linearGradient>
<linearGradient id="paint7_linear_173_2707" x1="191.369" y1="120.291" x2="170.78" y2="119.882" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="1" stopColor="#CE7DB0"/>
</linearGradient>
<linearGradient id="paint8_linear_173_2707" x1="161.83" y1="98.5983" x2="188.045" y2="97.5567" gradientUnits="userSpaceOnUse">
<stop stopColor="#CF3200"/>
<stop offset="1" stopColor="#FF7F57"/>
</linearGradient>
<linearGradient id="paint9_linear_173_2707" x1="195.324" y1="166.65" x2="185.67" y2="168.765" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="1" stopColor="#D987BA"/>
</linearGradient>
<linearGradient id="paint10_linear_173_2707" x1="207.595" y1="197.192" x2="208.004" y2="185.602" gradientUnits="userSpaceOnUse">
<stop stopColor="#4200FF"/>
<stop offset="1" stopColor="#20FFBC"/>
</linearGradient>
<linearGradient id="paint11_linear_173_2707" x1="188.505" y1="133.789" x2="174.461" y2="124.654" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="1" stopColor="#D987BA"/>
</linearGradient>
<linearGradient id="paint12_linear_173_2707" x1="155.34" y1="129.836" x2="162.655" y2="143.466" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFD7F3"/>
<stop offset="0.359375" stopColor="#FCF1D4"/>
<stop offset="1" stopColor="white"/>
</linearGradient>
<linearGradient id="paint13_linear_173_2707" x1="158.645" y1="137.334" x2="132.875" y2="148.106" gradientUnits="userSpaceOnUse">
<stop stopColor="#20FFBC"/>
<stop offset="1" stopColor="#4200FF"/>
</linearGradient>
<linearGradient id="paint14_linear_173_2707" x1="146.249" y1="118.654" x2="146.249" y2="164.618" gradientUnits="userSpaceOnUse">
<stop stopColor="#1E1C51"/>
<stop offset="1" stopColor="#1E1C51" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint15_linear_173_2707" x1="202.891" y1="190.92" x2="202.891" y2="199.574" gradientUnits="userSpaceOnUse">
<stop stopColor="#1E1C51"/>
<stop offset="1" stopColor="#1E1C51" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint16_linear_173_2707" x1="171.462" y1="98.4746" x2="192.46" y2="101.883" gradientUnits="userSpaceOnUse">
<stop stopColor="#CF3200"/>
<stop offset="1" stopColor="#FF7F57"/>
</linearGradient>
<linearGradient id="paint17_linear_173_2707" x1="172.248" y1="88.3216" x2="192.299" y2="87.157" gradientUnits="userSpaceOnUse">
<stop stopColor="#CF3200"/>
<stop offset="0.478853" stopColor="#E36842"/>
<stop offset="1" stopColor="#FFB7A0"/>
</linearGradient>
<linearGradient id="paint18_linear_173_2707" x1="153.109" y1="89.2582" x2="142.482" y2="95.5388" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="0.565975" stopColor="#D693B6"/>
<stop offset="1" stopColor="#C875A9"/>
</linearGradient>
<linearGradient id="paint19_linear_173_2707" x1="147.329" y1="97.3838" x2="149.159" y2="85.7743" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="1" stopColor="#CE7DB0"/>
</linearGradient>
<linearGradient id="paint20_linear_173_2707" x1="214.896" y1="84.4305" x2="204.809" y2="78.096" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="0.565975" stopColor="#D693B6"/>
<stop offset="1" stopColor="#C875A9"/>
</linearGradient>
</defs>
</svg>
  )
}

export const TemplateThree = ({ mainClassName, bgClassName }) => {
  return (
    <svg className={mainClassName} width="461" height="377" viewBox="0 0 461 377" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_173_2599)">
<path className={bgClassName} d="M25.0215 32.5958C25.0215 26.1916 30.2131 21 36.6172 21H423.915C430.319 21 435.511 26.1916 435.511 32.5957V336.404C435.511 342.808 430.319 348 423.915 348H36.6172C30.2131 348 25.0215 342.808 25.0215 336.404V32.5958Z" fill="white"/>
</g>
<mask id="mask0_173_2599" maskUnits="userSpaceOnUse" x="25" y="21" width="411" height="327">
<path d="M25.0215 32.5958C25.0215 26.1916 30.2131 21 36.6172 21H423.915C430.319 21 435.511 26.1916 435.511 32.5957V336.404C435.511 342.808 430.319 348 423.915 348H36.6172C30.2131 348 25.0215 342.808 25.0215 336.404V32.5958Z" fill="#C4C4C4"/>
</mask>
<g mask="url(#mask0_173_2599)">
<path d="M464.02 21H0.0344478V268.896H464.02V21Z" fill="url(#paint0_radial_173_2599)"/>
<path d="M234.044 161.095C239.496 161.095 248.601 165.639 252.472 167.911L260.045 169.678L-2.4929 176.251C20.1426 174.064 107.319 165.397 119.436 163.115C123.901 162.274 148.971 153.522 161.088 151.755C173.205 149.988 174.215 143.939 183.303 143.939C192.391 143.939 210.314 139.143 213.343 141.162C216.373 143.182 227.228 161.095 234.044 161.095Z" fill="url(#paint1_linear_173_2599)"/>
<path d="M305.49 167.92V170.949H223.195V165.143H227.739V168.173H229.758V167.163H231.273V168.173H235.312V165.143H237.584V158.58H242.38V169.435H247.177V168.173H250.963V165.648H253.488V168.173H254.497V167.163H260.808V161.357H262.071V168.173H263.585V165.143H264.847V168.93H269.391V166.153H275.197V165.143H282.013V163.881H291.606V169.435H292.363V162.871L293.626 161.862L294.13 138.637L297.917 142.171V170.697H299.432V168.677H300.189V167.92V160.599H301.956V150.754H304.228V167.92H305.49Z" fill="#B968EA"/>
<path d="M450.135 149.492C457.81 149.088 462.589 144.106 464.02 141.666V186.348L340.576 173.221C346.046 172.127 358.045 169.687 362.286 168.677C367.587 167.415 370.112 166.153 371.626 166.153C373.141 166.153 374.656 167.415 379.957 164.638C385.258 161.861 403.181 161.104 408.988 161.104C414.794 161.104 428.425 155.55 430.193 154.036C431.96 152.521 440.543 149.997 450.135 149.492Z" fill="url(#paint2_linear_173_2599)"/>
<path d="M-0.218436 268.896V175.998C-0.218436 175.998 86.8734 169.393 233.541 169.435C380.209 169.477 464.271 182.309 464.271 182.309V268.896L-0.218436 268.896Z" fill="url(#paint3_radial_173_2599)"/>
<path d="M316.09 175.494C307.254 174.484 317.857 171.959 333.003 172.969C320.129 172.935 303.77 174.181 351.431 174.989C399.092 175.797 447.106 183.067 464.02 193.669V354.958H-0.217971V231.03C146.955 219.166 326.288 200.536 363.043 193.669C408.988 185.086 332.353 177.352 316.09 175.494Z" fill="url(#paint4_linear_173_2599)"/>
<path d="M113.135 53.6388L110.115 56.1552L113.135 56.9102V53.6388Z" fill="url(#paint5_linear_173_2599)"/>
<path d="M171.266 49.1089L173.782 51.3737L171.266 52.3803V49.1089Z" fill="url(#paint6_linear_173_2599)"/>
<path d="M131.009 112.019C134.028 116.297 137.803 122.84 138.055 124.098L136.293 89.12L120.44 87.3585C120.44 89.0361 120.842 93.3979 122.453 97.4242C124.466 102.457 127.989 107.742 131.009 112.019Z" fill="url(#paint7_linear_173_2599)"/>
<path d="M112.891 64.4602C111.884 59.6287 112.471 54.5623 112.891 52.633C113.226 51.5426 113.897 49.1604 113.897 48.3551C113.897 47.5499 125.641 43.9933 131.512 42.3157L135.035 64.4602V83.8367L136.042 90.1278C134.28 90.2116 129.046 90.3291 126.228 90.1278C123.41 89.9264 120.356 89.0373 119.182 88.6179C118.175 85.2627 116.112 77.9986 115.911 75.7841C115.659 73.0161 114.149 70.4997 112.891 64.4602Z" fill="url(#paint8_linear_173_2599)"/>
<path d="M164.229 134.92C163.826 130.692 162.551 123.932 161.964 121.08C157.518 130.39 150.389 150.018 150.389 150.018C150.389 150.018 154.052 156.058 152.905 165.62C152.15 171.911 152.905 179.461 155.17 185.752C157.435 192.043 159.951 194.559 162.719 197.579C165.487 200.599 168.255 205.883 170.268 210.664C171.879 214.489 173.456 222.156 174.043 225.511C174.714 225.931 176.761 226.669 179.579 226.266C182.397 225.864 184.444 223.917 185.115 222.995C184.193 218.801 181.794 207.846 179.579 197.579C176.811 184.745 172.533 164.11 171.527 159.329C170.52 154.548 170.017 149.767 168.507 145.992C166.997 142.218 164.732 140.204 164.229 134.92Z" fill="url(#paint9_linear_173_2599)"/>
<path d="M183.854 217.206C185.106 214.942 185.86 226.014 186.874 228.027C187.888 230.04 184.106 232.305 180.331 232.556C177.312 232.758 174.551 233.311 174.795 230.795C175.04 228.279 171.014 219.401 172.782 219.723C174.551 220.044 176.154 220.125 178.57 219.723C180.986 219.32 182.603 219.471 183.854 217.206Z" fill="white"/>
<path d="M206.502 220.728C208.112 220.326 209.354 221.735 209.773 222.49C211.283 226.013 209.773 229.033 208.263 232.304C206.753 235.575 203.23 238.092 199.959 239.098C196.688 240.105 195.429 239.098 191.655 240.608C187.88 242.118 187.88 245.138 182.596 247.403C177.311 249.667 173.788 246.899 173.285 246.144C172.782 245.389 173.788 241.363 173.788 239.602C173.788 237.84 172.782 236.33 172.782 235.072C172.782 234.065 174.291 231.968 175.046 231.046C175.214 231.297 175.952 231.901 177.563 232.304C179.576 232.807 183.351 231.801 185.615 230.039C187.427 228.63 186.874 227.103 186.37 226.516C187.209 226.432 189.793 226.214 193.416 226.013C197.946 225.761 204.489 221.232 206.502 220.728Z" fill="url(#paint10_linear_173_2599)"/>
<path d="M151.14 85.5976C157.783 87.4094 165.651 92.2242 168.755 94.4051C167.664 100.277 165.232 112.574 164.225 114.788C162.967 117.556 154.663 140.959 151.643 146.998C148.623 153.038 144.094 152.031 140.068 151.276C136.041 150.521 135.035 148.508 124.214 145.992C115.558 143.979 108.193 143.979 105.593 144.23L109.116 118.563L112.89 120.073C116.514 120.677 117.408 118.379 124.214 118.563C133.525 118.814 139.37 128.125 137.803 122.337C134.532 110.259 135.035 98.9346 135.035 92.6436C135.035 87.6107 134.364 84.1716 134.028 83.0811C136.964 83.165 144.497 83.7857 151.14 85.5976Z" fill="url(#paint11_linear_173_2599)"/>
<path d="M107.605 116.298C109.417 117.103 112.051 119.318 111.883 119.821C109.115 121.331 109.115 131.899 109.115 133.41V133.41C109.115 134.92 113.142 142.721 111.883 144.23C110.625 145.74 102.824 144.23 102.824 142.217C102.824 140.204 99.8045 132.655 96.5331 128.125C93.2618 123.596 95.2749 118.814 97.7913 117.305C100.308 115.795 101.063 111.769 101.314 113.278C101.566 114.788 105.34 115.292 107.605 116.298Z" fill="url(#paint12_linear_173_2599)"/>
<path d="M107.104 149.263C107.507 147.652 104.253 143.895 102.575 142.217L90.7478 153.289L72.8811 190.029C72.8811 191.119 71.6158 193.502 74.6355 193.3C78.4102 193.049 81.4227 191.036 83.9463 188.267C87.1305 184.775 89.7412 175.937 91.7543 169.394C93.7675 162.852 96.5355 160.587 98.297 158.07C100.059 155.554 106.601 151.276 107.104 149.263Z" fill="#E64715"/>
<path d="M96.0295 105.478C94.2177 104.672 89.9062 102.29 87.9769 101.2L80.6794 116.55V156.058L77.6525 190.784C78.2397 190.197 82.1821 187.261 84.454 183.738C85.997 181.346 88.4803 175.686 88.9836 173.169C89.4869 170.653 94.7714 158.574 95.5263 156.058C96.2812 153.541 102.069 146.747 102.824 141.966C103.579 137.185 95.023 127.119 94.7714 123.596C94.5197 120.073 96.0295 118.815 99.0492 116.55C102.069 114.285 101.062 113.027 101.062 110.259C101.062 107.491 98.2943 106.484 96.0295 105.478Z" fill="url(#paint13_linear_173_2599)"/>
<path d="M87.977 101.198L79.1695 98.9333L80.4278 152.533C80.4278 164.109 80.8304 186.505 82.4409 183.485C84.9573 180.465 87.7254 168.135 87.7254 164.36C87.7254 160.586 87.4737 147.5 87.7254 142.467C87.977 137.435 89.2352 134.667 92.5066 120.071C95.1237 108.395 90.5773 102.624 87.977 101.198Z" fill="url(#paint14_linear_173_2599)"/>
<path d="M66.8393 113.782C66.4366 103.716 73.8853 98.4318 78.9181 98.9351C93.2616 101.451 88.2289 124.099 86.4674 130.39C85.0583 135.423 84.0349 142.553 83.6993 145.489C82.6927 159.329 87.4848 161.342 84.7059 174.931C81.927 188.52 75.8985 195.062 71.8722 193.049C67.8459 191.036 60.7998 174.176 60.5482 165.62C60.2966 157.064 66.8393 146.244 68.0975 136.933C69.3557 127.622 67.3426 126.364 66.8393 113.782Z" fill="#1E1C51"/>
<path d="M182.588 247.404C177.304 249.668 173.781 246.9 173.278 246.145V244.132C174.282 244.803 176.693 246.095 178.303 245.894C180.316 245.642 183.588 244.636 185.601 242.622C187.614 240.609 189.376 238.848 193.402 238.596C197.428 238.344 205.747 237.086 208.256 232.305C206.746 235.576 203.223 238.093 199.952 239.099C196.68 240.106 195.422 239.099 191.647 240.609C187.873 242.119 187.873 245.139 182.588 247.404Z" fill="url(#paint15_linear_173_2599)"/>
<path d="M185.859 226.518C186.06 225.31 185.943 224.505 185.859 224.253C186.194 224.505 187.514 225.558 190.389 225.763C193.912 226.015 196.058 225.206 198.441 224.253C200.958 223.247 203.977 221.485 206.494 220.73C209.01 219.975 210.268 223.247 209.262 221.989C208.255 220.73 206.242 222.492 204.229 223.498C202.637 224.294 201.461 225.512 198.441 226.77C195.421 228.028 192.402 229.035 187.62 229.286C184.755 229.437 185.607 228.028 185.859 226.518Z" fill="#E64715"/>
<path d="M141.325 83.8366C150.787 84.4405 163.721 91.1342 169.006 94.4055C169.593 90.3793 171.019 80.3136 172.025 72.261C173.284 62.1953 172.277 58.169 172.277 53.6395C172.277 50.0158 172.025 47.6839 172.025 45.8386L165.483 40.3024L142.08 39.0442L129.246 45.8386V52.6329C129.246 57.4141 132.266 61.692 131.763 68.4864C131.36 73.9219 128.743 79.978 127.485 82.3267C128.156 82.5784 131.863 83.2326 141.325 83.8366Z" fill="url(#paint16_linear_173_2599)"/>
<path d="M172.271 19.9189C172.271 16.0939 173.948 10.4404 174.787 8.09171C155.327 7.67231 110.719 7.08514 110.115 7.08514C109.36 7.08514 112.631 15.3893 112.631 19.9189C112.631 24.4485 111.373 28.4747 111.373 32.501C111.373 36.5273 110.115 40.8052 110.115 47.0963V56.1554C110.534 55.4005 114.947 53.79 118.168 51.3742C122.194 48.3545 134.273 45.3348 148.113 44.3282C159.185 43.523 170.258 48.69 173.781 51.3742C173.781 49.2772 173.479 44.3282 172.271 41.3085C170.761 37.5339 172.271 33.7592 172.271 28.4747V19.9189Z" fill="url(#paint17_linear_173_2599)"/>
<path d="M88.7247 31.2423C86.9129 28.8265 87.131 20.17 87.4665 16.1438H71.6131C73.0391 20.3378 75.5387 29.7325 74.1296 33.7587C72.3681 38.7916 74.3812 40.3014 74.1296 45.0826C73.8779 49.8638 76.8976 56.4065 78.9108 56.9098C80.9239 57.4131 82.4337 59.4263 84.1952 59.4263C85.9567 59.4263 90.2347 60.6845 92.2478 60.9361C94.2609 61.1878 95.2674 59.6779 97.029 59.4263C98.7906 59.1746 98.0355 55.9033 100.049 53.8901C102.062 51.877 100.3 49.3606 100.049 48.1024C99.797 46.8441 96.274 41.308 95.2674 38.0367C94.2609 34.7653 90.9895 34.262 88.7247 31.2423Z" fill="url(#paint18_linear_173_2599)"/>
<path d="M91.4999 38.6808C93.3117 39.0834 96.9522 45.559 98.5459 48.7465C95.9456 48.2432 90.292 46.834 88.4802 45.2235C86.2154 43.2104 82.4408 42.9587 78.6662 42.7071C74.8915 42.4554 82.6924 41.4489 85.4605 41.1972C88.2286 40.9456 86.7187 37.1709 86.7187 36.416C86.7187 35.6611 89.2351 38.1775 91.4999 38.6808Z" fill="url(#paint19_linear_173_2599)"/>
<path d="M221.063 25.9519C223.68 24.9454 228.864 18.3188 231.129 15.1313C225.593 14.3764 213.816 13.3195 210.998 15.1313C207.475 17.3961 209.739 20.6675 205.461 25.9519C201.183 31.2364 199.422 29.7266 196.654 29.7266C193.886 29.7266 189.608 32.243 189.608 33.5012C189.608 34.7594 189.105 40.5472 189.608 42.3087C190.111 44.0702 192.628 46.8383 194.892 48.3481C197.157 49.858 200.177 48.5998 202.19 48.3481C204.203 48.0965 206.468 43.0636 207.223 42.3087C207.978 41.5538 211.249 36.5209 212.004 33.5012C212.759 30.4815 217.792 27.2102 221.063 25.9519Z" fill="url(#paint20_linear_173_2599)"/>
</g>
<path d="M168.809 306.255C168.809 298.57 174.944 292.34 182.513 292.34H275.7C283.269 292.34 289.404 298.57 289.404 306.255V306.255C289.404 313.94 283.269 320.17 275.7 320.17H182.513C174.944 320.17 168.809 313.94 168.809 306.255V306.255Z" fill="#E64715"/>
<path opacity="0.5" d="M124.745 272.628C124.745 270.706 126.303 269.149 128.224 269.149H329.99C331.911 269.149 333.469 270.706 333.469 272.628V272.628C333.469 274.549 331.911 276.106 329.99 276.106H128.224C126.303 276.106 124.745 274.549 124.745 272.628V272.628Z" fill="white"/>
<path opacity="0.5" d="M50.5322 49.9894C50.5322 48.0681 52.0897 46.5107 54.011 46.5107H121.266C123.188 46.5107 124.745 48.0681 124.745 49.9894V49.9894C124.745 51.9106 123.188 53.4681 121.266 53.4681H54.011C52.0897 53.4681 50.5322 51.9106 50.5322 49.9894V49.9894Z" fill="white"/>
<path opacity="0.5" d="M129.383 49.9894C129.383 48.0681 130.94 46.5107 132.862 46.5107H160.691C162.613 46.5107 164.17 48.0681 164.17 49.9894V49.9894C164.17 51.9106 162.613 53.4681 160.691 53.4681H132.862C130.94 53.4681 129.383 51.9106 129.383 49.9894V49.9894Z" fill="white"/>
<path opacity="0.5" d="M377.531 49.9894C377.531 48.0681 379.089 46.5107 381.01 46.5107H408.84C410.761 46.5107 412.318 48.0681 412.318 49.9894V49.9894C412.318 51.9106 410.761 53.4681 408.84 53.4681H381.01C379.089 53.4681 377.531 51.9106 377.531 49.9894V49.9894Z" fill="white"/>
<defs>
<filter id="filter0_d_173_2599" x="0.0214844" y="0" width="460.49" height="377" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="12.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.823529 0 0 0 0 0.87451 0 0 0 0 0.909804 0 0 0 0.44 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_173_2599"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_173_2599" result="shape"/>
</filter>
<radialGradient id="paint0_radial_173_2599" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(336.285 174.988) rotate(-89.2946) scale(184.548 640.614)">
<stop offset="0.10248" stopColor="#FCF1D4"/>
<stop offset="0.525154" stopColor="#E880E4"/>
<stop offset="1" stopColor="#4200FF"/>
</radialGradient>
<linearGradient id="paint1_linear_173_2599" x1="179.517" y1="115.161" x2="176.918" y2="169.678" gradientUnits="userSpaceOnUse">
<stop stopColor="#4200FF"/>
<stop offset="1" stopColor="#EC95E1"/>
</linearGradient>
<linearGradient id="paint2_linear_173_2599" x1="428.425" y1="126.772" x2="414.075" y2="194.03" gradientUnits="userSpaceOnUse">
<stop stopColor="#4200FF"/>
<stop offset="1" stopColor="#EC95E1"/>
</linearGradient>
<radialGradient id="paint3_radial_173_2599" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(244.649 169.435) rotate(92.2198) scale(65.1784 564.99)">
<stop stopColor="#20FFBC"/>
<stop offset="1" stopColor="#4200FF"/>
</radialGradient>
<linearGradient id="paint4_linear_173_2599" x1="282.894" y1="172.969" x2="282.894" y2="269.149" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFACCB"/>
<stop offset="1" stopColor="#4200FF"/>
</linearGradient>
<linearGradient id="paint5_linear_173_2599" x1="117.916" y1="57.9167" x2="110.063" y2="57.0741" gradientUnits="userSpaceOnUse">
<stop stopColor="#CF3200"/>
<stop offset="1" stopColor="#FFB7A0"/>
</linearGradient>
<linearGradient id="paint6_linear_173_2599" x1="166.485" y1="53.3869" x2="175.23" y2="52.5119" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFB7A0"/>
<stop offset="1" stopColor="#CF3200"/>
</linearGradient>
<linearGradient id="paint7_linear_173_2599" x1="169.503" y1="101.954" x2="131.505" y2="101.199" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="1" stopColor="#CE7DB0"/>
</linearGradient>
<linearGradient id="paint8_linear_173_2599" x1="114.987" y1="61.9207" x2="163.368" y2="59.9982" gradientUnits="userSpaceOnUse">
<stop stopColor="#CF3200"/>
<stop offset="1" stopColor="#FF7F57"/>
</linearGradient>
<linearGradient id="paint9_linear_173_2599" x1="176.804" y1="187.513" x2="158.988" y2="191.416" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="1" stopColor="#D987BA"/>
</linearGradient>
<linearGradient id="paint10_linear_173_2599" x1="199.449" y1="243.88" x2="200.203" y2="222.49" gradientUnits="userSpaceOnUse">
<stop stopColor="#4200FF"/>
<stop offset="1" stopColor="#20FFBC"/>
</linearGradient>
<linearGradient id="paint11_linear_173_2599" x1="164.218" y1="126.867" x2="138.299" y2="110.007" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="1" stopColor="#D987BA"/>
</linearGradient>
<linearGradient id="paint12_linear_173_2599" x1="103.009" y1="119.57" x2="116.51" y2="144.725" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFD7F3"/>
<stop offset="0.359375" stopColor="#FCF1D4"/>
<stop offset="1" stopColor="white"/>
</linearGradient>
<linearGradient id="paint13_linear_173_2599" x1="109.108" y1="133.41" x2="61.5474" y2="153.29" gradientUnits="userSpaceOnUse">
<stop stopColor="#20FFBC"/>
<stop offset="1" stopColor="#4200FF"/>
</linearGradient>
<linearGradient id="paint14_linear_173_2599" x1="86.2308" y1="98.9333" x2="86.2308" y2="183.762" gradientUnits="userSpaceOnUse">
<stop stopColor="#1E1C51"/>
<stop offset="1" stopColor="#1E1C51" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint15_linear_173_2599" x1="190.767" y1="232.305" x2="190.767" y2="248.277" gradientUnits="userSpaceOnUse">
<stop stopColor="#1E1C51"/>
<stop offset="1" stopColor="#1E1C51" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint16_linear_173_2599" x1="132.762" y1="61.692" x2="171.515" y2="67.9831" gradientUnits="userSpaceOnUse">
<stop stopColor="#CF3200"/>
<stop offset="1" stopColor="#FF7F57"/>
</linearGradient>
<linearGradient id="paint17_linear_173_2599" x1="134.524" y1="39.547" x2="172.224" y2="37.7758" gradientUnits="userSpaceOnUse">
<stop stopColor="#CF3200"/>
<stop offset="0.478853" stopColor="#E36842"/>
<stop offset="1" stopColor="#FFB7A0"/>
</linearGradient>
<linearGradient id="paint18_linear_173_2599" x1="98.8911" y1="44.6819" x2="79.2785" y2="56.273" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="0.565975" stopColor="#D693B6"/>
<stop offset="1" stopColor="#C875A9"/>
</linearGradient>
<linearGradient id="paint19_linear_173_2599" x1="88.2232" y1="59.6786" x2="91.6013" y2="38.2525" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="1" stopColor="#CE7DB0"/>
</linearGradient>
<linearGradient id="paint20_linear_173_2599" x1="213.794" y1="34.514" x2="193.914" y2="22.6868" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="0.565975" stopColor="#D693B6"/>
<stop offset="1" stopColor="#C875A9"/>
</linearGradient>
</defs>
</svg>
  )
}

export const TemplateFour = ({ mainClassName, bgClassName, fillClassName, boxBgClassName }) => {
  return (
    <svg className={mainClassName} width="461" height="377" viewBox="0 0 461 377" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_173_2638)">
<path className={bgClassName} d="M25.2129 32.5958C25.2129 26.1916 30.4045 21 36.8086 21H424.106C430.511 21 435.702 26.1916 435.702 32.5957V336.404C435.702 342.808 430.511 348 424.106 348H36.8086C30.4045 348 25.2129 342.808 25.2129 336.404V32.5958Z" fill="white"/>
</g>
<mask id="mask0_173_2638" maskUnits="userSpaceOnUse" x="50" y="72" width="363" height="144">
<path d="M50.7227 72.0213H412.51V215.808H50.7227V72.0213Z" fill="#C4C4C4"/>
</mask>
<g mask="url(#mask0_173_2638)">
<path d="M412.314 47.3897H51.0776V240.39H412.314V47.3897Z" fill="url(#paint0_radial_173_2638)"/>
<path d="M233.266 156.46C237.512 156.46 244.6 159.998 247.614 161.767L253.51 163.143L49.1102 168.261C66.7331 166.557 134.604 159.809 144.038 158.033C147.515 157.378 167.033 150.564 176.467 149.188C185.901 147.813 186.687 143.104 193.762 143.104C200.838 143.104 214.792 139.369 217.15 140.942C219.509 142.514 227.96 156.46 233.266 156.46Z" fill="url(#paint1_linear_173_2638)"/>
<path d="M309.602 162.075V164.433H245.53V159.913H249.068V162.272H250.64V161.485H251.819V162.272H254.964V159.913H256.733V154.803H260.467V163.254H264.201V162.272H267.149V160.306H269.115V162.272H269.901V161.485H274.814V156.965H275.797V162.272H276.976V159.913H277.959V162.861H281.497V160.699H286.017V159.913H291.324V158.93H298.792V163.254H299.382V158.144L300.364 157.358L300.757 139.277L303.705 142.028V164.237H304.885V162.665H305.474V162.075V156.375H306.85V148.71H308.619V162.075H309.602Z" fill="#B968EA"/>
<path d="M401.505 147.428C407.48 147.113 411.201 143.235 412.314 141.335V176.122L316.207 165.902C320.466 165.051 329.808 163.151 333.11 162.365C337.237 161.382 339.202 160.399 340.382 160.399C341.561 160.399 342.74 161.382 346.867 159.22C350.995 157.058 364.949 156.468 369.469 156.468C373.99 156.468 384.603 152.145 385.978 150.965C387.354 149.786 394.036 147.821 401.505 147.428Z" fill="url(#paint2_linear_173_2638)"/>
<path d="M50.8799 240.39V168.064C50.8799 168.064 118.685 162.922 232.874 162.954C347.063 162.987 412.51 172.978 412.51 172.978V240.39L50.8799 240.39Z" fill="url(#paint3_radial_173_2638)"/>
<path d="M297.143 167.671C290.264 166.885 298.519 164.92 310.311 165.706C300.288 165.679 287.552 166.649 324.658 167.278C361.765 167.907 399.146 173.567 412.314 181.822V240.587H50.881V210.909C165.463 201.672 305.083 187.168 333.699 181.822C369.469 175.14 309.805 169.118 297.143 167.671Z" fill="url(#paint4_linear_173_2638)"/>
<path d="M248.304 94.4714L246.547 95.9356L248.304 96.3749V94.4714Z" fill="url(#paint5_linear_173_2638)"/>
<path d="M282.128 91.8354L283.592 93.1533L282.128 93.7389V91.8354Z" fill="url(#paint6_linear_173_2638)"/>
<path d="M258.705 128.441C260.462 130.93 262.658 134.737 262.805 135.47L261.78 115.117L252.555 114.092C252.555 115.068 252.789 117.606 253.726 119.949C254.898 122.877 256.948 125.952 258.705 128.441Z" fill="url(#paint7_linear_173_2638)"/>
<path d="M248.162 100.767C247.576 97.9562 247.918 95.0082 248.162 93.8856C248.357 93.2511 248.748 91.865 248.748 91.3964C248.748 90.9279 255.581 88.8584 258.997 87.8823L261.047 100.767V112.042L261.633 115.703C260.608 115.751 257.562 115.82 255.922 115.703C254.282 115.585 252.506 115.068 251.822 114.824C251.237 112.872 250.036 108.645 249.919 107.357C249.773 105.746 248.894 104.282 248.162 100.767Z" fill="url(#paint8_linear_173_2638)"/>
<path d="M278.032 141.766C277.798 139.306 277.056 135.372 276.715 133.713C274.128 139.13 269.979 150.551 269.979 150.551C269.979 150.551 272.111 154.065 271.443 159.629C271.004 163.29 271.443 167.683 272.761 171.343C274.079 175.004 275.543 176.468 277.154 178.225C278.765 179.982 280.375 183.057 281.547 185.839C282.484 188.065 283.401 192.526 283.743 194.478C284.133 194.722 285.324 195.152 286.964 194.917C288.604 194.683 289.795 193.551 290.186 193.014C289.649 190.573 288.253 184.199 286.964 178.225C285.354 170.758 282.864 158.751 282.279 155.969C281.693 153.187 281.4 150.405 280.522 148.208C279.643 146.012 278.325 144.841 278.032 141.766Z" fill="url(#paint9_linear_173_2638)"/>
<path d="M289.452 189.646C290.18 188.328 290.62 194.771 291.21 195.942C291.799 197.114 289.599 198.431 287.403 198.578C285.645 198.695 284.039 199.017 284.181 197.553C284.324 196.089 281.981 190.923 283.01 191.11C284.039 191.297 284.972 191.345 286.378 191.11C287.783 190.876 288.724 190.964 289.452 189.646Z" fill="white"/>
<path d="M302.631 191.696C303.568 191.462 304.29 192.282 304.535 192.721C305.413 194.771 304.535 196.528 303.656 198.431C302.777 200.335 300.728 201.799 298.824 202.385C296.921 202.97 296.188 202.385 293.992 203.263C291.796 204.142 291.796 205.899 288.721 207.217C285.646 208.534 283.596 206.924 283.303 206.485C283.01 206.045 283.596 203.702 283.596 202.678C283.596 201.653 283.01 200.774 283.01 200.042C283.01 199.456 283.889 198.236 284.328 197.699C284.426 197.846 284.855 198.197 285.792 198.431C286.964 198.724 289.16 198.138 290.478 197.113C291.532 196.293 291.21 195.405 290.917 195.064C291.405 195.015 292.909 194.888 295.017 194.771C297.653 194.624 301.46 191.989 302.631 191.696Z" fill="url(#paint10_linear_173_2638)"/>
<path d="M270.416 113.067C274.282 114.121 278.86 116.923 280.666 118.192C280.032 121.608 278.616 128.763 278.03 130.052C277.298 131.662 272.466 145.28 270.709 148.794C268.952 152.308 266.317 151.722 263.974 151.283C261.631 150.844 261.045 149.673 254.749 148.208C249.712 147.037 245.427 147.037 243.914 147.183L245.964 132.248L248.16 133.127C250.269 133.478 250.789 132.141 254.749 132.248C260.167 132.395 263.568 137.812 262.656 134.445C260.753 127.416 261.045 120.827 261.045 117.167C261.045 114.238 260.655 112.237 260.46 111.603C262.168 111.651 266.551 112.013 270.416 113.067Z" fill="url(#paint11_linear_173_2638)"/>
<path d="M245.086 130.931C246.14 131.399 247.673 132.688 247.575 132.981C245.964 133.859 245.964 140.008 245.964 140.887V140.887C245.964 141.766 248.307 146.305 247.575 147.184C246.843 148.062 242.304 147.184 242.304 146.012C242.304 144.841 240.547 140.448 238.643 137.813C236.74 135.177 237.911 132.395 239.375 131.516C240.84 130.638 241.279 128.295 241.425 129.174C241.572 130.052 243.768 130.345 245.086 130.931Z" fill="url(#paint12_linear_173_2638)"/>
<path d="M244.795 150.112C245.029 149.175 243.135 146.988 242.159 146.012L235.277 152.454L224.881 173.832C224.881 174.467 224.145 175.853 225.902 175.736C228.098 175.589 229.851 174.418 231.32 172.807C233.172 170.775 234.691 165.633 235.863 161.826C237.034 158.019 238.645 156.701 239.67 155.237C240.695 153.772 244.502 151.283 244.795 150.112Z" fill="#E64715"/>
<path d="M238.351 124.634C237.297 124.166 234.788 122.78 233.666 122.145L229.419 131.077V154.065L227.658 174.272C228 173.93 230.294 172.222 231.616 170.172C232.514 168.78 233.958 165.486 234.251 164.022C234.544 162.558 237.619 155.53 238.058 154.065C238.498 152.601 241.865 148.648 242.305 145.866C242.744 143.084 237.765 137.227 237.619 135.177C237.473 133.127 238.351 132.395 240.108 131.077C241.865 129.759 241.28 129.027 241.28 127.416C241.28 125.806 239.669 125.22 238.351 124.634Z" fill="url(#paint13_linear_173_2638)"/>
<path d="M233.665 122.145L228.54 120.827L229.273 152.015C229.273 158.751 229.507 171.783 230.444 170.025C231.908 168.268 233.519 161.094 233.519 158.897C233.519 156.701 233.372 149.087 233.519 146.159C233.665 143.23 234.397 141.619 236.301 133.127C237.824 126.333 235.178 122.975 233.665 122.145Z" fill="url(#paint14_linear_173_2638)"/>
<path d="M221.366 129.466C221.132 123.609 225.466 120.534 228.394 120.827C236.74 122.291 233.812 135.47 232.787 139.13C231.967 142.059 231.371 146.207 231.176 147.915C230.59 155.969 233.379 157.14 231.762 165.047C230.145 172.954 226.637 176.761 224.294 175.589C221.952 174.418 217.852 164.608 217.705 159.629C217.559 154.651 221.366 148.355 222.098 142.937C222.83 137.519 221.659 136.787 221.366 129.466Z" fill="#1E1C51"/>
<path d="M288.715 207.217C285.64 208.535 283.59 206.924 283.298 206.485V205.314C283.882 205.704 285.285 206.456 286.222 206.338C287.393 206.192 289.297 205.606 290.468 204.435C291.64 203.264 292.665 202.239 295.007 202.092C297.35 201.946 302.19 201.214 303.65 198.432C302.772 200.335 300.722 201.799 298.818 202.385C296.915 202.971 296.183 202.385 293.986 203.264C291.79 204.142 291.79 205.899 288.715 207.217Z" fill="url(#paint15_linear_173_2638)"/>
<path d="M290.621 195.064C290.738 194.361 290.67 193.892 290.621 193.746C290.816 193.892 291.584 194.505 293.256 194.624C295.306 194.771 296.556 194.3 297.942 193.746C299.406 193.16 301.163 192.135 302.627 191.696C304.092 191.257 304.824 193.16 304.238 192.428C303.652 191.696 302.481 192.721 301.31 193.307C300.384 193.77 299.699 194.478 297.942 195.21C296.185 195.942 294.428 196.528 291.646 196.674C289.979 196.762 290.474 195.942 290.621 195.064Z" fill="#E64715"/>
<path d="M264.708 112.042C270.213 112.393 277.739 116.288 280.814 118.192C281.156 115.849 281.986 109.992 282.571 105.307C283.303 99.4496 282.718 97.1068 282.718 94.4712C282.718 92.3627 282.571 91.0059 282.571 89.9321L278.764 86.7108L265.147 85.9787L257.679 89.9321V93.8855C257.679 96.6676 259.436 99.1568 259.144 103.11C258.909 106.273 257.387 109.797 256.654 111.163C257.045 111.31 259.202 111.691 264.708 112.042Z" fill="url(#paint16_linear_173_2638)"/>
<path d="M282.714 74.8506C282.714 72.625 283.69 69.3353 284.178 67.9687C272.854 67.7247 246.898 67.383 246.547 67.383C246.108 67.383 248.011 72.215 248.011 74.8506C248.011 77.4862 247.279 79.829 247.279 82.1717C247.279 84.5145 246.547 87.0037 246.547 90.6643V95.9355C246.791 95.4962 249.358 94.5591 251.233 93.1534C253.575 91.3964 260.604 89.6393 268.657 89.0536C275.1 88.5851 281.542 91.5916 283.592 93.1534C283.592 91.9333 283.416 89.0536 282.714 87.2965C281.835 85.1002 282.714 82.9038 282.714 79.829V74.8506Z" fill="url(#paint17_linear_173_2638)"/>
<path d="M234.1 81.4397C233.046 80.0341 233.173 74.9971 233.368 72.6544H224.144C224.973 75.0947 226.428 80.5612 225.608 82.904C224.583 85.8324 225.754 86.711 225.608 89.493C225.462 92.275 227.219 96.082 228.39 96.3749C229.561 96.6677 230.44 97.8391 231.465 97.8391C232.49 97.8391 234.979 98.5712 236.15 98.7176C237.322 98.864 237.907 97.9855 238.932 97.8391C239.957 97.6927 239.518 95.7892 240.689 94.6178C241.861 93.4464 240.836 91.9822 240.689 91.2501C240.543 90.5179 238.493 87.2966 237.907 85.3931C237.322 83.4896 235.418 83.1968 234.1 81.4397Z" fill="url(#paint18_linear_173_2638)"/>
<path d="M235.717 85.7675C236.771 86.0018 238.889 89.7697 239.816 91.6244C238.303 91.3316 235.014 90.5116 233.959 89.5745C232.642 88.4031 230.445 88.2567 228.249 88.1103C226.053 87.9639 230.592 87.3782 232.202 87.2317C233.813 87.0853 232.935 84.889 232.935 84.4497C232.935 84.0104 234.399 85.4747 235.717 85.7675Z" fill="url(#paint19_linear_173_2638)"/>
<path d="M311.104 78.3612C312.627 77.7755 315.643 73.9197 316.961 72.065C313.74 71.6257 306.887 71.0107 305.247 72.065C303.197 73.3828 304.515 75.2863 302.026 78.3612C299.537 81.436 298.512 80.5575 296.901 80.5575C295.29 80.5575 292.801 82.0217 292.801 82.7539C292.801 83.486 292.508 86.8537 292.801 87.8787C293.094 88.9036 294.558 90.5143 295.876 91.3928C297.194 92.2713 298.951 91.5392 300.122 91.3928C301.294 91.2464 302.611 88.3179 303.051 87.8787C303.49 87.4394 305.394 84.5109 305.833 82.7539C306.272 80.9968 309.201 79.0933 311.104 78.3612Z" fill="url(#paint20_linear_173_2638)"/>
</g>
<path d="M352.213 53.3222C352.213 49.5603 355.263 46.5107 359.024 46.5107H405.343C409.105 46.5107 412.155 49.5603 412.155 53.3222V53.3222C412.155 57.0842 409.105 60.1338 405.343 60.1338H359.024C355.263 60.1338 352.213 57.0842 352.213 53.3222V53.3222Z" fill="#FF4F17"/>
<path className={fillClassName} d="M50.7227 49.9894C50.7227 48.0681 52.2801 46.5107 54.2014 46.5107H121.457C123.378 46.5107 124.935 48.0681 124.935 49.9894V49.9894C124.935 51.9106 123.378 53.4681 121.457 53.4681H54.2014C52.2801 53.4681 50.7227 51.9106 50.7227 49.9894V49.9894Z" fill="#D4DCE1"/>
<path className={fillClassName} d="M129.574 49.9894C129.574 48.0681 131.132 46.5107 133.053 46.5107H160.883C162.804 46.5107 164.361 48.0681 164.361 49.9894V49.9894C164.361 51.9106 162.804 53.4681 160.883 53.4681H133.053C131.132 53.4681 129.574 51.9106 129.574 49.9894V49.9894Z" fill="#D4DCE1"/>
<path className={boxBgClassName} d="M51 250.596C51 244.192 56.1916 239 62.5957 239H118.062C124.466 239 129.658 244.192 129.658 250.596V306.051C129.658 312.456 124.466 317.647 118.062 317.647H62.5957C56.1916 317.647 51 312.456 51 306.051V250.596Z" fill="#F4F7FA"/>
<path fillRule="evenodd" clipRule="evenodd" d="M89 289L89 267L91 267L91 289L89 289Z" fill="#4FC865"/>
<path fillRule="evenodd" clipRule="evenodd" d="M101 279H79V277H101V279Z" fill="#4FC865"/>
<path className={boxBgClassName} d="M146 250.596C146 244.192 151.192 239 157.596 239H213.062C219.466 239 224.658 244.192 224.658 250.596V306.051C224.658 312.456 219.466 317.647 213.062 317.647H157.596C151.192 317.647 146 312.456 146 306.051V250.596Z" fill="#F4F7FA"/>
<path className={boxBgClassName} d="M239 250.596C239 244.192 244.192 239 250.596 239H306.062C312.466 239 317.658 244.192 317.658 250.596V306.051C317.658 312.456 312.466 317.647 306.062 317.647H250.596C244.192 317.647 239 312.456 239 306.051V250.596Z" fill="#F4F7FA"/>
<path className={boxBgClassName} d="M334 250.596C334 244.192 339.192 239 345.596 239H401.062C407.466 239 412.658 244.192 412.658 250.596V306.051C412.658 312.456 407.466 317.647 401.062 317.647H345.596C339.192 317.647 334 312.456 334 306.051V250.596Z" fill="#F4F7FA"/>
<path fillRule="evenodd" clipRule="evenodd" d="M184 289L184 267L186 267L186 289L184 289Z" fill="#4FC865"/>
<path fillRule="evenodd" clipRule="evenodd" d="M196 279H174V277H196V279Z" fill="#4FC865"/>
<path fillRule="evenodd" clipRule="evenodd" d="M277 289L277 267L279 267L279 289L277 289Z" fill="#4FC865"/>
<path fillRule="evenodd" clipRule="evenodd" d="M289 279H267V277H289V279Z" fill="#4FC865"/>
<path fillRule="evenodd" clipRule="evenodd" d="M372 289L372 267L374 267L374 289L372 289Z" fill="#4FC865"/>
<path fillRule="evenodd" clipRule="evenodd" d="M384 279H362V277H384V279Z" fill="#4FC865"/>
<defs>
<filter id="filter0_d_173_2638" x="0.212891" y="0" width="460.49" height="377" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="12.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.823529 0 0 0 0 0.87451 0 0 0 0 0.909804 0 0 0 0.44 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_173_2638"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_173_2638" result="shape"/>
</filter>
<radialGradient id="paint0_radial_173_2638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(312.866 167.278) rotate(-89.2946) scale(143.68 498.752)">
<stop offset="0.10248" stopColor="#FCF1D4"/>
<stop offset="0.525154" stopColor="#E880E4"/>
<stop offset="1" stopColor="#4200FF"/>
</radialGradient>
<linearGradient id="paint1_linear_173_2638" x1="190.814" y1="120.698" x2="188.791" y2="163.143" gradientUnits="userSpaceOnUse">
<stop stopColor="#4200FF"/>
<stop offset="1" stopColor="#EC95E1"/>
</linearGradient>
<linearGradient id="paint2_linear_173_2638" x1="384.603" y1="129.739" x2="373.43" y2="182.103" gradientUnits="userSpaceOnUse">
<stop stopColor="#4200FF"/>
<stop offset="1" stopColor="#EC95E1"/>
</linearGradient>
<radialGradient id="paint3_radial_173_2638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(241.522 162.954) rotate(92.2198) scale(50.7448 439.874)">
<stop stopColor="#20FFBC"/>
<stop offset="1" stopColor="#4200FF"/>
</radialGradient>
<linearGradient id="paint4_linear_173_2638" x1="271.298" y1="165.706" x2="271.298" y2="240.587" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFACCB"/>
<stop offset="1" stopColor="#4200FF"/>
</linearGradient>
<linearGradient id="paint5_linear_173_2638" x1="251.086" y1="96.9606" x2="246.517" y2="96.4702" gradientUnits="userSpaceOnUse">
<stop stopColor="#CF3200"/>
<stop offset="1" stopColor="#FFB7A0"/>
</linearGradient>
<linearGradient id="paint6_linear_173_2638" x1="279.346" y1="94.3246" x2="284.434" y2="93.8155" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFB7A0"/>
<stop offset="1" stopColor="#CF3200"/>
</linearGradient>
<linearGradient id="paint7_linear_173_2638" x1="281.103" y1="122.584" x2="258.994" y2="122.145" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="1" stopColor="#CE7DB0"/>
</linearGradient>
<linearGradient id="paint8_linear_173_2638" x1="249.382" y1="99.2898" x2="277.533" y2="98.1711" gradientUnits="userSpaceOnUse">
<stop stopColor="#CF3200"/>
<stop offset="1" stopColor="#FF7F57"/>
</linearGradient>
<linearGradient id="paint9_linear_173_2638" x1="285.349" y1="172.368" x2="274.983" y2="174.639" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="1" stopColor="#D987BA"/>
</linearGradient>
<linearGradient id="paint10_linear_173_2638" x1="298.527" y1="205.167" x2="298.966" y2="192.721" gradientUnits="userSpaceOnUse">
<stop stopColor="#4200FF"/>
<stop offset="1" stopColor="#20FFBC"/>
</linearGradient>
<linearGradient id="paint11_linear_173_2638" x1="278.026" y1="137.08" x2="262.945" y2="127.27" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="1" stopColor="#D987BA"/>
</linearGradient>
<linearGradient id="paint12_linear_173_2638" x1="242.411" y1="132.835" x2="250.267" y2="147.471" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFD7F3"/>
<stop offset="0.359375" stopColor="#FCF1D4"/>
<stop offset="1" stopColor="white"/>
</linearGradient>
<linearGradient id="paint13_linear_173_2638" x1="245.961" y1="140.887" x2="218.287" y2="152.455" gradientUnits="userSpaceOnUse">
<stop stopColor="#20FFBC"/>
<stop offset="1" stopColor="#4200FF"/>
</linearGradient>
<linearGradient id="paint14_linear_173_2638" x1="232.649" y1="120.827" x2="232.649" y2="170.187" gradientUnits="userSpaceOnUse">
<stop stopColor="#1E1C51"/>
<stop offset="1" stopColor="#1E1C51" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint15_linear_173_2638" x1="293.474" y1="198.432" x2="293.474" y2="207.725" gradientUnits="userSpaceOnUse">
<stop stopColor="#1E1C51"/>
<stop offset="1" stopColor="#1E1C51" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint16_linear_173_2638" x1="259.725" y1="99.1568" x2="282.274" y2="102.817" gradientUnits="userSpaceOnUse">
<stop stopColor="#CF3200"/>
<stop offset="1" stopColor="#FF7F57"/>
</linearGradient>
<linearGradient id="paint17_linear_173_2638" x1="260.75" y1="86.2716" x2="282.686" y2="85.241" gradientUnits="userSpaceOnUse">
<stop stopColor="#CF3200"/>
<stop offset="0.478853" stopColor="#E36842"/>
<stop offset="1" stopColor="#FFB7A0"/>
</linearGradient>
<linearGradient id="paint18_linear_173_2638" x1="240.016" y1="89.2598" x2="228.604" y2="96.0043" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="0.565975" stopColor="#D693B6"/>
<stop offset="1" stopColor="#C875A9"/>
</linearGradient>
<linearGradient id="paint19_linear_173_2638" x1="233.81" y1="97.9855" x2="235.776" y2="85.5183" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="1" stopColor="#CE7DB0"/>
</linearGradient>
<linearGradient id="paint20_linear_173_2638" x1="306.874" y1="83.3431" x2="295.307" y2="76.4613" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="0.565975" stopColor="#D693B6"/>
<stop offset="1" stopColor="#C875A9"/>
</linearGradient>
</defs>
</svg>
  )
}

export const TemplateFive = ({ mainClassName, bgClassName, fillClassName, boxBgClassName }) => {
  return (
    <svg className={mainClassName} width="462" height="377" viewBox="0 0 462 377" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_173_2778)">
<path className={bgClassName} d="M25.7227 32.5958C25.7227 26.1916 30.9142 21 37.3184 21H424.616C431.02 21 436.212 26.1916 436.212 32.5957V336.404C436.212 342.808 431.02 348 424.616 348H37.3184C30.9143 348 25.7227 342.808 25.7227 336.404V32.5958Z" fill="white"/>
</g>
<path className={fillClassName} d="M97.6172 198.415C97.6172 196.494 99.1747 194.936 101.096 194.936H302.862C304.783 194.936 306.341 196.494 306.341 198.415V198.415C306.341 200.336 304.783 201.894 302.862 201.894H101.096C99.1747 201.894 97.6172 200.336 97.6172 198.415V198.415Z" fill="#D4DCE1"/>
<path className={fillClassName} d="M97.6172 212.33C97.6172 210.409 99.1747 208.851 101.096 208.851H233.287C235.209 208.851 236.766 210.409 236.766 212.33V212.33C236.766 214.251 235.209 215.809 233.287 215.809H101.096C99.1747 215.809 97.6172 214.251 97.6172 212.33V212.33Z" fill="#D4DCE1"/>
<mask id="mask0_173_2778" maskUnits="userSpaceOnUse" x="51" y="77" width="117" height="105">
<path d="M51.2324 77.9666H167.443V181.021H51.2324V77.9666Z" fill="#C4C4C4"/>
</mask>
<g mask="url(#mask0_173_2778)">
<path d="M174.49 74.4953H-28.7898V183.103H174.49V74.4953Z" fill="url(#paint0_radial_173_2778)"/>
<path d="M73.7353 135.873C76.1242 135.873 80.1132 137.864 81.809 138.859L85.127 139.633L-29.8955 142.513C-19.9785 141.555 18.2148 137.758 23.5236 136.758C25.48 136.389 36.4636 132.555 41.7723 131.781C47.0811 131.007 47.5235 128.357 51.505 128.357C55.4865 128.357 63.339 126.255 64.6662 127.14C65.9934 128.025 70.7491 135.873 73.7353 135.873Z" fill="url(#paint1_linear_173_2778)"/>
<path d="M105.035 138.864V140.191H68.98V137.647H70.9708V138.974H71.8556V138.532H72.5192V138.974H74.2888V137.647H75.2842V134.771H77.3855V139.527H79.4869V138.974H81.1459V137.868H82.2519V138.974H82.6943V138.532H85.4592V135.988H86.0122V138.974H86.6758V137.647H87.2288V139.306H89.2196V138.089H91.7633V137.647H94.7495V137.094H98.9522V139.527H99.284V136.652L99.837 136.209L100.058 126.034L101.717 127.582V140.08H102.381V139.195H102.713V138.864V135.656H103.487V131.343H104.482V138.864H105.035Z" fill="#B968EA"/>
<path d="M168.407 130.79C171.77 130.613 173.864 128.431 174.49 127.362V146.937L120.408 141.186C122.804 140.707 128.061 139.638 129.919 139.196C132.242 138.643 133.348 138.09 134.011 138.09C134.675 138.09 135.338 138.643 137.661 137.426C139.983 136.209 147.836 135.878 150.38 135.878C152.924 135.878 158.896 133.444 159.67 132.781C160.444 132.117 164.205 131.011 168.407 130.79Z" fill="url(#paint2_linear_173_2778)"/>
<path d="M-28.8997 183.103V142.403C-28.8997 142.403 9.25678 139.509 73.5145 139.527C137.772 139.546 174.602 145.168 174.602 145.168V183.103L-28.8997 183.103Z" fill="url(#paint3_radial_173_2778)"/>
<path d="M109.68 142.182C105.809 141.739 110.454 140.633 117.09 141.076C111.449 141.061 104.282 141.607 125.163 141.961C146.044 142.314 167.08 145.5 174.49 150.145V183.214H-28.9005V166.513C35.5785 161.315 114.148 153.153 130.251 150.145C150.38 146.384 116.805 142.996 109.68 142.182Z" fill="url(#paint4_linear_173_2778)"/>
<path d="M106.944 92.6261L105.709 93.6555L106.944 93.9643V92.6261Z" fill="url(#paint5_linear_173_2778)"/>
<path d="M130.724 90.7729L131.754 91.6994L130.724 92.1112V90.7729Z" fill="url(#paint6_linear_173_2778)"/>
<path d="M114.256 116.509C115.492 118.26 117.036 120.936 117.139 121.451L116.418 107.142L109.933 106.421C109.933 107.107 110.097 108.892 110.756 110.539C111.58 112.598 113.021 114.759 114.256 116.509Z" fill="url(#paint7_linear_173_2778)"/>
<path d="M106.844 97.0528C106.432 95.0763 106.672 93.0037 106.844 92.2144C106.981 91.7683 107.255 90.7938 107.255 90.4644C107.255 90.135 112.059 88.68 114.461 87.9937L115.903 97.0528V104.98L116.314 107.553C115.594 107.587 113.453 107.636 112.3 107.553C111.147 107.471 109.898 107.107 109.417 106.935C109.005 105.563 108.161 102.591 108.079 101.685C107.976 100.553 107.358 99.5235 106.844 97.0528Z" fill="url(#paint8_linear_173_2778)"/>
<path d="M127.846 125.877C127.682 124.148 127.16 121.382 126.92 120.216C125.101 124.024 122.184 132.054 122.184 132.054C122.184 132.054 123.683 134.525 123.214 138.437C122.905 141.01 123.214 144.099 124.14 146.672C125.067 149.246 126.096 150.275 127.229 151.511C128.361 152.746 129.493 154.908 130.317 156.864C130.976 158.428 131.621 161.565 131.861 162.937C132.136 163.109 132.973 163.411 134.126 163.246C135.279 163.082 136.116 162.285 136.391 161.908C136.013 160.192 135.032 155.711 134.126 151.511C132.993 146.26 131.243 137.819 130.832 135.863C130.42 133.907 130.214 131.951 129.596 130.407C128.979 128.863 128.052 128.039 127.846 125.877Z" fill="url(#paint9_linear_173_2778)"/>
<path d="M135.874 159.54C136.386 158.614 136.695 163.143 137.109 163.967C137.524 164.79 135.977 165.717 134.433 165.82C133.198 165.902 132.068 166.128 132.168 165.099C132.268 164.07 130.621 160.438 131.345 160.569C132.068 160.701 132.724 160.734 133.712 160.569C134.701 160.405 135.362 160.467 135.874 159.54Z" fill="white"/>
<path d="M145.138 160.981C145.797 160.816 146.305 161.393 146.477 161.702C147.094 163.143 146.477 164.378 145.859 165.717C145.241 167.055 143.8 168.084 142.462 168.496C141.123 168.908 140.609 168.496 139.065 169.114C137.52 169.731 137.52 170.967 135.359 171.893C133.197 172.82 131.756 171.687 131.55 171.378C131.344 171.07 131.756 169.423 131.756 168.702C131.756 167.981 131.344 167.364 131.344 166.849C131.344 166.437 131.961 165.579 132.27 165.202C132.339 165.305 132.641 165.552 133.3 165.717C134.123 165.922 135.667 165.511 136.594 164.79C137.335 164.214 137.109 163.589 136.903 163.349C137.246 163.315 138.303 163.225 139.785 163.143C141.638 163.04 144.315 161.187 145.138 160.981Z" fill="url(#paint10_linear_173_2778)"/>
<path d="M122.491 105.7C125.209 106.441 128.428 108.411 129.697 109.303C129.251 111.705 128.256 116.736 127.844 117.642C127.33 118.774 123.932 128.348 122.697 130.818C121.462 133.289 119.609 132.877 117.962 132.568C116.314 132.26 115.903 131.436 111.476 130.407C107.935 129.583 104.922 129.583 103.858 129.686L105.299 119.186L106.844 119.803C108.326 120.05 108.692 119.11 111.476 119.186C115.285 119.289 117.676 123.098 117.035 120.73C115.697 115.789 115.903 111.156 115.903 108.582C115.903 106.524 115.628 105.117 115.491 104.671C116.692 104.705 119.773 104.959 122.491 105.7Z" fill="url(#paint11_linear_173_2778)"/>
<path d="M104.683 118.26C105.425 118.589 106.502 119.495 106.434 119.701C105.301 120.318 105.301 124.642 105.301 125.26V125.26C105.301 125.877 106.948 129.069 106.434 129.686C105.919 130.304 102.728 129.686 102.728 128.863C102.728 128.039 101.492 124.951 100.154 123.098C98.8157 121.245 99.6392 119.289 100.669 118.671C101.698 118.054 102.007 116.407 102.11 117.024C102.213 117.642 103.757 117.848 104.683 118.26Z" fill="url(#paint12_linear_173_2778)"/>
<path d="M104.476 131.745C104.641 131.086 103.31 129.549 102.623 128.862L97.7849 133.392L90.4758 148.422C90.4758 148.868 89.9582 149.842 91.1935 149.76C92.7377 149.657 93.9701 148.833 95.0025 147.701C96.3051 146.272 97.3731 142.657 98.1967 139.98C99.0202 137.304 100.153 136.377 100.873 135.348C101.594 134.318 104.27 132.568 104.476 131.745Z" fill="#E64715"/>
<path d="M99.9453 113.833C99.2041 113.503 97.4403 112.529 96.6511 112.083L93.6657 118.362V134.525L92.4275 148.731C92.6677 148.491 94.2805 147.29 95.2099 145.848C95.8411 144.87 96.857 142.554 97.0629 141.525C97.2688 140.495 99.4306 135.554 99.7395 134.525C100.048 133.495 102.416 130.716 102.725 128.76C103.034 126.804 99.5336 122.686 99.4306 121.245C99.3277 119.803 99.9453 119.289 101.181 118.362C102.416 117.436 102.004 116.921 102.004 115.789C102.004 114.656 100.872 114.244 99.9453 113.833Z" fill="url(#paint13_linear_173_2778)"/>
<path d="M96.6519 112.083L93.0488 111.156L93.5635 133.083C93.5635 137.819 93.7283 146.981 94.3871 145.745C95.4165 144.51 96.5489 139.466 96.5489 137.922C96.5489 136.377 96.446 131.024 96.5489 128.965C96.6519 126.907 97.1666 125.774 98.5049 119.803C99.5755 115.027 97.7156 112.666 96.6519 112.083Z" fill="url(#paint14_linear_173_2778)"/>
<path d="M88.0067 117.23C87.842 113.112 90.8891 110.95 92.948 111.156C98.8158 112.185 96.757 121.45 96.0364 124.024C95.4599 126.083 95.0412 129 94.9039 130.201C94.4922 135.863 96.4525 136.686 95.3157 142.245C94.1789 147.804 91.7127 150.481 90.0656 149.657C88.4185 148.834 85.536 141.936 85.4331 138.436C85.3301 134.936 88.0067 130.509 88.5214 126.701C89.0361 122.892 88.2126 122.377 88.0067 117.23Z" fill="#1E1C51"/>
<path d="M135.357 171.893C133.195 172.82 131.754 171.687 131.548 171.379V170.555C131.959 170.83 132.945 171.358 133.604 171.276C134.428 171.173 135.766 170.761 136.59 169.937C137.413 169.114 138.134 168.393 139.781 168.29C141.428 168.187 144.831 167.673 145.857 165.717C145.24 167.055 143.799 168.084 142.46 168.496C141.122 168.908 140.607 168.496 139.063 169.114C137.519 169.731 137.519 170.967 135.357 171.893Z" fill="url(#paint15_linear_173_2778)"/>
<path d="M136.695 163.349C136.778 162.855 136.73 162.525 136.695 162.422C136.833 162.525 137.372 162.956 138.548 163.04C139.99 163.143 140.868 162.812 141.843 162.422C142.872 162.011 144.107 161.29 145.137 160.981C146.166 160.672 146.681 162.011 146.269 161.496C145.857 160.981 145.034 161.702 144.21 162.113C143.559 162.439 143.078 162.937 141.843 163.452C140.607 163.966 139.372 164.378 137.416 164.481C136.244 164.543 136.592 163.966 136.695 163.349Z" fill="#E64715"/>
<path d="M118.477 104.98C122.348 105.227 127.639 107.965 129.801 109.303C130.041 107.656 130.624 103.538 131.036 100.244C131.551 96.1264 131.139 94.4793 131.139 92.6263C131.139 91.1439 131.036 90.1899 131.036 89.435L128.359 87.1702L118.786 86.6555L113.536 89.435V92.2145C113.536 94.1705 114.771 95.9205 114.565 98.7C114.4 100.924 113.33 103.401 112.815 104.362C113.089 104.465 114.606 104.733 118.477 104.98Z" fill="url(#paint16_linear_173_2778)"/>
<path d="M131.135 78.8316C131.135 77.2668 131.821 74.954 132.164 73.9932C124.203 73.8216 105.954 73.5814 105.707 73.5814C105.399 73.5814 106.737 76.9786 106.737 78.8316C106.737 80.6846 106.222 82.3317 106.222 83.9788C106.222 85.6259 105.707 87.376 105.707 89.9496V93.6556C105.879 93.3467 107.684 92.6879 109.002 91.6996C110.649 90.4643 115.59 89.229 121.252 88.8172C125.782 88.4878 130.311 90.6016 131.752 91.6996C131.752 90.8418 131.629 88.8172 131.135 87.5819C130.517 86.0377 131.135 84.4935 131.135 82.3317V78.8316Z" fill="url(#paint17_linear_173_2778)"/>
<path d="M96.9599 83.4638C96.2187 82.4756 96.3079 78.9343 96.4452 77.2872H89.9597C90.5431 79.0029 91.5657 82.8462 90.9892 84.4933C90.2686 86.5522 91.0921 87.1698 90.9892 89.1258C90.8862 91.0817 92.1216 93.7583 92.9451 93.9642C93.7687 94.1701 94.3864 94.9936 95.107 94.9936C95.8276 94.9936 97.5776 95.5083 98.4012 95.6113C99.2247 95.7142 99.6365 95.0966 100.357 94.9936C101.078 94.8907 100.769 93.5524 101.592 92.7288C102.416 91.9053 101.695 90.8758 101.592 90.3611C101.489 89.8464 100.048 87.5816 99.6365 86.2433C99.2247 84.9051 97.8864 84.6992 96.9599 83.4638Z" fill="url(#paint18_linear_173_2778)"/>
<path d="M98.0961 86.5067C98.8373 86.6714 100.327 89.3205 100.979 90.6245C99.9148 90.4186 97.6019 89.8421 96.8607 89.1832C95.9342 88.3597 94.3901 88.2567 92.8459 88.1538C91.3017 88.0508 94.493 87.6391 95.6254 87.5361C96.7578 87.4332 96.1401 85.889 96.1401 85.5802C96.1401 85.2713 97.1696 86.3008 98.0961 86.5067Z" fill="url(#paint19_linear_173_2778)"/>
<path d="M151.097 81.3001C152.168 80.8883 154.288 78.1775 155.215 76.8735C152.95 76.5647 148.132 76.1323 146.979 76.8735C145.538 77.8 146.465 79.1383 144.715 81.3001C142.964 83.4619 142.244 82.8443 141.111 82.8443C139.979 82.8443 138.229 83.8737 138.229 84.3884C138.229 84.9032 138.023 87.2709 138.229 87.9915C138.435 88.7121 139.464 89.8445 140.391 90.4621C141.317 91.0798 142.553 90.5651 143.376 90.4621C144.2 90.3592 145.126 88.3003 145.435 87.9915C145.744 87.6827 147.082 85.6238 147.391 84.3884C147.7 83.1531 149.759 81.8148 151.097 81.3001Z" fill="url(#paint20_linear_173_2778)"/>
</g>
<mask id="mask1_173_2778" maskUnits="userSpaceOnUse" x="174" y="77" width="117" height="105">
<path d="M174.021 77.9666H290.232V181.021H174.021V77.9666Z" fill="#C4C4C4"/>
</mask>
<g mask="url(#mask1_173_2778)">
<path d="M297.279 74.4953H93.9992V183.103H297.279V74.4953Z" fill="url(#paint21_radial_173_2778)"/>
<path d="M196.524 135.873C198.913 135.873 202.902 137.864 204.598 138.859L207.916 139.633L92.8935 142.513C102.811 141.555 141.004 137.758 146.313 136.758C148.269 136.389 159.253 132.555 164.561 131.781C169.87 131.007 170.313 128.357 174.294 128.357C178.276 128.357 186.128 126.255 187.455 127.14C188.782 128.025 193.538 135.873 196.524 135.873Z" fill="url(#paint22_linear_173_2778)"/>
<path d="M227.824 138.864V140.191H191.769V137.647H193.76V138.974H194.645V138.532H195.308V138.974H197.078V137.647H198.073V134.771H200.175V139.527H202.276V138.974H203.935V137.868H205.041V138.974H205.483V138.532H208.248V135.988H208.801V138.974H209.465V137.647H210.018V139.306H212.009V138.089H214.552V137.647H217.539V137.094H221.741V139.527H222.073V136.652L222.626 136.209L222.847 126.034L224.506 127.582V140.08H225.17V139.195H225.502V138.864V135.656H226.276V131.343H227.271V138.864H227.824Z" fill="#B968EA"/>
<path d="M291.196 130.79C294.559 130.613 296.653 128.43 297.279 127.361V146.937L243.197 141.186C245.593 140.707 250.85 139.638 252.708 139.195C255.031 138.642 256.137 138.089 256.8 138.089C257.464 138.089 258.127 138.642 260.45 137.426C262.773 136.209 270.625 135.877 273.169 135.877C275.713 135.877 281.685 133.444 282.459 132.781C283.233 132.117 286.994 131.011 291.196 130.79Z" fill="url(#paint23_linear_173_2778)"/>
<path d="M93.8894 183.103V142.403C93.8894 142.403 132.046 139.509 196.304 139.527C260.561 139.546 297.391 145.168 297.391 145.168V183.103L93.8894 183.103Z" fill="url(#paint24_radial_173_2778)"/>
<path d="M232.469 142.181C228.598 141.739 233.243 140.633 239.879 141.075C234.238 141.061 227.071 141.606 247.952 141.96C268.833 142.314 289.869 145.499 297.279 150.145V183.214H93.8886V166.513C158.368 161.315 236.937 153.153 253.04 150.145C273.169 146.384 239.594 142.996 232.469 142.181Z" fill="url(#paint25_linear_173_2778)"/>
<path d="M229.733 92.6261L228.498 93.6555L229.733 93.9643V92.6261Z" fill="url(#paint26_linear_173_2778)"/>
<path d="M253.514 90.7729L254.543 91.6994L253.514 92.1112V90.7729Z" fill="url(#paint27_linear_173_2778)"/>
<path d="M237.045 116.509C238.281 118.26 239.825 120.936 239.928 121.451L239.207 107.142L232.722 106.421C232.722 107.107 232.886 108.892 233.545 110.539C234.369 112.598 235.81 114.759 237.045 116.509Z" fill="url(#paint28_linear_173_2778)"/>
<path d="M229.633 97.0528C229.221 95.0763 229.461 93.0037 229.633 92.2144C229.77 91.7683 230.044 90.7938 230.044 90.4644C230.044 90.135 234.848 88.68 237.251 87.9937L238.692 97.0528V104.98L239.104 107.553C238.383 107.587 236.242 107.636 235.089 107.553C233.936 107.471 232.687 107.107 232.206 106.935C231.794 105.563 230.95 102.591 230.868 101.685C230.765 100.553 230.147 99.5235 229.633 97.0528Z" fill="url(#paint29_linear_173_2778)"/>
<path d="M250.635 125.877C250.471 124.148 249.949 121.382 249.709 120.216C247.89 124.024 244.973 132.054 244.973 132.054C244.973 132.054 246.472 134.525 246.003 138.437C245.694 141.01 246.003 144.099 246.929 146.672C247.856 149.246 248.885 150.275 250.018 151.511C251.15 152.746 252.282 154.908 253.106 156.864C253.765 158.428 254.41 161.565 254.65 162.937C254.925 163.109 255.762 163.411 256.915 163.246C258.068 163.082 258.905 162.285 259.18 161.908C258.802 160.192 257.821 155.711 256.915 151.511C255.783 146.26 254.032 137.819 253.621 135.863C253.209 133.907 253.003 131.951 252.385 130.407C251.768 128.863 250.841 128.039 250.635 125.877Z" fill="url(#paint30_linear_173_2778)"/>
<path d="M258.663 159.54C259.175 158.614 259.484 163.143 259.899 163.967C260.313 164.79 258.766 165.717 257.222 165.82C255.987 165.902 254.857 166.128 254.957 165.099C255.057 164.07 253.41 160.438 254.134 160.569C254.857 160.701 255.513 160.734 256.501 160.569C257.49 160.405 258.151 160.467 258.663 159.54Z" fill="white"/>
<path d="M267.927 160.981C268.586 160.816 269.094 161.393 269.266 161.702C269.883 163.143 269.266 164.378 268.648 165.717C268.03 167.055 266.589 168.084 265.251 168.496C263.913 168.908 263.398 168.496 261.854 169.114C260.309 169.731 260.309 170.967 258.148 171.893C255.986 172.82 254.545 171.687 254.339 171.378C254.133 171.07 254.545 169.423 254.545 168.702C254.545 167.981 254.133 167.364 254.133 166.849C254.133 166.437 254.75 165.579 255.059 165.202C255.128 165.305 255.43 165.552 256.089 165.717C256.912 165.922 258.456 165.511 259.383 164.79C260.124 164.214 259.898 163.589 259.692 163.349C260.035 163.315 261.092 163.225 262.574 163.143C264.427 163.04 267.104 161.187 267.927 160.981Z" fill="url(#paint31_linear_173_2778)"/>
<path d="M245.28 105.7C247.998 106.441 251.217 108.411 252.486 109.303C252.04 111.705 251.045 116.736 250.633 117.642C250.119 118.774 246.721 128.348 245.486 130.818C244.251 133.289 242.398 132.877 240.751 132.568C239.104 132.26 238.692 131.436 234.265 130.407C230.724 129.583 227.711 129.583 226.647 129.686L228.089 119.186L229.633 119.803C231.115 120.05 231.481 119.11 234.265 119.186C238.074 119.289 240.465 123.098 239.824 120.73C238.486 115.789 238.692 111.156 238.692 108.582C238.692 106.524 238.417 105.117 238.28 104.671C239.481 104.705 242.562 104.959 245.28 105.7Z" fill="url(#paint32_linear_173_2778)"/>
<path d="M227.472 118.26C228.214 118.589 229.291 119.495 229.223 119.701C228.09 120.318 228.09 124.642 228.09 125.26V125.26C228.09 125.877 229.737 129.069 229.223 129.686C228.708 130.304 225.517 129.686 225.517 128.863C225.517 128.039 224.281 124.951 222.943 123.098C221.605 121.245 222.428 119.289 223.458 118.671C224.487 118.054 224.796 116.407 224.899 117.024C225.002 117.642 226.546 117.848 227.472 118.26Z" fill="url(#paint33_linear_173_2778)"/>
<path d="M227.265 131.745C227.43 131.086 226.099 129.549 225.412 128.862L220.574 133.392L213.265 148.422C213.265 148.868 212.747 149.842 213.983 149.76C215.527 149.657 216.759 148.833 217.792 147.701C219.094 146.272 220.162 142.657 220.986 139.98C221.809 137.304 222.942 136.377 223.662 135.348C224.383 134.318 227.059 132.568 227.265 131.745Z" fill="#E64715"/>
<path d="M222.734 113.833C221.993 113.503 220.229 112.529 219.44 112.083L216.455 118.362V134.525L215.217 148.731C215.457 148.491 217.07 147.29 217.999 145.848C218.63 144.87 219.646 142.554 219.852 141.525C220.058 140.495 222.22 135.554 222.529 134.525C222.837 133.495 225.205 130.716 225.514 128.76C225.823 126.804 222.323 122.686 222.22 121.245C222.117 119.803 222.734 119.289 223.97 118.362C225.205 117.436 224.793 116.921 224.793 115.789C224.793 114.656 223.661 114.244 222.734 113.833Z" fill="url(#paint34_linear_173_2778)"/>
<path d="M219.441 112.083L215.838 111.156L216.353 133.083C216.353 137.819 216.517 146.981 217.176 145.745C218.206 144.51 219.338 139.466 219.338 137.922C219.338 136.377 219.235 131.024 219.338 128.965C219.441 126.907 219.956 125.774 221.294 119.803C222.365 115.027 220.505 112.666 219.441 112.083Z" fill="url(#paint35_linear_173_2778)"/>
<path d="M210.796 117.23C210.631 113.112 213.678 110.95 215.737 111.156C221.605 112.185 219.546 121.45 218.825 124.024C218.249 126.083 217.83 129 217.693 130.201C217.281 135.863 219.242 136.686 218.105 142.245C216.968 147.804 214.502 150.481 212.855 149.657C211.208 148.834 208.325 141.936 208.222 138.436C208.119 134.936 210.796 130.509 211.31 126.701C211.825 122.892 211.002 122.377 210.796 117.23Z" fill="#1E1C51"/>
<path d="M258.146 171.893C255.984 172.82 254.543 171.687 254.337 171.379V170.555C254.748 170.83 255.734 171.358 256.393 171.276C257.217 171.173 258.555 170.761 259.379 169.937C260.202 169.114 260.923 168.393 262.57 168.29C264.217 168.187 267.62 167.673 268.646 165.717C268.029 167.055 266.588 168.084 265.249 168.496C263.911 168.908 263.396 168.496 261.852 169.114C260.308 169.731 260.308 170.967 258.146 171.893Z" fill="url(#paint36_linear_173_2778)"/>
<path d="M259.484 163.349C259.567 162.855 259.519 162.525 259.484 162.422C259.622 162.525 260.161 162.956 261.337 163.04C262.779 163.143 263.657 162.812 264.632 162.422C265.661 162.011 266.896 161.29 267.926 160.981C268.955 160.672 269.47 162.011 269.058 161.496C268.646 160.981 267.823 161.702 266.999 162.113C266.348 162.439 265.867 162.937 264.632 163.452C263.396 163.966 262.161 164.378 260.205 164.481C259.033 164.543 259.381 163.966 259.484 163.349Z" fill="#E64715"/>
<path d="M241.266 104.98C245.137 105.227 250.428 107.965 252.59 109.303C252.83 107.656 253.413 103.538 253.825 100.244C254.34 96.1264 253.928 94.4793 253.928 92.6263C253.928 91.1439 253.825 90.1899 253.825 89.435L251.149 87.1702L241.575 86.6555L236.325 89.435V92.2145C236.325 94.1705 237.56 95.9205 237.354 98.7C237.189 100.924 236.119 103.401 235.604 104.362C235.878 104.465 237.395 104.733 241.266 104.98Z" fill="url(#paint37_linear_173_2778)"/>
<path d="M253.924 78.8316C253.924 77.2668 254.61 74.954 254.953 73.9932C246.992 73.8216 228.743 73.5814 228.496 73.5814C228.188 73.5814 229.526 76.9786 229.526 78.8316C229.526 80.6846 229.011 82.3317 229.011 83.9788C229.011 85.6259 228.496 87.376 228.496 89.9496V93.6556C228.668 93.3467 230.473 92.6879 231.791 91.6996C233.438 90.4643 238.379 89.229 244.041 88.8172C248.571 88.4878 253.1 90.6016 254.541 91.6996C254.541 90.8418 254.418 88.8172 253.924 87.5819C253.306 86.0377 253.924 84.4935 253.924 82.3317V78.8316Z" fill="url(#paint38_linear_173_2778)"/>
<path d="M219.749 83.4641C219.008 82.4759 219.097 78.9346 219.234 77.2875H212.749C213.332 79.0032 214.355 82.8465 213.778 84.4936C213.058 86.5525 213.881 87.1701 213.778 89.1261C213.675 91.082 214.911 93.7586 215.734 93.9645C216.558 94.1704 217.175 94.9939 217.896 94.9939C218.617 94.9939 220.367 95.5086 221.19 95.6116C222.014 95.7145 222.426 95.0969 223.146 94.9939C223.867 94.891 223.558 93.5527 224.381 92.7291C225.205 91.9056 224.484 90.8761 224.381 90.3614C224.279 89.8467 222.837 87.5819 222.426 86.2436C222.014 84.9054 220.675 84.6995 219.749 83.4641Z" fill="url(#paint39_linear_173_2778)"/>
<path d="M220.883 86.5069C221.624 86.6717 223.114 89.3208 223.766 90.6247C222.702 90.4188 220.389 89.8424 219.648 89.1835C218.721 88.36 217.177 88.257 215.633 88.1541C214.089 88.0511 217.28 87.6393 218.413 87.5364C219.545 87.4335 218.927 85.8893 218.927 85.5805C218.927 85.2716 219.957 86.3011 220.883 86.5069Z" fill="url(#paint40_linear_173_2778)"/>
<path d="M273.884 81.3001C274.955 80.8883 277.075 78.1775 278.002 76.8735C275.737 76.5647 270.919 76.1323 269.766 76.8735C268.325 77.8 269.252 79.1383 267.502 81.3001C265.752 83.4619 265.031 82.8443 263.899 82.8443C262.766 82.8443 261.016 83.8737 261.016 84.3884C261.016 84.9032 260.81 87.2709 261.016 87.9915C261.222 88.7121 262.251 89.8445 263.178 90.4621C264.104 91.0798 265.34 90.5651 266.163 90.4621C266.987 90.3592 267.913 88.3003 268.222 87.9915C268.531 87.6827 269.869 85.6238 270.178 84.3884C270.487 83.1531 272.546 81.8148 273.884 81.3001Z" fill="url(#paint41_linear_173_2778)"/>
</g>
<mask id="mask2_173_2778" maskUnits="userSpaceOnUse" x="296" y="77" width="118" height="105">
<path d="M296.811 77.9666H413.021V181.021H296.811V77.9666Z" fill="#C4C4C4"/>
</mask>
<g mask="url(#mask2_173_2778)">
<path d="M420.068 74.4953H216.788V183.103H420.068V74.4953Z" fill="url(#paint42_radial_173_2778)"/>
<path d="M319.313 135.873C321.702 135.873 325.691 137.864 327.387 138.859L330.705 139.633L215.683 142.513C225.6 141.555 263.793 137.758 269.102 136.758C271.058 136.389 282.042 132.555 287.35 131.781C292.659 131.007 293.102 128.357 297.083 128.357C301.065 128.357 308.917 126.255 310.244 127.14C311.572 128.025 316.327 135.873 319.313 135.873Z" fill="url(#paint43_linear_173_2778)"/>
<path d="M350.613 138.863V140.191H314.558V137.647H316.549V138.974H317.434V138.532H318.097V138.974H319.867V137.647H320.862V134.771H322.964V139.527H325.065V138.974H326.724V137.868H327.83V138.974H328.272V138.532H331.037V135.988H331.59V138.974H332.254V137.647H332.807V139.306H334.798V138.089H337.341V137.647H340.328V137.094H344.53V139.527H344.862V136.652L345.415 136.209L345.636 126.034L347.295 127.582V140.08H347.959V139.195H348.291V138.863V135.656H349.065V131.343H350.06V138.863H350.613Z" fill="#B968EA"/>
<path d="M413.985 130.79C417.348 130.613 419.442 128.431 420.068 127.362V146.937L365.986 141.186C368.382 140.707 373.639 139.638 375.497 139.196C377.82 138.643 378.926 138.09 379.589 138.09C380.253 138.09 380.916 138.643 383.239 137.426C385.562 136.209 393.414 135.878 395.958 135.878C398.502 135.878 404.474 133.444 405.248 132.781C406.022 132.117 409.783 131.011 413.985 130.79Z" fill="url(#paint44_linear_173_2778)"/>
<path d="M216.678 183.103V142.403C216.678 142.403 254.835 139.509 319.093 139.527C383.35 139.546 420.18 145.168 420.18 145.168V183.103L216.678 183.103Z" fill="url(#paint45_radial_173_2778)"/>
<path d="M355.258 142.182C351.387 141.739 356.032 140.633 362.668 141.076C357.027 141.061 349.86 141.607 370.741 141.96C391.622 142.314 412.658 145.5 420.068 150.145V183.214H216.678V166.513C281.157 161.315 359.726 153.153 375.829 150.145C395.958 146.384 362.383 142.996 355.258 142.182Z" fill="url(#paint46_linear_173_2778)"/>
<path d="M352.522 92.6261L351.287 93.6555L352.522 93.9643V92.6261Z" fill="url(#paint47_linear_173_2778)"/>
<path d="M376.303 90.7729L377.332 91.6994L376.303 92.1112V90.7729Z" fill="url(#paint48_linear_173_2778)"/>
<path d="M359.834 116.509C361.07 118.26 362.614 120.936 362.717 121.451L361.996 107.142L355.511 106.421C355.511 107.107 355.675 108.892 356.334 110.539C357.158 112.598 358.599 114.759 359.834 116.509Z" fill="url(#paint49_linear_173_2778)"/>
<path d="M352.422 97.0528C352.01 95.0763 352.25 93.0037 352.422 92.2144C352.559 91.7683 352.833 90.7938 352.833 90.4644C352.833 90.135 357.638 88.68 360.04 87.9937L361.481 97.0528V104.98L361.893 107.553C361.172 107.587 359.031 107.636 357.878 107.553C356.725 107.471 355.476 107.107 354.995 106.935C354.584 105.563 353.739 102.591 353.657 101.685C353.554 100.553 352.936 99.5235 352.422 97.0528Z" fill="url(#paint50_linear_173_2778)"/>
<path d="M373.424 125.877C373.26 124.148 372.738 121.382 372.498 120.216C370.679 124.024 367.762 132.054 367.762 132.054C367.762 132.054 369.261 134.525 368.792 138.437C368.483 141.01 368.792 144.099 369.718 146.672C370.645 149.246 371.674 150.275 372.807 151.511C373.939 152.746 375.071 154.908 375.895 156.864C376.554 158.428 377.199 161.565 377.439 162.937C377.714 163.109 378.551 163.411 379.704 163.246C380.857 163.082 381.694 162.285 381.969 161.908C381.591 160.192 380.61 155.711 379.704 151.511C378.572 146.26 376.822 137.819 376.41 135.863C375.998 133.907 375.792 131.951 375.174 130.407C374.557 128.863 373.63 128.039 373.424 125.877Z" fill="url(#paint51_linear_173_2778)"/>
<path d="M381.452 159.54C381.964 158.614 382.273 163.143 382.688 163.967C383.102 164.79 381.555 165.717 380.011 165.82C378.776 165.902 377.646 166.128 377.746 165.099C377.846 164.07 376.199 160.438 376.923 160.569C377.646 160.701 378.302 160.734 379.29 160.569C380.279 160.405 380.94 160.467 381.452 159.54Z" fill="white"/>
<path d="M390.716 160.981C391.375 160.816 391.883 161.393 392.055 161.702C392.672 163.143 392.055 164.378 391.437 165.717C390.819 167.055 389.378 168.084 388.04 168.496C386.702 168.908 386.187 168.496 384.643 169.114C383.099 169.731 383.099 170.967 380.937 171.893C378.775 172.82 377.334 171.687 377.128 171.378C376.922 171.07 377.334 169.423 377.334 168.702C377.334 167.981 376.922 167.364 376.922 166.849C376.922 166.437 377.54 165.579 377.848 165.202C377.917 165.305 378.219 165.552 378.878 165.717C379.701 165.922 381.246 165.511 382.172 164.79C382.913 164.214 382.687 163.589 382.481 163.349C382.824 163.315 383.881 163.225 385.363 163.143C387.216 163.04 389.893 161.187 390.716 160.981Z" fill="url(#paint52_linear_173_2778)"/>
<path d="M368.069 105.7C370.787 106.441 374.006 108.411 375.275 109.303C374.829 111.705 373.834 116.736 373.422 117.642C372.908 118.774 369.511 128.348 368.275 130.818C367.04 133.289 365.187 132.877 363.54 132.568C361.893 132.26 361.481 131.436 357.054 130.407C353.513 129.583 350.5 129.583 349.436 129.686L350.878 119.186L352.422 119.803C353.904 120.05 354.27 119.11 357.054 119.186C360.863 119.289 363.254 123.098 362.613 120.73C361.275 115.789 361.481 111.156 361.481 108.582C361.481 106.524 361.206 105.117 361.069 104.671C362.27 104.705 365.352 104.959 368.069 105.7Z" fill="url(#paint53_linear_173_2778)"/>
<path d="M350.262 118.26C351.003 118.589 352.08 119.495 352.012 119.701C350.879 120.318 350.879 124.642 350.879 125.26V125.26C350.879 125.877 352.526 129.069 352.012 129.686C351.497 130.304 348.306 129.686 348.306 128.863C348.306 128.039 347.07 124.951 345.732 123.098C344.394 121.245 345.217 119.289 346.247 118.671C347.276 118.054 347.585 116.407 347.688 117.024C347.791 117.642 349.335 117.848 350.262 118.26Z" fill="url(#paint54_linear_173_2778)"/>
<path d="M350.054 131.745C350.219 131.086 348.888 129.549 348.201 128.862L343.363 133.392L336.054 148.422C336.054 148.868 335.536 149.842 336.772 149.76C338.316 149.657 339.548 148.833 340.581 147.701C341.883 146.272 342.951 142.657 343.775 139.98C344.598 137.304 345.731 136.377 346.451 135.348C347.172 134.318 349.848 132.568 350.054 131.745Z" fill="#E64715"/>
<path d="M345.523 113.833C344.782 113.503 343.018 112.529 342.229 112.083L339.244 118.362V134.525L338.006 148.731C338.246 148.491 339.859 147.29 340.788 145.848C341.419 144.87 342.435 142.554 342.641 141.525C342.847 140.495 345.009 135.554 345.318 134.525C345.626 133.495 347.994 130.716 348.303 128.76C348.612 126.804 345.112 122.686 345.009 121.245C344.906 119.803 345.523 119.289 346.759 118.362C347.994 117.436 347.582 116.921 347.582 115.789C347.582 114.656 346.45 114.244 345.523 113.833Z" fill="url(#paint55_linear_173_2778)"/>
<path d="M342.23 112.083L338.627 111.156L339.142 133.083C339.142 137.819 339.306 146.981 339.965 145.745C340.995 144.51 342.127 139.466 342.127 137.922C342.127 136.377 342.024 131.024 342.127 128.965C342.23 126.907 342.745 125.774 344.083 119.803C345.154 115.027 343.294 112.666 342.23 112.083Z" fill="url(#paint56_linear_173_2778)"/>
<path d="M333.585 117.23C333.42 113.112 336.467 110.95 338.526 111.156C344.394 112.185 342.335 121.45 341.614 124.024C341.038 126.083 340.619 129 340.482 130.201C340.07 135.863 342.031 136.686 340.894 142.245C339.757 147.804 337.291 150.481 335.644 149.657C333.997 148.834 331.114 141.936 331.011 138.436C330.908 134.936 333.585 130.509 334.1 126.701C334.614 122.892 333.791 122.377 333.585 117.23Z" fill="#1E1C51"/>
<path d="M380.935 171.893C378.773 172.82 377.332 171.687 377.126 171.379V170.555C377.537 170.83 378.523 171.358 379.182 171.276C380.006 171.173 381.344 170.761 382.168 169.937C382.991 169.114 383.712 168.393 385.359 168.29C387.006 168.187 390.409 167.673 391.436 165.717C390.818 167.055 389.377 168.084 388.038 168.496C386.7 168.908 386.185 168.496 384.641 169.114C383.097 169.731 383.097 170.967 380.935 171.893Z" fill="url(#paint57_linear_173_2778)"/>
<path d="M382.273 163.349C382.356 162.855 382.308 162.525 382.273 162.422C382.411 162.525 382.95 162.956 384.126 163.04C385.568 163.143 386.446 162.812 387.421 162.422C388.45 162.011 389.685 161.29 390.715 160.981C391.744 160.672 392.259 162.011 391.847 161.496C391.435 160.981 390.612 161.702 389.788 162.113C389.137 162.439 388.656 162.937 387.421 163.452C386.185 163.966 384.95 164.378 382.994 164.481C381.822 164.543 382.17 163.966 382.273 163.349Z" fill="#E64715"/>
<path d="M364.055 104.98C367.926 105.227 373.217 107.965 375.379 109.303C375.619 107.656 376.202 103.538 376.614 100.244C377.129 96.1264 376.717 94.4793 376.717 92.6263C376.717 91.1439 376.614 90.1899 376.614 89.435L373.938 87.1702L364.364 86.6555L359.114 89.435V92.2145C359.114 94.1705 360.349 95.9205 360.143 98.7C359.978 100.924 358.908 103.401 358.393 104.362C358.668 104.465 360.184 104.733 364.055 104.98Z" fill="url(#paint58_linear_173_2778)"/>
<path d="M376.713 78.8316C376.713 77.2668 377.399 74.954 377.742 73.9932C369.781 73.8216 351.533 73.5814 351.285 73.5814C350.977 73.5814 352.315 76.9786 352.315 78.8316C352.315 80.6846 351.8 82.3317 351.8 83.9788C351.8 85.6259 351.285 87.376 351.285 89.9496V93.6556C351.457 93.3467 353.262 92.6879 354.58 91.6996C356.227 90.4643 361.168 89.229 366.83 88.8172C371.36 88.4878 375.889 90.6016 377.33 91.6996C377.33 90.8418 377.207 88.8172 376.713 87.5819C376.095 86.0377 376.713 84.4935 376.713 82.3317V78.8316Z" fill="url(#paint59_linear_173_2778)"/>
<path d="M342.538 83.4641C341.797 82.4759 341.886 78.9346 342.023 77.2875H335.538C336.121 79.0032 337.144 82.8465 336.567 84.4936C335.847 86.5525 336.67 87.1701 336.567 89.1261C336.464 91.082 337.7 93.7586 338.523 93.9645C339.347 94.1704 339.964 94.9939 340.685 94.9939C341.406 94.9939 343.156 95.5086 343.979 95.6116C344.803 95.7145 345.215 95.0969 345.935 94.9939C346.656 94.891 346.347 93.5527 347.171 92.7291C347.994 91.9056 347.273 90.8761 347.171 90.3614C347.068 89.8467 345.626 87.5819 345.215 86.2436C344.803 84.9054 343.465 84.6995 342.538 83.4641Z" fill="url(#paint60_linear_173_2778)"/>
<path d="M343.674 86.5067C344.415 86.6714 345.905 89.3205 346.557 90.6245C345.493 90.4186 343.18 89.8421 342.439 89.1833C341.512 88.3597 339.968 88.2568 338.424 88.1538C336.88 88.0509 340.071 87.6391 341.204 87.5362C342.336 87.4332 341.718 85.889 341.718 85.5802C341.718 85.2714 342.748 86.3008 343.674 86.5067Z" fill="url(#paint61_linear_173_2778)"/>
<path d="M396.673 81.3001C397.744 80.8883 399.865 78.1775 400.791 76.8735C398.526 76.5647 393.708 76.1323 392.555 76.8735C391.114 77.8 392.041 79.1383 390.291 81.3001C388.541 83.4619 387.82 82.8443 386.688 82.8443C385.555 82.8443 383.805 83.8737 383.805 84.3884C383.805 84.9032 383.599 87.2709 383.805 87.9915C384.011 88.7121 385.041 89.8445 385.967 90.4621C386.894 91.0798 388.129 90.5651 388.952 90.4621C389.776 90.3592 390.702 88.3003 391.011 87.9915C391.32 87.6827 392.658 85.6238 392.967 84.3884C393.276 83.1531 395.335 81.8148 396.673 81.3001Z" fill="url(#paint62_linear_173_2778)"/>
</g>
<path d="M352.723 53.3222C352.723 49.5603 355.772 46.5107 359.534 46.5107H405.853C409.615 46.5107 412.665 49.5603 412.665 53.3222V53.3222C412.665 57.0842 409.615 60.1338 405.853 60.1338H359.534C355.772 60.1338 352.723 57.0842 352.723 53.3222V53.3222Z" fill="#FF4F17"/>
<path className={fillClassName} d="M51.2324 49.9894C51.2324 48.0681 52.7899 46.5107 54.7111 46.5107H121.966C123.888 46.5107 125.445 48.0681 125.445 49.9894V49.9894C125.445 51.9106 123.888 53.4681 121.966 53.4681H54.7111C52.7899 53.4681 51.2324 51.9106 51.2324 49.9894V49.9894Z" fill="#D4DCE1"/>
<path className={fillClassName} d="M130.084 49.9894C130.084 48.0681 131.641 46.5107 133.563 46.5107H161.392C163.314 46.5107 164.871 48.0681 164.871 49.9894V49.9894C164.871 51.9106 163.314 53.4681 161.392 53.4681H133.563C131.641 53.4681 130.084 51.9106 130.084 49.9894V49.9894Z" fill="#D4DCE1"/>
<path className={boxBgClassName} d="M51 250.596C51 244.192 56.1916 239 62.5957 239H118.062C124.466 239 129.658 244.192 129.658 250.596V306.051C129.658 312.456 124.466 317.647 118.062 317.647H62.5957C56.1916 317.647 51 312.456 51 306.051V250.596Z" fill="#F4F7FA"/>
<path className={boxBgClassName} d="M146 250.596C146 244.192 151.192 239 157.596 239H213.062C219.466 239 224.658 244.192 224.658 250.596V306.051C224.658 312.456 219.466 317.647 213.062 317.647H157.596C151.192 317.647 146 312.456 146 306.051V250.596Z" fill="#F4F7FA"/>
<path className={boxBgClassName} d="M239 250.596C239 244.192 244.192 239 250.596 239H306.062C312.466 239 317.658 244.192 317.658 250.596V306.051C317.658 312.456 312.466 317.647 306.062 317.647H250.596C244.192 317.647 239 312.456 239 306.051V250.596Z" fill="#F4F7FA"/>
<path className={boxBgClassName} d="M334 250.596C334 244.192 339.192 239 345.596 239H401.062C407.466 239 412.658 244.192 412.658 250.596V306.051C412.658 312.456 407.466 317.647 401.062 317.647H345.596C339.192 317.647 334 312.456 334 306.051V250.596Z" fill="#F4F7FA"/>
<path fillRule="evenodd" clipRule="evenodd" d="M90 289L90 267L92 267L92 289L90 289Z" fill="#4FC865"/>
<path fillRule="evenodd" clipRule="evenodd" d="M102 279H80V277H102V279Z" fill="#4FC865"/>
<path fillRule="evenodd" clipRule="evenodd" d="M185 289L185 267L187 267L187 289L185 289Z" fill="#4FC865"/>
<path fillRule="evenodd" clipRule="evenodd" d="M197 279H175V277H197V279Z" fill="#4FC865"/>
<path fillRule="evenodd" clipRule="evenodd" d="M277 289L277 267L279 267L279 289L277 289Z" fill="#4FC865"/>
<path fillRule="evenodd" clipRule="evenodd" d="M289 279H267V277H289V279Z" fill="#4FC865"/>
<path fillRule="evenodd" clipRule="evenodd" d="M372 289L372 267L374 267L374 289L372 289Z" fill="#4FC865"/>
<path fillRule="evenodd" clipRule="evenodd" d="M384 279H362V277H384V279Z" fill="#4FC865"/>
<defs>
<filter id="filter0_d_173_2778" x="0.722656" y="0" width="460.49" height="377" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="12.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.823529 0 0 0 0 0.87451 0 0 0 0 0.909804 0 0 0 0.44 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_173_2778"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_173_2778" result="shape"/>
</filter>
<radialGradient id="paint0_radial_173_2778" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(118.527 141.96) rotate(-89.2946) scale(80.8536 280.664)">
<stop offset="0.10248" stopColor="#FCF1D4"/>
<stop offset="0.525154" stopColor="#E880E4"/>
<stop offset="1" stopColor="#4200FF"/>
</radialGradient>
<linearGradient id="paint1_linear_173_2778" x1="49.846" y1="115.749" x2="48.7076" y2="139.633" gradientUnits="userSpaceOnUse">
<stop stopColor="#4200FF"/>
<stop offset="1" stopColor="#EC95E1"/>
</linearGradient>
<linearGradient id="paint2_linear_173_2778" x1="158.896" y1="120.836" x2="152.609" y2="150.303" gradientUnits="userSpaceOnUse">
<stop stopColor="#4200FF"/>
<stop offset="1" stopColor="#EC95E1"/>
</linearGradient>
<radialGradient id="paint3_radial_173_2778" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(78.3809 139.527) rotate(92.2198) scale(28.5558 247.532)">
<stop stopColor="#20FFBC"/>
<stop offset="1" stopColor="#4200FF"/>
</radialGradient>
<linearGradient id="paint4_linear_173_2778" x1="95.1358" y1="141.076" x2="95.1358" y2="183.214" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFACCB"/>
<stop offset="1" stopColor="#4200FF"/>
</linearGradient>
<linearGradient id="paint5_linear_173_2778" x1="108.9" y1="94.3761" x2="105.688" y2="94.0314" gradientUnits="userSpaceOnUse">
<stop stopColor="#CF3200"/>
<stop offset="1" stopColor="#FFB7A0"/>
</linearGradient>
<linearGradient id="paint6_linear_173_2778" x1="128.769" y1="92.523" x2="132.346" y2="92.165" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFB7A0"/>
<stop offset="1" stopColor="#CF3200"/>
</linearGradient>
<linearGradient id="paint7_linear_173_2778" x1="130.004" y1="112.392" x2="114.459" y2="112.083" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="1" stopColor="#CE7DB0"/>
</linearGradient>
<linearGradient id="paint8_linear_173_2778" x1="107.701" y1="96.0139" x2="127.493" y2="95.2274" gradientUnits="userSpaceOnUse">
<stop stopColor="#CF3200"/>
<stop offset="1" stopColor="#FF7F57"/>
</linearGradient>
<linearGradient id="paint9_linear_173_2778" x1="132.991" y1="147.393" x2="125.702" y2="148.99" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="1" stopColor="#D987BA"/>
</linearGradient>
<linearGradient id="paint10_linear_173_2778" x1="142.253" y1="170.452" x2="142.562" y2="161.702" gradientUnits="userSpaceOnUse">
<stop stopColor="#4200FF"/>
<stop offset="1" stopColor="#20FFBC"/>
</linearGradient>
<linearGradient id="paint11_linear_173_2778" x1="127.841" y1="122.583" x2="117.238" y2="115.686" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="1" stopColor="#D987BA"/>
</linearGradient>
<linearGradient id="paint12_linear_173_2778" x1="102.803" y1="119.598" x2="108.326" y2="129.889" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFD7F3"/>
<stop offset="0.359375" stopColor="#FCF1D4"/>
<stop offset="1" stopColor="white"/>
</linearGradient>
<linearGradient id="paint13_linear_173_2778" x1="105.296" y1="125.26" x2="85.839" y2="133.392" gradientUnits="userSpaceOnUse">
<stop stopColor="#20FFBC"/>
<stop offset="1" stopColor="#4200FF"/>
</linearGradient>
<linearGradient id="paint14_linear_173_2778" x1="95.9375" y1="111.156" x2="95.9375" y2="145.859" gradientUnits="userSpaceOnUse">
<stop stopColor="#1E1C51"/>
<stop offset="1" stopColor="#1E1C51" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint15_linear_173_2778" x1="138.703" y1="165.717" x2="138.703" y2="172.251" gradientUnits="userSpaceOnUse">
<stop stopColor="#1E1C51"/>
<stop offset="1" stopColor="#1E1C51" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint16_linear_173_2778" x1="114.974" y1="95.9205" x2="130.827" y2="98.4941" gradientUnits="userSpaceOnUse">
<stop stopColor="#CF3200"/>
<stop offset="1" stopColor="#FF7F57"/>
</linearGradient>
<linearGradient id="paint17_linear_173_2778" x1="115.693" y1="86.8612" x2="131.116" y2="86.1367" gradientUnits="userSpaceOnUse">
<stop stopColor="#CF3200"/>
<stop offset="0.478853" stopColor="#E36842"/>
<stop offset="1" stopColor="#FFB7A0"/>
</linearGradient>
<linearGradient id="paint18_linear_173_2778" x1="101.119" y1="88.9618" x2="93.0956" y2="93.7036" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="0.565975" stopColor="#D693B6"/>
<stop offset="1" stopColor="#C875A9"/>
</linearGradient>
<linearGradient id="paint19_linear_173_2778" x1="96.7556" y1="95.0967" x2="98.1376" y2="86.3315" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="1" stopColor="#CE7DB0"/>
</linearGradient>
<linearGradient id="paint20_linear_173_2778" x1="148.123" y1="84.8027" x2="139.991" y2="79.9643" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="0.565975" stopColor="#D693B6"/>
<stop offset="1" stopColor="#C875A9"/>
</linearGradient>
<radialGradient id="paint21_radial_173_2778" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(241.316 141.96) rotate(-89.2946) scale(80.8536 280.664)">
<stop offset="0.10248" stopColor="#FCF1D4"/>
<stop offset="0.525154" stopColor="#E880E4"/>
<stop offset="1" stopColor="#4200FF"/>
</radialGradient>
<linearGradient id="paint22_linear_173_2778" x1="172.635" y1="115.749" x2="171.497" y2="139.633" gradientUnits="userSpaceOnUse">
<stop stopColor="#4200FF"/>
<stop offset="1" stopColor="#EC95E1"/>
</linearGradient>
<linearGradient id="paint23_linear_173_2778" x1="281.685" y1="120.836" x2="275.398" y2="150.303" gradientUnits="userSpaceOnUse">
<stop stopColor="#4200FF"/>
<stop offset="1" stopColor="#EC95E1"/>
</linearGradient>
<radialGradient id="paint24_radial_173_2778" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(201.17 139.527) rotate(92.2198) scale(28.5558 247.532)">
<stop stopColor="#20FFBC"/>
<stop offset="1" stopColor="#4200FF"/>
</radialGradient>
<linearGradient id="paint25_linear_173_2778" x1="217.925" y1="141.075" x2="217.925" y2="183.213" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFACCB"/>
<stop offset="1" stopColor="#4200FF"/>
</linearGradient>
<linearGradient id="paint26_linear_173_2778" x1="231.689" y1="94.3761" x2="228.477" y2="94.0314" gradientUnits="userSpaceOnUse">
<stop stopColor="#CF3200"/>
<stop offset="1" stopColor="#FFB7A0"/>
</linearGradient>
<linearGradient id="paint27_linear_173_2778" x1="251.558" y1="92.523" x2="255.135" y2="92.165" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFB7A0"/>
<stop offset="1" stopColor="#CF3200"/>
</linearGradient>
<linearGradient id="paint28_linear_173_2778" x1="252.793" y1="112.392" x2="237.248" y2="112.083" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="1" stopColor="#CE7DB0"/>
</linearGradient>
<linearGradient id="paint29_linear_173_2778" x1="230.49" y1="96.0139" x2="250.282" y2="95.2274" gradientUnits="userSpaceOnUse">
<stop stopColor="#CF3200"/>
<stop offset="1" stopColor="#FF7F57"/>
</linearGradient>
<linearGradient id="paint30_linear_173_2778" x1="255.78" y1="147.393" x2="248.491" y2="148.99" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="1" stopColor="#D987BA"/>
</linearGradient>
<linearGradient id="paint31_linear_173_2778" x1="265.042" y1="170.452" x2="265.351" y2="161.702" gradientUnits="userSpaceOnUse">
<stop stopColor="#4200FF"/>
<stop offset="1" stopColor="#20FFBC"/>
</linearGradient>
<linearGradient id="paint32_linear_173_2778" x1="250.63" y1="122.583" x2="240.027" y2="115.686" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="1" stopColor="#D987BA"/>
</linearGradient>
<linearGradient id="paint33_linear_173_2778" x1="225.592" y1="119.598" x2="231.115" y2="129.889" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFD7F3"/>
<stop offset="0.359375" stopColor="#FCF1D4"/>
<stop offset="1" stopColor="white"/>
</linearGradient>
<linearGradient id="paint34_linear_173_2778" x1="228.085" y1="125.26" x2="208.628" y2="133.392" gradientUnits="userSpaceOnUse">
<stop stopColor="#20FFBC"/>
<stop offset="1" stopColor="#4200FF"/>
</linearGradient>
<linearGradient id="paint35_linear_173_2778" x1="218.727" y1="111.156" x2="218.727" y2="145.859" gradientUnits="userSpaceOnUse">
<stop stopColor="#1E1C51"/>
<stop offset="1" stopColor="#1E1C51" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint36_linear_173_2778" x1="261.492" y1="165.717" x2="261.492" y2="172.251" gradientUnits="userSpaceOnUse">
<stop stopColor="#1E1C51"/>
<stop offset="1" stopColor="#1E1C51" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint37_linear_173_2778" x1="237.763" y1="95.9205" x2="253.616" y2="98.4941" gradientUnits="userSpaceOnUse">
<stop stopColor="#CF3200"/>
<stop offset="1" stopColor="#FF7F57"/>
</linearGradient>
<linearGradient id="paint38_linear_173_2778" x1="238.482" y1="86.8612" x2="253.905" y2="86.1367" gradientUnits="userSpaceOnUse">
<stop stopColor="#CF3200"/>
<stop offset="0.478853" stopColor="#E36842"/>
<stop offset="1" stopColor="#FFB7A0"/>
</linearGradient>
<linearGradient id="paint39_linear_173_2778" x1="223.908" y1="88.9621" x2="215.885" y2="93.7039" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="0.565975" stopColor="#D693B6"/>
<stop offset="1" stopColor="#C875A9"/>
</linearGradient>
<linearGradient id="paint40_linear_173_2778" x1="219.543" y1="95.0969" x2="220.925" y2="86.3318" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="1" stopColor="#CE7DB0"/>
</linearGradient>
<linearGradient id="paint41_linear_173_2778" x1="270.91" y1="84.8027" x2="262.778" y2="79.9643" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="0.565975" stopColor="#D693B6"/>
<stop offset="1" stopColor="#C875A9"/>
</linearGradient>
<radialGradient id="paint42_radial_173_2778" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(364.106 141.96) rotate(-89.2946) scale(80.8536 280.664)">
<stop offset="0.10248" stopColor="#FCF1D4"/>
<stop offset="0.525154" stopColor="#E880E4"/>
<stop offset="1" stopColor="#4200FF"/>
</radialGradient>
<linearGradient id="paint43_linear_173_2778" x1="295.424" y1="115.748" x2="294.286" y2="139.633" gradientUnits="userSpaceOnUse">
<stop stopColor="#4200FF"/>
<stop offset="1" stopColor="#EC95E1"/>
</linearGradient>
<linearGradient id="paint44_linear_173_2778" x1="404.474" y1="120.836" x2="398.187" y2="150.303" gradientUnits="userSpaceOnUse">
<stop stopColor="#4200FF"/>
<stop offset="1" stopColor="#EC95E1"/>
</linearGradient>
<radialGradient id="paint45_radial_173_2778" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(323.959 139.527) rotate(92.2198) scale(28.5558 247.532)">
<stop stopColor="#20FFBC"/>
<stop offset="1" stopColor="#4200FF"/>
</radialGradient>
<linearGradient id="paint46_linear_173_2778" x1="340.714" y1="141.076" x2="340.714" y2="183.214" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFACCB"/>
<stop offset="1" stopColor="#4200FF"/>
</linearGradient>
<linearGradient id="paint47_linear_173_2778" x1="354.478" y1="94.3761" x2="351.266" y2="94.0314" gradientUnits="userSpaceOnUse">
<stop stopColor="#CF3200"/>
<stop offset="1" stopColor="#FFB7A0"/>
</linearGradient>
<linearGradient id="paint48_linear_173_2778" x1="374.347" y1="92.523" x2="377.924" y2="92.165" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFB7A0"/>
<stop offset="1" stopColor="#CF3200"/>
</linearGradient>
<linearGradient id="paint49_linear_173_2778" x1="375.582" y1="112.392" x2="360.037" y2="112.083" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="1" stopColor="#CE7DB0"/>
</linearGradient>
<linearGradient id="paint50_linear_173_2778" x1="353.279" y1="96.0139" x2="373.071" y2="95.2274" gradientUnits="userSpaceOnUse">
<stop stopColor="#CF3200"/>
<stop offset="1" stopColor="#FF7F57"/>
</linearGradient>
<linearGradient id="paint51_linear_173_2778" x1="378.569" y1="147.393" x2="371.28" y2="148.99" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="1" stopColor="#D987BA"/>
</linearGradient>
<linearGradient id="paint52_linear_173_2778" x1="387.831" y1="170.452" x2="388.14" y2="161.702" gradientUnits="userSpaceOnUse">
<stop stopColor="#4200FF"/>
<stop offset="1" stopColor="#20FFBC"/>
</linearGradient>
<linearGradient id="paint53_linear_173_2778" x1="373.419" y1="122.583" x2="362.816" y2="115.686" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="1" stopColor="#D987BA"/>
</linearGradient>
<linearGradient id="paint54_linear_173_2778" x1="348.381" y1="119.598" x2="353.904" y2="129.889" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFD7F3"/>
<stop offset="0.359375" stopColor="#FCF1D4"/>
<stop offset="1" stopColor="white"/>
</linearGradient>
<linearGradient id="paint55_linear_173_2778" x1="350.874" y1="125.26" x2="331.417" y2="133.392" gradientUnits="userSpaceOnUse">
<stop stopColor="#20FFBC"/>
<stop offset="1" stopColor="#4200FF"/>
</linearGradient>
<linearGradient id="paint56_linear_173_2778" x1="341.516" y1="111.156" x2="341.516" y2="145.859" gradientUnits="userSpaceOnUse">
<stop stopColor="#1E1C51"/>
<stop offset="1" stopColor="#1E1C51" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint57_linear_173_2778" x1="384.281" y1="165.717" x2="384.281" y2="172.251" gradientUnits="userSpaceOnUse">
<stop stopColor="#1E1C51"/>
<stop offset="1" stopColor="#1E1C51" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint58_linear_173_2778" x1="360.552" y1="95.9205" x2="376.405" y2="98.4941" gradientUnits="userSpaceOnUse">
<stop stopColor="#CF3200"/>
<stop offset="1" stopColor="#FF7F57"/>
</linearGradient>
<linearGradient id="paint59_linear_173_2778" x1="361.271" y1="86.8612" x2="376.694" y2="86.1367" gradientUnits="userSpaceOnUse">
<stop stopColor="#CF3200"/>
<stop offset="0.478853" stopColor="#E36842"/>
<stop offset="1" stopColor="#FFB7A0"/>
</linearGradient>
<linearGradient id="paint60_linear_173_2778" x1="346.697" y1="88.9621" x2="338.674" y2="93.7039" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="0.565975" stopColor="#D693B6"/>
<stop offset="1" stopColor="#C875A9"/>
</linearGradient>
<linearGradient id="paint61_linear_173_2778" x1="342.334" y1="95.0967" x2="343.716" y2="86.3315" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="1" stopColor="#CE7DB0"/>
</linearGradient>
<linearGradient id="paint62_linear_173_2778" x1="393.699" y1="84.8027" x2="385.567" y2="79.9643" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="0.565975" stopColor="#D693B6"/>
<stop offset="1" stopColor="#C875A9"/>
</linearGradient>
</defs>
</svg>
  )
}

export const TemplateSix = ({ mainClassName, bgClassName, fillClassName, boxBgClassName }) => {
  return (
    <svg className={mainClassName} width="462" height="377" viewBox="0 0 462 377" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_173_2475)">
<path className={bgClassName} d="M25.5957 32.5958C25.5957 26.1916 30.7873 21 37.1914 21H424.489C430.893 21 436.085 26.1916 436.085 32.5957V336.404C436.085 342.808 430.893 348 424.489 348H37.1915C30.7873 348 25.5957 342.808 25.5957 336.404V32.5958Z" fill="white"/>
</g>
<mask id="mask0_173_2475" maskUnits="userSpaceOnUse" x="51" y="72" width="267" height="144">
<path d="M51.1055 72.0213H317.808V215.808H51.1055V72.0213Z" fill="#C4C4C4"/>
</mask>
<g mask="url(#mask0_173_2475)">
<path d="M320.084 72.0213H50.9588V215.809H320.084V72.0213Z" fill="url(#paint0_radial_173_2475)"/>
<path d="M186.694 153.28C189.857 153.28 195.138 155.916 197.383 157.234L201.775 158.259L49.4956 162.071C62.6249 160.802 113.19 155.775 120.218 154.452C122.808 153.964 137.349 148.887 144.378 147.863C151.406 146.838 151.992 143.329 157.263 143.329C162.534 143.329 172.93 140.547 174.687 141.719C176.444 142.89 182.74 153.28 186.694 153.28Z" fill="url(#paint1_linear_173_2475)"/>
<path d="M228.131 157.239V158.996H180.397V155.629H183.033V157.386H184.204V156.8H185.083V157.386H187.425V155.629H188.743V151.822H191.525V158.118H194.307V157.386H196.504V155.921H197.968V157.386H198.553V156.8H202.214V153.432H202.946V157.386H203.825V155.629H204.557V157.825H207.192V156.214H210.56V155.629H214.514V154.896H220.078V158.118H220.517V154.311L221.249 153.725L221.542 140.254L223.738 142.304V158.85H224.617V157.679H225.056V157.239V152.993H226.081V147.283H227.399V157.239H228.131Z" fill="#B968EA"/>
<path d="M312.031 146.551C316.482 146.317 319.254 143.427 320.084 142.012V167.929L248.483 160.315C251.656 159.68 258.616 158.265 261.076 157.679C264.15 156.947 265.615 156.215 266.493 156.215C267.372 156.215 268.25 156.947 271.325 155.336C274.4 153.726 284.796 153.286 288.164 153.286C291.532 153.286 299.438 150.065 300.463 149.186C301.488 148.308 306.467 146.844 312.031 146.551Z" fill="url(#paint2_linear_173_2475)"/>
<path d="M50.8125 215.809V161.925C50.8125 161.925 101.328 158.094 186.4 158.118C271.472 158.142 320.23 165.586 320.23 165.586V215.809L50.8125 215.809Z" fill="url(#paint3_radial_173_2475)"/>
<path d="M234.28 161.632C229.155 161.047 235.305 159.582 244.09 160.168C236.623 160.148 227.135 160.871 254.779 161.339C282.424 161.808 310.274 166.025 320.084 172.175V215.955H50.8124V193.845C136.177 186.963 240.196 176.157 261.515 172.175C288.164 167.196 243.714 162.71 234.28 161.632Z" fill="url(#paint4_linear_173_2475)"/>
<path d="M159.312 93.2529L157.555 94.7171L159.312 95.1564V93.2529Z" fill="url(#paint5_linear_173_2475)"/>
<path d="M193.135 90.6169L194.6 91.9347L193.135 92.5204V90.6169Z" fill="url(#paint6_linear_173_2475)"/>
<path d="M169.713 127.223C171.47 129.712 173.666 133.519 173.812 134.251L172.788 113.898L163.563 112.873C163.563 113.85 163.797 116.388 164.734 118.73C165.906 121.659 167.956 124.734 169.713 127.223Z" fill="url(#paint7_linear_173_2475)"/>
<path d="M159.17 99.5489C158.584 96.7376 158.926 93.7896 159.17 92.6671C159.365 92.0326 159.755 90.6464 159.755 90.1779C159.755 89.7093 166.588 87.6399 170.005 86.6637L172.055 99.5489V110.823L172.641 114.484C171.616 114.533 168.57 114.601 166.93 114.484C165.29 114.367 163.514 113.85 162.83 113.606C162.245 111.653 161.044 107.426 160.927 106.138C160.78 104.527 159.902 103.063 159.17 99.5489Z" fill="url(#paint8_linear_173_2475)"/>
<path d="M189.04 140.547C188.806 138.087 188.064 134.154 187.722 132.494C185.136 137.912 180.987 149.333 180.987 149.333C180.987 149.333 183.119 152.847 182.451 158.411C182.012 162.072 182.451 166.464 183.769 170.125C185.087 173.785 186.551 175.25 188.162 177.007C189.772 178.764 191.383 181.839 192.554 184.621C193.492 186.846 194.409 191.307 194.751 193.26C195.141 193.504 196.332 193.933 197.972 193.699C199.612 193.465 200.803 192.332 201.193 191.795C200.656 189.355 199.261 182.981 197.972 177.007C196.361 169.539 193.872 157.532 193.287 154.75C192.701 151.968 192.408 149.186 191.529 146.99C190.651 144.794 189.333 143.622 189.04 140.547Z" fill="url(#paint9_linear_173_2475)"/>
<path d="M200.46 188.428C201.188 187.11 201.628 193.552 202.217 194.724C202.807 195.895 200.607 197.213 198.41 197.359C196.653 197.477 195.047 197.799 195.189 196.334C195.331 194.87 192.989 189.705 194.018 189.892C195.047 190.079 195.98 190.126 197.385 189.892C198.791 189.658 199.732 189.745 200.46 188.428Z" fill="white"/>
<path d="M213.639 190.477C214.576 190.243 215.298 191.063 215.542 191.502C216.421 193.552 215.542 195.309 214.664 197.213C213.785 199.116 211.735 200.58 209.832 201.166C207.928 201.752 207.196 201.166 205 202.045C202.804 202.923 202.804 204.68 199.729 205.998C196.654 207.316 194.604 205.705 194.311 205.266C194.018 204.827 194.604 202.484 194.604 201.459C194.604 200.434 194.018 199.556 194.018 198.823C194.018 198.238 194.897 197.018 195.336 196.481C195.434 196.627 195.863 196.978 196.8 197.213C197.972 197.506 200.168 196.92 201.486 195.895C202.54 195.075 202.218 194.187 201.925 193.845C202.413 193.796 203.916 193.669 206.025 193.552C208.66 193.406 212.467 190.77 213.639 190.477Z" fill="url(#paint10_linear_173_2475)"/>
<path d="M181.426 111.848C185.292 112.903 189.87 115.704 191.676 116.973C191.041 120.39 189.626 127.545 189.04 128.833C188.308 130.444 183.476 144.061 181.719 147.575C179.962 151.09 177.326 150.504 174.984 150.065C172.641 149.625 172.055 148.454 165.759 146.99C160.722 145.818 156.437 145.818 154.924 145.965L156.974 131.03L159.17 131.908C161.278 132.26 161.799 130.923 165.759 131.03C171.177 131.176 174.578 136.594 173.666 133.226C171.762 126.198 172.055 119.609 172.055 115.948C172.055 113.02 171.665 111.019 171.469 110.384C173.178 110.433 177.561 110.794 181.426 111.848Z" fill="url(#paint11_linear_173_2475)"/>
<path d="M156.094 129.712C157.148 130.181 158.681 131.469 158.583 131.762C156.972 132.641 156.972 138.79 156.972 139.669V139.669C156.972 140.547 159.315 145.087 158.583 145.965C157.851 146.844 153.312 145.965 153.312 144.794C153.312 143.622 151.555 139.23 149.651 136.594C147.748 133.958 148.919 131.176 150.383 130.298C151.847 129.419 152.287 127.077 152.433 127.955C152.58 128.834 154.776 129.126 156.094 129.712Z" fill="url(#paint12_linear_173_2475)"/>
<path d="M155.802 148.893C156.037 147.956 154.143 145.769 153.167 144.793L146.285 151.236L135.889 172.614C135.889 173.248 135.153 174.634 136.91 174.517C139.106 174.371 140.859 173.199 142.327 171.589C144.18 169.556 145.699 164.414 146.871 160.607C148.042 156.8 149.653 155.482 150.678 154.018C151.703 152.554 155.51 150.065 155.802 148.893Z" fill="#E64715"/>
<path d="M149.359 123.416C148.305 122.947 145.796 121.561 144.673 120.927L140.427 129.858V152.847L138.666 173.053C139.008 172.712 141.302 171.003 142.624 168.953C143.521 167.561 144.966 164.268 145.259 162.804C145.552 161.339 148.627 154.311 149.066 152.847C149.505 151.383 152.873 147.429 153.312 144.647C153.752 141.865 148.773 136.008 148.627 133.958C148.48 131.908 149.359 131.176 151.116 129.858C152.873 128.541 152.287 127.809 152.287 126.198C152.287 124.587 150.677 124.002 149.359 123.416Z" fill="url(#paint13_linear_173_2475)"/>
<path d="M144.673 120.927L139.548 119.609L140.28 150.797C140.28 157.532 140.515 170.564 141.452 168.807C142.916 167.05 144.527 159.875 144.527 157.679C144.527 155.482 144.38 147.868 144.527 144.94C144.673 142.012 145.405 140.401 147.309 131.908C148.831 125.114 146.186 121.756 144.673 120.927Z" fill="url(#paint14_linear_173_2475)"/>
<path d="M132.374 128.248C132.139 122.391 136.474 119.316 139.402 119.609C147.748 121.073 144.82 134.251 143.795 137.912C142.975 140.84 142.379 144.989 142.184 146.697C141.598 154.75 144.387 155.922 142.77 163.828C141.153 171.735 137.645 175.542 135.302 174.371C132.959 173.2 128.86 163.389 128.713 158.411C128.567 153.432 132.374 147.136 133.106 141.719C133.838 136.301 132.667 135.569 132.374 128.248Z" fill="#1E1C51"/>
<path d="M199.723 205.999C196.648 207.316 194.598 205.706 194.305 205.266V204.095C194.89 204.485 196.293 205.237 197.23 205.12C198.401 204.974 200.305 204.388 201.476 203.216C202.647 202.045 203.672 201.02 206.015 200.874C208.358 200.727 213.198 199.995 214.658 197.213C213.78 199.117 211.73 200.581 209.826 201.167C207.923 201.752 207.191 201.167 204.994 202.045C202.798 202.924 202.798 204.681 199.723 205.999Z" fill="url(#paint15_linear_173_2475)"/>
<path d="M201.629 193.845C201.746 193.142 201.677 192.674 201.629 192.527C201.824 192.674 202.591 193.286 204.264 193.406C206.314 193.552 207.563 193.082 208.95 192.527C210.414 191.942 212.171 190.917 213.635 190.477C215.1 190.038 215.832 191.942 215.246 191.21C214.66 190.477 213.489 191.502 212.318 192.088C211.391 192.551 210.707 193.26 208.95 193.992C207.193 194.724 205.436 195.309 202.654 195.456C200.986 195.544 201.482 194.724 201.629 193.845Z" fill="#E64715"/>
<path d="M175.715 110.823C181.221 111.175 188.747 115.07 191.822 116.973C192.164 114.63 192.993 108.774 193.579 104.088C194.311 98.2311 193.725 95.8883 193.725 93.2527C193.725 91.1442 193.579 89.7873 193.579 88.7136L189.772 85.4923L176.155 84.7602L168.687 88.7136V92.667C168.687 95.449 170.444 97.9382 170.151 101.892C169.917 105.054 168.394 108.578 167.662 109.945C168.053 110.091 170.21 110.472 175.715 110.823Z" fill="url(#paint16_linear_173_2475)"/>
<path d="M193.721 73.6321C193.721 71.4065 194.697 68.1168 195.186 66.7502C183.862 66.5062 157.906 66.1645 157.555 66.1645C157.116 66.1645 159.019 70.9965 159.019 73.6321C159.019 76.2677 158.287 78.6105 158.287 80.9532C158.287 83.296 157.555 85.7852 157.555 89.4458V94.717C157.799 94.2777 160.366 93.3406 162.24 91.9349C164.583 90.1779 171.611 88.4208 179.665 87.8351C186.107 87.3665 192.55 90.3731 194.6 91.9349C194.6 90.7147 194.424 87.8351 193.721 86.078C192.843 83.8817 193.721 81.6853 193.721 78.6105V73.6321Z" fill="url(#paint17_linear_173_2475)"/>
<path d="M145.108 80.2212C144.054 78.8156 144.181 73.7786 144.376 71.4359H135.152C135.981 73.8762 137.436 79.3427 136.616 81.6855C135.591 84.6139 136.762 85.4924 136.616 88.2745C136.469 91.0565 138.226 94.8635 139.398 95.1564C140.569 95.4492 141.448 96.6206 142.473 96.6206C143.498 96.6206 145.987 97.3527 147.158 97.4991C148.33 97.6455 148.915 96.767 149.94 96.6206C150.965 96.4742 150.526 94.5707 151.697 93.3993C152.869 92.2279 151.844 90.7637 151.697 90.0316C151.551 89.2994 149.501 86.0781 148.915 84.1746C148.33 82.2711 146.426 81.9783 145.108 80.2212Z" fill="url(#paint18_linear_173_2475)"/>
<path d="M146.724 84.549C147.779 84.7833 149.897 88.5513 150.824 90.4059C149.311 90.1131 146.022 89.2931 144.967 88.356C143.649 87.1847 141.453 87.0382 139.257 86.8918C137.06 86.7454 141.6 86.1597 143.21 86.0133C144.821 85.8668 143.942 83.6705 143.942 83.2312C143.942 82.792 145.407 84.2562 146.724 84.549Z" fill="url(#paint19_linear_173_2475)"/>
<path d="M222.112 77.1427C223.635 76.557 226.651 72.7012 227.969 70.8465C224.747 70.4072 217.895 69.7922 216.255 70.8465C214.205 72.1643 215.523 74.0678 213.034 77.1427C210.544 80.2175 209.519 79.339 207.909 79.339C206.298 79.339 203.809 80.8032 203.809 81.5353C203.809 82.2675 203.516 85.6352 203.809 86.6601C204.102 87.6851 205.566 89.2958 206.884 90.1743C208.202 91.0528 209.959 90.3207 211.13 90.1743C212.302 90.0279 213.619 87.0994 214.059 86.6601C214.498 86.2209 216.401 83.2924 216.841 81.5353C217.28 79.7783 220.208 77.8748 222.112 77.1427Z" fill="url(#paint20_linear_173_2475)"/>
</g>
<path d="M352.596 53.3222C352.596 49.5603 355.645 46.5107 359.407 46.5107H405.726C409.488 46.5107 412.538 49.5603 412.538 53.3222V53.3222C412.538 57.0842 409.488 60.1338 405.726 60.1338H359.407C355.645 60.1338 352.596 57.0842 352.596 53.3222V53.3222Z" fill="#FF4F17"/>
<path className={fillClassName} d="M51.1055 49.9894C51.1055 48.0681 52.6629 46.5107 54.5842 46.5107H121.84C123.761 46.5107 125.318 48.0681 125.318 49.9894V49.9894C125.318 51.9106 123.761 53.4681 121.84 53.4681H54.5842C52.6629 53.4681 51.1055 51.9106 51.1055 49.9894V49.9894Z" fill="#D4DCE1"/>
<path className={fillClassName} d="M129.957 49.9894C129.957 48.0681 131.515 46.5107 133.436 46.5107H161.266C163.187 46.5107 164.744 48.0681 164.744 49.9894V49.9894C164.744 51.9106 163.187 53.4681 161.266 53.4681H133.436C131.515 53.4681 129.957 51.9106 129.957 49.9894V49.9894Z" fill="#D4DCE1"/>
<path className={boxBgClassName} d="M51.1055 250.596C51.1055 244.192 56.2971 239 62.7012 239H118.167C124.571 239 129.763 244.192 129.763 250.596V306.051C129.763 312.456 124.571 317.647 118.167 317.647H62.7012C56.2971 317.647 51.1055 312.456 51.1055 306.051V250.596Z" fill="#F4F7FA"/>
<path className={boxBgClassName} d="M146.191 250.596C146.191 244.192 151.383 239 157.787 239H213.253C219.657 239 224.849 244.192 224.849 250.596V306.051C224.849 312.456 219.657 317.647 213.253 317.647H157.787C151.383 317.647 146.191 312.456 146.191 306.051V250.596Z" fill="#F4F7FA"/>
<path className={boxBgClassName} d="M238.957 250.596C238.957 244.192 244.149 239 250.553 239H306.019C312.423 239 317.615 244.192 317.615 250.596V306.051C317.615 312.456 312.423 317.647 306.019 317.647H250.553C244.149 317.647 238.957 312.456 238.957 306.051V250.596Z" fill="#F4F7FA"/>
<path className={boxBgClassName} d="M334.043 250.596C334.043 244.192 339.235 239 345.639 239H401.105C407.509 239 412.701 244.192 412.701 250.596V306.051C412.701 312.456 407.509 317.647 401.105 317.647H345.639C339.235 317.647 334.043 312.456 334.043 306.051V250.596Z" fill="#F4F7FA"/>
<path className={fillClassName} d="M343.318 94.8262C343.318 93.7589 344.184 92.8936 345.251 92.8936H399.364C400.432 92.8936 401.297 93.7589 401.297 94.8262V94.8262C401.297 95.8936 400.432 96.7589 399.364 96.7589H345.251C344.184 96.7589 343.318 95.8936 343.318 94.8262V94.8262Z" fill="#D4DCE1"/>
<path className={fillClassName} d="M343.318 102.557C343.318 101.489 344.184 100.624 345.251 100.624H380.038C381.106 100.624 381.971 101.489 381.971 102.557V102.557C381.971 103.624 381.106 104.489 380.038 104.489H345.251C344.184 104.489 343.318 103.624 343.318 102.557V102.557Z" fill="#D4DCE1"/>
<path className={fillClassName} d="M343.318 127.294C343.318 126.227 344.184 125.362 345.251 125.362H399.364C400.432 125.362 401.297 126.227 401.297 127.294V127.294C401.297 128.362 400.432 129.227 399.364 129.227H345.251C344.184 129.227 343.318 128.362 343.318 127.294V127.294Z" fill="#D4DCE1"/>
<path className={fillClassName} d="M343.318 135.025C343.318 133.957 344.184 133.092 345.251 133.092H380.038C381.106 133.092 381.971 133.957 381.971 135.025V135.025C381.971 136.092 381.106 136.957 380.038 136.957H345.251C344.184 136.957 343.318 136.092 343.318 135.025V135.025Z" fill="#D4DCE1"/>
<path className={fillClassName} d="M343.318 159.762C343.318 158.695 344.184 157.83 345.251 157.83H399.364C400.432 157.83 401.297 158.695 401.297 159.762V159.762C401.297 160.83 400.432 161.695 399.364 161.695H345.251C344.184 161.695 343.318 160.83 343.318 159.762V159.762Z" fill="#D4DCE1"/>
<path className={fillClassName} d="M343.318 167.493C343.318 166.425 344.184 165.56 345.251 165.56H380.038C381.106 165.56 381.971 166.425 381.971 167.493V167.493C381.971 168.56 381.106 169.425 380.038 169.425H345.251C344.184 169.425 343.318 168.56 343.318 167.493V167.493Z" fill="#D4DCE1"/>
<path className={fillClassName} d="M343.318 111.06C343.318 109.993 344.184 109.128 345.251 109.128H399.364C400.432 109.128 401.297 109.993 401.297 111.06V111.06C401.297 112.128 400.432 112.993 399.364 112.993H345.251C344.184 112.993 343.318 112.128 343.318 111.06V111.06Z" fill="#D4DCE1"/>
<path className={fillClassName} d="M343.318 118.791C343.318 117.723 344.184 116.858 345.251 116.858H380.038C381.106 116.858 381.971 117.723 381.971 118.791V118.791C381.971 119.858 381.106 120.723 380.038 120.723H345.251C344.184 120.723 343.318 119.858 343.318 118.791V118.791Z" fill="#D4DCE1"/>
<path className={fillClassName} d="M343.318 143.528C343.318 142.461 344.184 141.596 345.251 141.596H399.364C400.432 141.596 401.297 142.461 401.297 143.528V143.528C401.297 144.596 400.432 145.461 399.364 145.461H345.251C344.184 145.461 343.318 144.596 343.318 143.528V143.528Z" fill="#D4DCE1"/>
<path className={fillClassName} d="M343.318 151.259C343.318 150.191 344.184 149.326 345.251 149.326H380.038C381.106 149.326 381.971 150.191 381.971 151.259V151.259C381.971 152.326 381.106 153.191 380.038 153.191H345.251C344.184 153.191 343.318 152.326 343.318 151.259V151.259Z" fill="#D4DCE1"/>
<path className={fillClassName} d="M343.318 175.996C343.318 174.929 344.184 174.064 345.251 174.064H399.364C400.432 174.064 401.297 174.929 401.297 175.996V175.996C401.297 177.064 400.432 177.929 399.364 177.929H345.251C344.184 177.929 343.318 177.064 343.318 175.996V175.996Z" fill="#D4DCE1"/>
<path className={fillClassName} d="M343.318 183.727C343.318 182.659 344.184 181.794 345.251 181.794H380.038C381.106 181.794 381.971 182.659 381.971 183.727V183.727C381.971 184.794 381.106 185.659 380.038 185.659H345.251C344.184 185.659 343.318 184.794 343.318 183.727V183.727Z" fill="#D4DCE1"/>
<path fillRule="evenodd" clipRule="evenodd" d="M89 289L89 267L91 267L91 289L89 289Z" fill="#4FC865"/>
<path fillRule="evenodd" clipRule="evenodd" d="M101 279H79V277H101V279Z" fill="#4FC865"/>
<path fillRule="evenodd" clipRule="evenodd" d="M185 289L185 267L187 267L187 289L185 289Z" fill="#4FC865"/>
<path fillRule="evenodd" clipRule="evenodd" d="M197 279H175V277H197V279Z" fill="#4FC865"/>
<path fillRule="evenodd" clipRule="evenodd" d="M277 289L277 267L279 267L279 289L277 289Z" fill="#4FC865"/>
<path fillRule="evenodd" clipRule="evenodd" d="M289 279H267V277H289V279Z" fill="#4FC865"/>
<path fillRule="evenodd" clipRule="evenodd" d="M372 289L372 267L374 267L374 289L372 289Z" fill="#4FC865"/>
<path fillRule="evenodd" clipRule="evenodd" d="M384 279H362V277H384V279Z" fill="#4FC865"/>
<defs>
<filter id="filter0_d_173_2475" x="0.595703" y="0" width="460.49" height="377" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="12.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.823529 0 0 0 0 0.87451 0 0 0 0 0.909804 0 0 0 0.44 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_173_2475"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_173_2475" result="shape"/>
</filter>
<radialGradient id="paint0_radial_173_2475" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(245.994 161.339) rotate(-89.2946) scale(107.043 371.575)">
<stop offset="0.10248" stopColor="#FCF1D4"/>
<stop offset="0.525154" stopColor="#E880E4"/>
<stop offset="1" stopColor="#4200FF"/>
</radialGradient>
<linearGradient id="paint1_linear_173_2475" x1="155.067" y1="126.637" x2="153.559" y2="158.259" gradientUnits="userSpaceOnUse">
<stop stopColor="#4200FF"/>
<stop offset="1" stopColor="#EC95E1"/>
</linearGradient>
<linearGradient id="paint2_linear_173_2475" x1="299.438" y1="133.373" x2="291.115" y2="172.384" gradientUnits="userSpaceOnUse">
<stop stopColor="#4200FF"/>
<stop offset="1" stopColor="#EC95E1"/>
</linearGradient>
<radialGradient id="paint3_radial_173_2475" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(192.843 158.118) rotate(92.2198) scale(37.8054 327.711)">
<stop stopColor="#20FFBC"/>
<stop offset="1" stopColor="#4200FF"/>
</radialGradient>
<linearGradient id="paint4_linear_173_2475" x1="215.026" y1="160.168" x2="215.026" y2="215.955" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFACCB"/>
<stop offset="1" stopColor="#4200FF"/>
</linearGradient>
<linearGradient id="paint5_linear_173_2475" x1="162.094" y1="95.7421" x2="157.524" y2="95.2517" gradientUnits="userSpaceOnUse">
<stop stopColor="#CF3200"/>
<stop offset="1" stopColor="#FFB7A0"/>
</linearGradient>
<linearGradient id="paint6_linear_173_2475" x1="190.353" y1="93.1061" x2="195.442" y2="92.597" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFB7A0"/>
<stop offset="1" stopColor="#CF3200"/>
</linearGradient>
<linearGradient id="paint7_linear_173_2475" x1="192.111" y1="121.366" x2="170.001" y2="120.927" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="1" stopColor="#CE7DB0"/>
</linearGradient>
<linearGradient id="paint8_linear_173_2475" x1="160.389" y1="98.0712" x2="188.541" y2="96.9526" gradientUnits="userSpaceOnUse">
<stop stopColor="#CF3200"/>
<stop offset="1" stopColor="#FF7F57"/>
</linearGradient>
<linearGradient id="paint9_linear_173_2475" x1="196.357" y1="171.15" x2="185.991" y2="173.421" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="1" stopColor="#D987BA"/>
</linearGradient>
<linearGradient id="paint10_linear_173_2475" x1="209.535" y1="203.948" x2="209.974" y2="191.502" gradientUnits="userSpaceOnUse">
<stop stopColor="#4200FF"/>
<stop offset="1" stopColor="#20FFBC"/>
</linearGradient>
<linearGradient id="paint11_linear_173_2475" x1="189.036" y1="135.862" x2="173.954" y2="126.051" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="1" stopColor="#D987BA"/>
</linearGradient>
<linearGradient id="paint12_linear_173_2475" x1="153.419" y1="131.616" x2="161.275" y2="146.253" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFD7F3"/>
<stop offset="0.359375" stopColor="#FCF1D4"/>
<stop offset="1" stopColor="white"/>
</linearGradient>
<linearGradient id="paint13_linear_173_2475" x1="156.969" y1="139.669" x2="129.295" y2="151.236" gradientUnits="userSpaceOnUse">
<stop stopColor="#20FFBC"/>
<stop offset="1" stopColor="#4200FF"/>
</linearGradient>
<linearGradient id="paint14_linear_173_2475" x1="143.657" y1="119.609" x2="143.657" y2="168.968" gradientUnits="userSpaceOnUse">
<stop stopColor="#1E1C51"/>
<stop offset="1" stopColor="#1E1C51" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint15_linear_173_2475" x1="204.482" y1="197.213" x2="204.482" y2="206.507" gradientUnits="userSpaceOnUse">
<stop stopColor="#1E1C51"/>
<stop offset="1" stopColor="#1E1C51" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint16_linear_173_2475" x1="170.733" y1="97.9382" x2="193.282" y2="101.599" gradientUnits="userSpaceOnUse">
<stop stopColor="#CF3200"/>
<stop offset="1" stopColor="#FF7F57"/>
</linearGradient>
<linearGradient id="paint17_linear_173_2475" x1="171.758" y1="85.0531" x2="193.694" y2="84.0225" gradientUnits="userSpaceOnUse">
<stop stopColor="#CF3200"/>
<stop offset="0.478853" stopColor="#E36842"/>
<stop offset="1" stopColor="#FFB7A0"/>
</linearGradient>
<linearGradient id="paint18_linear_173_2475" x1="151.024" y1="88.0413" x2="139.612" y2="94.7858" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="0.565975" stopColor="#D693B6"/>
<stop offset="1" stopColor="#C875A9"/>
</linearGradient>
<linearGradient id="paint19_linear_173_2475" x1="144.818" y1="96.767" x2="146.783" y2="84.2998" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="1" stopColor="#CE7DB0"/>
</linearGradient>
<linearGradient id="paint20_linear_173_2475" x1="217.882" y1="82.1246" x2="206.314" y2="75.2428" gradientUnits="userSpaceOnUse">
<stop stopColor="#F8DBD7"/>
<stop offset="0.565975" stopColor="#D693B6"/>
<stop offset="1" stopColor="#C875A9"/>
</linearGradient>
</defs>
</svg>
  )
}

export const SlideThree = ({ mainClassName, bgClassName, fillClassName, boxBgClassName, lightFillClassName }) => {
  return (
    <svg className={mainClassName} width="233" height="256" viewBox="0 0 233 256" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_126_3955)">
<path className={bgClassName} d="M10 14.3099C10 9.72045 13.7205 6 18.3099 6H214.423C219.012 6 222.732 9.72045 222.732 14.3099V233.69C222.732 238.28 219.012 242 214.423 242H18.3099C13.7205 242 10 238.28 10 233.69V14.3099Z" fill="white"/>
</g>
<path className={fillClassName} d="M18.3096 178.014C18.3096 176.637 19.4257 175.521 20.8025 175.521H165.394C166.771 175.521 167.887 176.637 167.887 178.014C167.887 179.391 166.771 180.507 165.394 180.507H20.8025C19.4257 180.507 18.3096 179.391 18.3096 178.014Z" fill="#D4DCE1"/>
<path className={fillClassName} d="M18.3096 187.986C18.3096 186.609 19.4257 185.493 20.8025 185.493H115.535C116.912 185.493 118.028 186.609 118.028 187.986C118.028 189.363 116.912 190.479 115.535 190.479H20.8025C19.4257 190.479 18.3096 189.363 18.3096 187.986Z" fill="#D4DCE1"/>
<path d="M18.3096 218.732C18.3096 213.225 22.7741 208.76 28.2814 208.76H88.1124C93.6197 208.76 98.0842 213.225 98.0842 218.732C98.0842 224.24 93.6197 228.704 88.1124 228.704H28.2814C22.7741 228.704 18.3096 224.24 18.3096 218.732Z" fill="#FF4F17"/>
<path className={lightFillClassName} d="M167.886 211.715C168.876 210.805 170.154 210.423 171.334 210.423C172.318 210.423 173.261 210.793 174.019 211.225C174.553 211.529 175.017 211.877 175.366 212.182C175.716 211.877 176.18 211.529 176.714 211.225C177.472 210.793 178.415 210.423 179.399 210.423C180.579 210.423 181.857 210.805 182.847 211.715C183.85 212.637 184.507 214.054 184.507 216.009C184.507 217.065 184.093 218.13 183.484 219.139C182.872 220.153 182.034 221.155 181.122 222.097C179.304 223.974 177.129 225.669 175.699 226.783L175.699 226.783L175.366 227.042L175.034 226.784L175.034 226.784C173.604 225.669 171.429 223.974 169.61 222.097C168.699 221.155 167.861 220.153 167.249 219.138C166.64 218.13 166.226 217.065 166.226 216.009C166.226 214.054 166.883 212.637 167.886 211.715Z" fill="#EEF2F5"/>
<path className={lightFillClassName} fillRule="evenodd" clipRule="evenodd" d="M207.775 225.558L203.92 225.437C202.592 226.446 200.952 227.042 199.179 227.042C194.747 227.042 191.155 223.322 191.155 218.732C191.155 214.143 194.747 210.423 199.179 210.423C203.61 210.423 207.202 214.143 207.202 218.732C207.202 219.497 207.102 220.238 206.915 220.941L207.775 225.558Z" fill="#EEF2F5"/>
<path className={boxBgClassName} d="M18.3096 20.9578C18.3096 16.3684 22.03 12.6479 26.6194 12.6479H207.774C212.364 12.6479 216.084 16.3684 216.084 20.9578V157.24C216.084 161.829 212.364 165.549 207.774 165.549H26.6194C22.03 165.549 18.3096 161.829 18.3096 157.24V20.9578Z" fill="#F4F7FA"/>
<defs>
<filter id="filter0_d_126_3955" x="0" y="0" width="232.732" height="256" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.823529 0 0 0 0 0.87451 0 0 0 0 0.909804 0 0 0 0.44 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_126_3955"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_126_3955" result="shape"/>
</filter>
</defs>
</svg>
  )
}

export const SlideFour = ({ mainClassName, bgClassName, fillClassName, lightFillClassName }) => {
  return (
    <svg className={mainClassName} width="298" height="325" viewBox="0 0 298 325" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_126_4649)">
<path className={bgClassName} d="M25 30.675C25 25.3316 29.3316 21 34.675 21H263.005C268.348 21 272.68 25.3316 272.68 30.675V286.095C272.68 291.439 268.348 295.77 263.005 295.77H34.675C29.3316 295.77 25 291.439 25 286.095V30.675Z" fill="white"/>
</g>
<path d="M73.3117 108.002C87.3669 113.026 102.549 108.49 111.682 97.7668C113.861 95.2173 115.703 92.293 117.093 89.1061C117.394 88.3938 117.694 87.6814 117.958 86.9316C120.626 79.5081 120.588 71.7847 118.409 64.8112C115.252 54.7258 107.548 46.2151 96.8372 42.3909C93.3046 41.1161 89.6969 40.4788 86.1267 40.3663C71.3951 39.9164 57.4151 48.877 52.1914 63.4615C45.7276 81.5702 55.1603 101.516 73.3117 108.002ZM60.4216 66.3859C64.33 55.4381 74.815 48.727 85.8637 49.0645C88.5319 49.1395 91.2377 49.6269 93.9059 50.6016C101.948 53.4511 107.698 59.8622 110.066 67.3981C111.719 72.6471 111.719 78.4208 109.727 83.9697C109.539 84.5321 109.314 85.057 109.089 85.5819C108.036 87.9814 106.646 90.1559 105.03 92.068C98.1901 100.091 86.8032 103.503 76.2806 99.7164C62.6764 94.9174 55.5737 79.958 60.4216 66.3859Z" fill="#4AAFFF"/>
<path d="M110.065 67.3981L118.408 64.8111C115.251 54.7257 107.547 46.215 96.8368 42.3908C93.3043 41.1161 89.6965 40.4787 86.1264 40.3662L85.8633 49.0644C88.5315 49.1394 91.2373 49.6268 93.9055 50.6016C101.948 53.451 107.698 59.8622 110.065 67.3981Z" fill="#4760FF"/>
<path d="M109.089 85.6197L117.094 89.1065C117.394 88.3941 117.695 87.6818 117.958 86.9319C120.626 79.5085 120.589 71.7851 118.409 64.8115L110.066 67.3985C111.72 72.6474 111.72 78.4212 109.728 83.97C109.54 84.5324 109.314 85.0948 109.089 85.6197Z" fill="#97D6FF"/>
<path d="M111.681 97.7671C113.861 95.2176 115.702 92.2932 117.093 89.1064L109.088 85.6196C108.036 88.0191 106.645 90.1937 105.029 92.1058L111.681 97.7671Z" fill="#C0F2FF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M251.386 115.579C252.585 116.643 252.695 118.477 251.631 119.676L229.053 145.13C226.185 148.362 221.859 149.897 217.595 149.193L189.995 144.637C187.856 144.284 185.678 144.976 184.135 146.498L151.737 178.467C148.69 181.474 144.322 182.725 140.146 181.786L100.94 172.979C98.7576 172.488 96.4743 173.107 94.8382 174.632L56.0033 210.819C54.8305 211.912 52.9939 211.847 51.9011 210.674C50.8083 209.501 50.8731 207.665 52.0459 206.572L90.8808 170.385C93.9193 167.553 98.1597 166.404 102.212 167.315L141.418 176.122C143.667 176.628 146.019 175.954 147.659 174.335L180.058 142.366C182.923 139.539 186.969 138.254 190.94 138.909L218.54 143.465C220.836 143.844 223.166 143.018 224.71 141.278L247.288 115.824C248.352 114.625 250.187 114.515 251.386 115.579Z" fill="url(#paint0_linear_126_4649)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M150.775 48.0902C150.775 44.8841 153.374 42.2852 156.58 42.2852H247.525C250.731 42.2852 253.33 44.8841 253.33 48.0902C253.33 51.2962 250.731 53.8952 247.525 53.8952H156.58C153.374 53.8952 150.775 51.2962 150.775 48.0902Z" fill="#FF4F17"/>
<path className={lightFillClassName} fillRule="evenodd" clipRule="evenodd" d="M150.775 69.3748C150.775 66.1688 153.374 63.5698 156.58 63.5698H247.525C250.731 63.5698 253.33 66.1688 253.33 69.3748C253.33 72.5808 250.731 75.1798 247.525 75.1798H156.58C153.374 75.1798 150.775 72.5808 150.775 69.3748Z" fill="#EEF2F5"/>
<path className={lightFillClassName} fillRule="evenodd" clipRule="evenodd" d="M150.775 90.66C150.775 87.454 153.374 84.855 156.58 84.855H247.525C250.731 84.855 253.33 87.454 253.33 90.66C253.33 93.866 250.731 96.465 247.525 96.465H156.58C153.374 96.465 150.775 93.866 150.775 90.66Z" fill="#EEF2F5"/>
<path className={lightFillClassName} d="M168.19 241.59H187.54V272.55H168.19V241.59Z" fill="#EEF2F5"/>
<path className={lightFillClassName} d="M199.15 228.045H218.5V272.55H199.15V228.045Z" fill="#EEF2F5"/>
<path d="M230.11 208.695H249.46V272.55H230.11V208.695Z" fill="#4FC865"/>
<path className={fillClassName} d="M48.7305 240.089H52.167V249.33H54.0092V240.089H57.4302V238.432H48.7305V240.089Z" fill="#D4DCE1"/>
<path className={fillClassName} d="M60.9783 241.25C58.6563 241.25 57.0928 242.937 57.0928 245.383C57.0928 247.829 58.6563 249.516 60.9783 249.516C63.3003 249.516 64.8638 247.829 64.8638 245.383C64.8638 242.937 63.3003 241.25 60.9783 241.25ZM60.9783 247.952C59.678 247.952 58.8885 246.9 58.8885 245.383C58.8885 243.866 59.678 242.813 60.9783 242.813C62.2786 242.813 63.0681 243.866 63.0681 245.383C63.0681 246.9 62.2786 247.952 60.9783 247.952Z" fill="#D4DCE1"/>
<path className={fillClassName} d="M68.7652 246.652V242.937H70.3906V241.435H68.7652V239.376H67.0005V241.435H65.7311V242.937H67.0005V246.76C67.0005 248.587 67.8519 249.346 69.6011 249.346H70.3906V247.798H69.8953C69.2451 247.798 68.7652 247.596 68.7652 246.652Z" fill="#D4DCE1"/>
<path className={fillClassName} d="M76.6677 244.098V244.547H74.7481C72.7822 244.547 71.5747 245.476 71.5747 247.039C71.5747 248.587 72.7822 249.516 74.3147 249.516C75.3364 249.516 76.2033 249.129 76.7605 248.432V249.33H78.4014V244.129C78.4014 242.287 77.1166 241.25 75.1816 241.25C73.2466 241.25 72.1165 242.333 71.8843 243.618H73.5562C73.7264 243.123 74.2063 242.705 75.1196 242.705C76.002 242.705 76.6677 243.169 76.6677 244.098ZM74.7017 248.076C73.9122 248.076 73.3549 247.689 73.3549 246.977C73.3549 246.265 73.9741 245.878 74.872 245.878H76.6677V246.358C76.6677 247.426 75.7543 248.076 74.7017 248.076Z" fill="#D4DCE1"/>
<path className={fillClassName} d="M82.2392 238.076H80.4745V249.33H82.2392V238.076Z" fill="#D4DCE1"/>
<path d="M50.9482 268.506C51.2191 270.596 52.7284 271.97 54.915 272.357V274.272H57.1983V272.395C59.6557 272.066 61.1457 270.538 61.1457 268.274C61.1457 265.991 59.5977 264.868 57.4692 264.52L55.4181 264.21C54.4312 264.056 53.9668 263.63 53.9668 262.933C53.9668 261.985 54.8376 261.424 55.9212 261.424C57.0822 261.424 57.8562 261.869 58.1464 262.856H60.9522C60.6619 260.824 59.2687 259.508 57.1983 259.141V257.322H54.915V259.102C52.6704 259.47 51.103 260.998 51.103 263.127C51.103 265.159 52.3414 266.416 54.6634 266.765L56.7339 267.074C57.7207 267.229 58.2819 267.674 58.2819 268.487C58.2819 269.435 57.4885 270.073 56.2114 270.073C54.7795 270.073 54.0249 269.377 53.7927 268.506H50.9482Z" fill="#FF007A"/>
<path d="M73.3791 263.591C72.0053 263.591 70.6701 264.094 69.8961 265.313C70.0316 262.333 71.1732 261.211 72.7406 261.211C73.8822 261.211 74.5208 261.85 74.6949 262.45H77.5781C77.3072 260.418 75.5076 258.696 72.7406 258.696C69.4898 258.696 67.071 261.018 67.071 265.855C67.071 270.035 69.1415 272.782 72.7793 272.782C75.7592 272.782 77.8103 270.77 77.8103 267.964C77.8103 265.236 75.6431 263.591 73.3791 263.591ZM72.6825 270.248C71.4248 270.248 70.4379 269.338 70.4379 268.08C70.4379 266.765 71.4441 265.933 72.6438 265.933C73.8822 265.933 74.8497 266.803 74.8497 268.08C74.8497 269.338 73.9403 270.248 72.6825 270.248Z" fill="#FF007A"/>
<path d="M89.1341 270.248C87.857 270.248 87.1217 269.474 86.9476 268.777H84.0257C84.2773 271.06 86.2897 272.782 89.1922 272.782C92.0173 272.782 94.2812 271.022 94.2812 267.984C94.2812 265.062 92.1527 263.552 89.8888 263.552C88.573 263.552 87.5668 264.056 86.9476 264.752L87.2572 261.482H93.4879V258.928H84.7804L84.1225 266.997H87.025C87.4894 266.261 88.244 265.894 89.1535 265.894C90.3145 265.894 91.3207 266.707 91.3207 268.042C91.3207 269.357 90.4306 270.248 89.1341 270.248Z" fill="#FF007A"/>
<path d="M101.686 258.696C98.3195 258.696 96.1716 261.54 96.1716 265.739C96.1716 269.938 98.3195 272.782 101.686 272.782C105.053 272.782 107.201 269.938 107.201 265.739C107.201 261.54 105.053 258.696 101.686 258.696ZM101.686 270.209C99.9449 270.209 99.1322 268.274 99.1322 265.739C99.1322 263.204 99.9449 261.269 101.686 261.269C103.428 261.269 104.26 263.204 104.26 265.739C104.26 268.274 103.428 270.209 101.686 270.209Z" fill="#FF007A"/>
<path d="M114.649 258.696C111.282 258.696 109.135 261.54 109.135 265.739C109.135 269.938 111.282 272.782 114.649 272.782C118.016 272.782 120.164 269.938 120.164 265.739C120.164 261.54 118.016 258.696 114.649 258.696ZM114.649 270.209C112.908 270.209 112.095 268.274 112.095 265.739C112.095 263.204 112.908 261.269 114.649 261.269C116.391 261.269 117.223 263.204 117.223 265.739C117.223 268.274 116.391 270.209 114.649 270.209Z" fill="#FF007A"/>
<defs>
<filter id="filter0_d_126_4649" x="0" y="0" width="297.68" height="324.77" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="12.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.823529 0 0 0 0 0.87451 0 0 0 0 0.909804 0 0 0 0.44 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_126_4649"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_126_4649" result="shape"/>
</filter>
<linearGradient id="paint0_linear_126_4649" x1="254.297" y1="110.01" x2="54.0246" y2="208.695" gradientUnits="userSpaceOnUse">
<stop stopColor="#FF007A"/>
<stop offset="0.508555" stopColor="#FF6E74"/>
<stop offset="1" stopColor="#FFE175"/>
</linearGradient>
</defs>
</svg>
  )
}

export const TriangleArrow = ({ mainClassName, fillClassName }) => {
  return (
    <svg
      className={mainClassName}
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="5"
      viewBox="0 0 6 5"
      fill="none"
    >
      <path
        className={fillClassName}
        d="M1.26795 3C2.03775 4.33333 2.42265 5 3 5C3.57735 5 3.96225 4.33333 4.73205 3C5.50185 1.66667 5.88675 1 5.59808 0.5C5.3094 0 4.5396 0 3 0C1.4604 0 0.690599 0 0.401924 0.5C0.113249 1 0.498149 1.66667 1.26795 3Z"
      />
    </svg>
  );
};
