import React, { useContext, useEffect, useRef, useState } from "react";
import { useInView } from "framer-motion";
import video_white from "../../../assets/videos/operates/bg-white.mp4";
import video_black from "../../../assets/videos/operates/bg-black.mp4";
import "./Operates.css";
import { ThemeContext } from "../../../assets/contexts/themeContext";
import { THEME_LIGHT_TYPE } from "../../../assets/utils/constants";
import maestro from "../../../assets/images/operates/maestro-icon.webp";
import mastercard from "../../../assets/images/operates/mastercard-icon.webp";
import magnati from "../../../assets/images/operates/magnati-icon.webp";
import visa from "../../../assets/images/operates/visa-icon.webp";
import { TranslationContext } from "../../../assets/contexts/translationContext";
import unicredit from "../../../assets/images/magnati/logouni.webp";

const icons = [maestro, mastercard, unicredit, visa];

function Operates() {
  const { main } = useContext(TranslationContext);
  const { mainOperates } = main;

  const videoRef = useRef(null);
  const isInView = useInView(videoRef, { once: true, margin: "-100px" });
  const [isUnlocked, setIsUnlocked] = useState(true); // State to track if video is unlocked
  const [isStarted, setIsStarted] = useState(false);
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const unlockVideoAutoplay = () => {
      if (isUnlocked) return; // If already unlocked, do nothing
      setIsUnlocked(true); // Set the flag to true

      video
        .play()
        .then(() => {
          video.pause();
        })
        .catch((error) => {
          console.error("Error trying to unlock video autoplay:", error);
        });

      // document.removeEventListener("touchstart", unlockVideoAutoplay);
      // document.removeEventListener("click", unlockVideoAutoplay);
    };

    // document.addEventListener("touchstart", unlockVideoAutoplay);
    // document.addEventListener("click", unlockVideoAutoplay);

    // return () => {
    //   document.removeEventListener("touchstart", unlockVideoAutoplay);
    //   document.removeEventListener("click", unlockVideoAutoplay);
    // };
  }, [isUnlocked]); // Depend on isUnlocked state

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    function startLogosAnimation() {
      video.currentTime >= 9 ? setIsStarted(true) : setIsStarted(false);
    }

    video.addEventListener("timeupdate", startLogosAnimation);

    return () => {
      video.removeEventListener("timeupdate", startLogosAnimation);
    };
  }, [isUnlocked]); // Depend on isUnlocked state

  useEffect(() => {
    setIsStarted(false);
  }, [theme]);

  useEffect(() => {
    const video = videoRef.current;
    setIsUnlocked(false);
    if (isInView && !isUnlocked) {
      video.play().catch((error) => {
        console.error("Error trying to play the video:", error);
      });
    }
  }, [isInView, isUnlocked]); // Depend on isInView and isUnlocked states

  return (
    <div className="operates">
      <h3 className="operates__title">
        {mainOperates.title}
      </h3>
      <div className="operates__animation-block">
        {theme === THEME_LIGHT_TYPE ? (
          <video
            key={`operates__video_video_white`}
            className="operates__video"
            ref={videoRef}
            src={video_white}
            autoPlay
            muted
            playsInline
            preload="metadata"
            onError={(e) => console.error("Video error:", e)}
          >
            {mainOperates.errorVideo}
          </video>
        ) : (
          <video
            key={`operates__video_video_black`}
            className="operates__video"
            ref={videoRef}
            src={video_black}
            autoPlay
            muted
            playsInline
            preload="metadata"
            onError={(e) => console.error("Video error:", e)}
          >
            {mainOperates.errorVideo}
          </video>
        )}

        <div className="operates__logos-block">
          <div
            className={`operates__logos ${isStarted ? "operates__logos_started" : ""
              }`}
          >
            <ul className="operates__logos-list operates__logos-list_type_first-frame">
              {icons.map((icon, i) => (
                <li className={`operates__logo-item operates__logo-item-${i}`} key={`operates__logo-${i}`}>
                  <img className="operates__logo" src={icon} alt="" />
                </li>
              ))}
            </ul>
            <ul className="operates__logos-list operates__logos-list_type_second-frame">
              {icons.map((icon, i) => (
                <li className={`operates__logo-item operates__logo-item-${i}`} key={`operates__logo-${i}`}>
                  <img className="operates__logo" src={icon} alt="" />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Operates;
