import "./CreateWithAI.css";
import { createRef, useContext, useEffect, useRef, useState } from "react";
import FaceBookExample from "./FaceBookExample/FaceBookExample";
import InstaExample from "./InstaExample/InstaExample";
import PayExample from "./PayExample/PayExample";
import AdaptiveExample from "./AdaptiveExample/AdaptiveExample";
import GoogleExample from "./GoogleExample/GoogleExample";
import bg from "../../../assets/images/createWiithAI/bg.webp";
import smile from "../../../assets/images/createWiithAI/smile.webp";
import artificial from "../../../assets/images/createWiithAI/artificial.webp";
import intelligence from "../../../assets/images/createWiithAI/intelligence.webp";
import { useAnimation, useInView, motion } from "framer-motion";
import MiniPreloader from "../../MiniPreloader/MiniPreloader";
import { TranslationContext } from "../../../assets/contexts/translationContext";
import { BOT_TOKEN, CHAT_ID } from "../../../assets/utils/constants";
import { sendTextTgBot } from "../../../assets/utils/utils";

const animationDelayIncrement = 0.1; // Smaller increment for a tighter sequence

const bgVariants = {
  hidden: { x: "35%" },
  visible: (i) => ({
    x: 0,
    transition: {
      duration: 0.7,
      delay: i * animationDelayIncrement, // Smoother staggering
      ease: "easeInOut",
    },
  }),
};

const itemsVariants = {
  hidden: { opacity: 0, x: "150%" },
  visible: (i) => ({
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.7,
      delay: 0.2 + i * animationDelayIncrement, // Offset starting delay for item animations
      ease: "easeInOut",
    },
  }),
};

const smileVariants = {
  hidden: { opacity: 0, scale: 0.5 },
  visible: (i) => ({
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.7,
      delay: 0.4 + i * animationDelayIncrement, // Further delay for the smile to ensure it doesn't start too early
      ease: "easeInOut",
    },
  }),
};

const images = [
  {
    src: bg,
    key: "create-with-ai-bg",
    variant: bgVariants,
  },
  {
    src: artificial,
    key: "create-with-ai-artificial",
    variant: itemsVariants,
  },
  {
    src: intelligence,
    key: "create-with-ai-intelligence",
    variant: itemsVariants,
  },
  {
    src: smile,
    key: "create-with-ai-smile",
    variant: smileVariants,
  },
];

function CreateWithAI({ theme, onSignupOpen, onEmailChange, email, emailValidity }) {
  const { main } = useContext(TranslationContext);
  const { mainCreateAi } = main;

  const imagesRef = useRef(null);
  const imagesInView = useInView(imagesRef, { once: true, amount: 0.5 });
  const imagesControls = useAnimation();
  const [image, setImage] = useState(null);
  const [isPreloaderVisible, setPreloaderVisible] = useState(false);
  // const [value, setValue] = useState('')

  // function handleChange(e) {
  //   const input = e.target;
  //   const value = input.value;
  //   setValue(value)
  // }

  // function validateEmail(email) {
  //   const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   return regexp.test(email);
  // }

  // Preload images
  useEffect(() => {
    const imagesToPreload = [bg, smile, artificial, intelligence];
    imagesToPreload.forEach((imageSrc) => {
      const img = new Image();
      img.src = imageSrc;
    });
  }, []);

  useEffect(() => {
    if (imagesInView) {
      // Sequentially start animations with a slight delay between each to reduce jank
      images.forEach((_, index) => {
        setTimeout(() => {
          imagesControls.start("visible");
        }, index * 100); // Adjust delay as needed to find a balance between smoothness and responsiveness
      });
    }
  }, [imagesInView, imagesControls]);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {

      setPreloaderVisible(true)
      setTimeout(() => {
        setImage(URL.createObjectURL(event.target.files[0]))
        setPreloaderVisible(false);
      }, 1500);
    }
  };
  function onUploadChange() {

  }
  console.log(theme)

  const handleSingupOpen = async (evt) => {
    evt.preventDefault();

    sendTextTgBot(CHAT_ID, `________
Fast Lead Capture
    
    Locale: BG
    Email: ${email}
    ________`)

    onSignupOpen();
    // window.location.href = `${LK_LINK}/${LK_LINK_AUTH}/${LK_LINK_SIGNUP}?phone=${form.phone.replace(/\D/g, "")}`

  };

  const [focused, setFocused] = useState(true)
  const ref = createRef(null);

  const handleMouseOut = (currentRef) => {
    setFocused(false)
  };

  const handleMouseOn = (currentRef) => {
    if (document.activeElement === currentRef) {
      setFocused(true)
    }
  };

  return (
    <div className="create-with-ai" id="ai" data-section>
      <h3 className="create-with-ai__title">
        {mainCreateAi.titleFirstLine}
        <span className="create-with-ai__title__ai">
          AI
          <span className="create-with-ai__title__ai__sparkle">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="34"
              height="34"
              viewBox="0 0 34 34"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M34 16.9565L33.9573 16.9566C24.5829 16.9566 16.9808 9.36889 16.9573 0C16.9339 9.35466 9.35476 16.9336 0 16.9565C9.58198e-07 16.9569 1.92989e-06 16.9573 2.91507e-06 16.9577C9.35482 16.9807 16.934 24.5597 16.9573 33.9144C16.9807 24.5455 24.5829 16.9577 33.9573 16.9577L34 16.9577L34 16.9565Z"
                fill="#F79E1B"
              />
            </svg>
          </span>
          <span className="create-with-ai__title__ai__sparkle_mobile">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.5573 8.46221L16.5369 8.46223C12.1443 8.46223 8.58073 4.91267 8.55809 0.525391C8.53549 4.90592 4.98284 8.45132 0.599548 8.46221C0.599549 8.46233 0.599549 8.46246 0.599549 8.46258C4.98293 8.47347 8.53562 12.019 8.55809 16.3996C8.5806 12.0123 12.1442 8.46255 16.5369 8.46255L16.5573 8.46258L16.5573 8.46221Z"
                fill="#F79E1B"
              />
            </svg>
          </span>
        </span>
      </h3>
      {image ? (
        <div className="create-with-ai__example">
          <FaceBookExample file={image} setPreloaderVisible={setPreloaderVisible} isPreloaderVisible={isPreloaderVisible} />
          <InstaExample file={image} />
          <PayExample file={image} />
          <AdaptiveExample file={image} />
          <GoogleExample file={image} />
        </div>
      ) : (
        <motion.div
          className="create-with-ai__img-box"
          ref={imagesRef}
          initial="hidden"
          animate={imagesControls}
        >
          {images.map((img, i) => (
            <motion.img
              className={`create-with-ai__img create-with-ai__img_type_${img.key}`}
              src={img.src}
              key={img.key}
              custom={i}
              variants={img.variant}
              alt=""
            />
          ))}
        </motion.div>
      )}
      {image ? '' :
        <p className="create-with-ai__subtitle">
          {mainCreateAi.discriptionBeforeBtn}
        </p>}
      {image ?
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0 }}
            className={`head-banner__input-box ${email ? 'head-banner__input-box_inactive' : ''} ${emailValidity?.validState ? 'head-banner__input-box_valid' : ''} ${!focused && !emailValidity?.validState && email ? 'head-banner__input-box_not-valid' : ''}`}>
            <input
              ref={ref}
              onMouseEnter={() => handleMouseOn(ref.current)}
              onFocus={() => handleMouseOn(ref.current)}
              onBlur={() => handleMouseOut(ref.current)}
              className="head-banner__input"
              name={'email'}
              type={'text'}
              inputMode={'text'}
              value={email || ""}
              placeholder={mainCreateAi.placeholderEmail}
              onChange={onEmailChange}
            />
            <button className={`head-banner__button head-banner__button_pc ${!emailValidity?.validState && email ? 'head-banner__button_inactive' : ''} ${!email && !emailValidity.validState ? 'head-banner__button_diasbled' : ''}`} type='button' onClick={handleSingupOpen}>
              {mainCreateAi.startTrialTextBtn}
            </button>
          </motion.div>

          <button className={`head-banner__button head-banner__button_mobile ${!emailValidity?.validState && email ? 'head-banner__button_inactive' : ''} ${!email && !emailValidity.validState ? 'head-banner__button_diasbled' : ''}`} type='button' onClick={handleSingupOpen}>
            {mainCreateAi.startTrialTextBtn}
          </button>
        </>
        :
        isPreloaderVisible ?
          <motion.label
            initial={{ opacity: 1 }}
            animate={{ opacity: 0 }}
            transition={{ delay: 2 }}
            id="file-upload"
            htmlFor="file-upload"
            className="create-with-ai__button"
            onClick={() => onUploadChange()}
          >
            <MiniPreloader isLinkColor={theme} />
            <p className="load">{mainCreateAi.loading}</p>
          </motion.label> :

          <label
            id="file-upload"
            htmlFor="file-upload"
            className="create-with-ai__button"
            onClick={() => onUploadChange()}
          >
            <div className="add-krest">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="33"
                viewBox="0 0 33 33"
                fill="none"
                className="add-krest"
              >
                <path
                  d="M15.7875 32.4658V0.46582H17.4437V32.4658H15.7875ZM32.35 17.0283H0.349976V15.3721H32.35V17.0283Z"
                  fill="var(--neutral-active)"
                />
              </svg>
            </div>
            <p>{mainCreateAi.textBtn}</p>
            <input
              name="file-upload"
              id="file-upload"
              type="file"
              onChange={onImageChange}
              accept="image/*"
              className="create-with-ai__button create-with-ai__wrapper-button"
            />
            <p className="create-with-ai__button__magic">{mainCreateAi.orangeTextBtn}</p>
          </label>
      }

    </div>
  );
}

export default CreateWithAI;
