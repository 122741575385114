import { motion } from "framer-motion";
import logo from "../../../assets/images/magnati/logouni.webp";
import "./Magnati.css";
import { TranslationContext } from "../../../assets/contexts/translationContext";
import unicredit from '../../../assets/images/magnati/bulbankBlack.webp'
import unicreditWhite from '../../../assets/images/magnati/bulbankWhite.webp'
import { useContext, useRef, useEffect, useState, useMemo, useCallback } from "react";
import ReactCurvedText from "react-curved-text";

function Magnati({ theme, language, lang }) {
  const ellipses = Array(4).fill("");
  const { main } = useContext(TranslationContext);
  const { mainMagnati } = main;
  const { animateText } = mainMagnati;

  const word = [
    animateText.first,
    animateText.second,
    animateText.third,
    animateText.forth,
    animateText.five,
    animateText.six,
    animateText.seven,
    animateText.eight,
    animateText.nine,
  ];

  const hiddenTextRef = useRef(null);
  const [textWidths, setTextWidths] = useState([]);
  const [arrayWord, setArrayWord] = useState([]);

  const getTextWidth = useCallback((text, fontSize) => {
    if (hiddenTextRef.current) {
      const span = hiddenTextRef.current;
      span.style.fontSize = `${fontSize}px`;
      span.innerText = text;
      return span.offsetWidth;
    }
    return 0;
  }, []);

  // console.log(word)

  useEffect(() => {
    if (word !== []) {
      setArrayWord(word)
    }
  }, [])

  useEffect(() => {
    setArrayWord(word)
  }, [language, lang])

  useEffect(() => {
    setTextWidths(arrayWord.map((item) => getTextWidth(item, 24)));
  }, []);

  useEffect(() => {
    setTextWidths(arrayWord.map((item) => getTextWidth(item, 24)));
  }, [arrayWord]);


  const itemsVariants = {
    hidden: { opacity: 0, scale: 0.3 },
    visible: (i) => ({
      scale: 1,
      opacity: [0, 1, 0],
      transition: {
        duration: 6,
        times: [0, 0.5, 1],
        delay: i * 2,
        ease: "easeInOut",
        repeat: Infinity,
        repeatDelay: 2,
      },
    }),
  };

  const wordsVariants = {
    hidden: { opacity: 0, y: "10%", scale: 0.6 },
    visible: (i) => ({
      scale: 1,
      opacity: [0, 1, 0],
      y: "20%",
      transition: {
        duration: 3,
        times: [0, 0.5, 1],
        delay: 3 * i,
        ease: "linear",
        repeatDelay: 3 * (word.length - 1),
        repeat: Infinity,
      },
    }),
  };

  const cxdesc = 250;
  const cydesc = 50;
  const rxdesc = 250;
  const rydesc = 50;
  const cxmob = 150;
  const cymob = 40;
  const rxmob = 150;
  const rymob = 40;

  const circumferenceDesc = Math.PI * (3 * (rxdesc + rydesc) - Math.sqrt((3 * rxdesc + rydesc) * (rxdesc + 3 * rydesc)));
  const circumferenceMobile = Math.PI * (3 * (rxmob + rymob) - Math.sqrt((3 * rxmob + rymob) * (rxmob + 3 * rymob)));

  const getStartOffset = (textWidth, circumference) => (circumference / 4) - (textWidth / 2);

  return (
    <div className="magnati">
      <span
        ref={hiddenTextRef}
        style={{ visibility: "hidden", position: "absolute", whiteSpace: "nowrap" }}
      ></span>
      {theme !== 'dark' ?
        <img src={unicredit} alt="" className="magnati__img"></img>
        :
        <img src={unicreditWhite} alt="" className="magnati__img"></img>
      }
      <p className="magnati__title">{mainMagnati.title}</p>
      <p className="magnati__subtitle">{mainMagnati.text}</p>
      <div className="magnati__img-block">
        <img className="magnati__logo" src={logo} alt="Magnati Logo" />
        <div className="magnati__anim-block">
          {ellipses.map((_, i) => (
            <motion.div
              className="magnati__ellipse"
              key={`magnati-ellipse-${i}`}
              custom={i}
              initial="hidden"
              animate="visible"
              variants={itemsVariants}
            />
          ))}
          {word.map((item, i) => {
            const textWidth = textWidths[i] || 0;
            const startOffsetDesc = getStartOffset(textWidth, circumferenceDesc);

            return (
              <motion.div
                className="magnati__word magnati__word_pc"
                key={`magnati-word-${i}`}
                custom={i}
                initial="hidden"
                animate="visible"
                variants={wordsVariants}
              >
                <ReactCurvedText
                  width={500}
                  height={120}
                  cx={cxdesc}
                  cy={cydesc}
                  rx={rxdesc}
                  ry={rydesc}
                  startOffset={startOffsetDesc}
                  reversed={false}
                  text={item}
                  textProps={{ style: { fontSize: 24 } }}
                  textPathProps={{
                    style: theme === "dark" ? { fill: "white" } : { fill: "#000" },
                  }}
                />
              </motion.div>
            );
          })}

          {word.map((item, i) => {
            const textWidthMobile = textWidths[i] ? getTextWidth(item, 16) : 0;
            const startOffsetMobile = getStartOffset(textWidthMobile, circumferenceMobile);

            return (
              <motion.div
                className="magnati__word magnati__word_mobile"
                key={`magnati-word-${i}`}
                custom={i}
                initial="hidden"
                animate="visible"
                variants={wordsVariants}
              >
                <ReactCurvedText
                  width={300}
                  height={90}
                  cx={cxmob}
                  cy={cymob}
                  rx={rxmob}
                  ry={rymob}
                  startOffset={startOffsetMobile}
                  reversed={false}
                  text={item}
                  textProps={{ style: { fontSize: 16 } }}
                  textPathProps={{
                    style: theme === "dark" ? { fill: "white" } : { fill: "#000" },
                  }}
                />
              </motion.div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Magnati;