import { useLayoutEffect, useState } from "react";
import { THEME_DARK_TYPE, THEME_LIGHT_TYPE } from "../utils/constants";

const isDarkTheme = window?.matchMedia("(prefers-color-scheme: dark)").matches;
const defaulTheme = isDarkTheme ? THEME_DARK_TYPE : THEME_LIGHT_TYPE;

export const useTheme = () => {
    const [theme, setTheme] = useState(localStorage.getItem("app-theme") || defaulTheme);
    useLayoutEffect(() => {
        document.documentElement.setAttribute("data-theme", theme);
        localStorage.setItem("app-theme", theme);
    }, [theme]);

    return { theme, setTheme };
};