
import './GoogleExample.css';
import google from '../../../../assets/images/createWiithAI/google.webp'
import search from '../../../../assets/images/createWiithAI/search.webp'
import circle from '../../../../assets/images/createWiithAI/googleCircle.webp'
import {motion} from 'framer-motion'
import { useContext } from 'react';
import { TranslationContext } from '../../../../assets/contexts/translationContext';

function GoogleExample({ file }) {
    const { main } = useContext(TranslationContext);
    const { mainCreateAi } = main;
    const { google } = mainCreateAi;

    return (
        <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1.5 }}
         className='google-example'>
            <div className='google-example__card'>
          <img alt='' src={google} className='google-example__card__logo'></img>
          <img alt='' src={search} className='google-example__card__search'></img>
                <div className='facebook-example__card__img-box'>
                    <div className='facebook-example__photo-card'>
                        <img src={file} alt='' className='google-example__photo-card__img'></img>

                        <p className='facebook-example__photo-card__price'>$ 100.90</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="69" height="6" viewBox="0 0 69 6" fill="none">
                            <path d="M1.89586 0C2.36658 0 2.74887 0.114212 3.04274 0.342635C3.33921 0.571058 3.51736 0.964309 3.57717 1.52239H2.8945C2.85289 1.26541 2.75797 1.05256 2.60974 0.883842C2.4615 0.712524 2.22354 0.626866 1.89586 0.626866C1.44855 0.626866 1.12867 0.844906 0.936228 1.28099C0.811398 1.56392 0.748982 1.91304 0.748982 2.32836C0.748982 2.74627 0.837404 3.09799 1.01425 3.38352C1.19109 3.66905 1.46936 3.81181 1.84905 3.81181C2.14032 3.81181 2.37048 3.72356 2.53952 3.54705C2.71116 3.36794 2.82949 3.12395 2.8945 2.81506H3.57717C3.49915 3.36794 3.3041 3.77287 2.99203 4.02985C2.67995 4.28423 2.28076 4.41142 1.79444 4.41142C1.2483 4.41142 0.812698 4.21285 0.487619 3.8157C0.16254 3.41596 0 2.91759 0 2.32057C0 1.58858 0.178143 1.01882 0.53443 0.611291C0.890717 0.203764 1.34453 0 1.89586 0Z" fill="#009B22" />
                            <path d="M5.93725 3.83907C6.40276 3.83907 6.72134 3.66385 6.89298 3.31343C7.06722 2.96042 7.15434 2.56846 7.15434 2.13757C7.15434 1.74822 7.09193 1.43154 6.9671 1.18754C6.76945 0.803374 6.42877 0.611291 5.94505 0.611291C5.51594 0.611291 5.20387 0.774822 5.00882 1.10188C4.81377 1.42894 4.71625 1.82349 4.71625 2.28553C4.71625 2.7294 4.81377 3.09929 5.00882 3.3952C5.20387 3.69111 5.51334 3.83907 5.93725 3.83907ZM5.96455 0C6.50288 0 6.95799 0.179104 7.32988 0.537313C7.70178 0.895522 7.88772 1.42245 7.88772 2.11811C7.88772 2.7904 7.72388 3.34588 7.3962 3.78456C7.06852 4.22323 6.5601 4.44257 5.87093 4.44257C5.29619 4.44257 4.83978 4.24919 4.5017 3.86243C4.16361 3.47307 3.99457 2.95133 3.99457 2.29721C3.99457 1.59637 4.17272 1.03829 4.529 0.622972C4.88529 0.207657 5.36381 0 5.96455 0Z" fill="#009B22" />
                            <path d="M8.72643 0.120701H9.42079V0.712524C9.58723 0.507463 9.73807 0.358209 9.8733 0.264763C10.1048 0.106424 10.3674 0.027255 10.6613 0.027255C10.9942 0.027255 11.262 0.10902 11.4649 0.27255C11.5793 0.365996 11.6833 0.503569 11.777 0.685269C11.933 0.462038 12.1164 0.29721 12.327 0.190785C12.5377 0.0817651 12.7743 0.027255 13.037 0.027255C13.5987 0.027255 13.981 0.229721 14.1839 0.634653C14.2931 0.852693 14.3477 1.14601 14.3477 1.5146V4.29072H13.6182V1.3939C13.6182 1.11616 13.548 0.925373 13.4076 0.821544C13.2697 0.717716 13.1007 0.665801 12.9004 0.665801C12.6248 0.665801 12.3868 0.757949 12.1866 0.942245C11.9889 1.12654 11.8901 1.43413 11.8901 1.86502V4.29072H11.1762V1.56911C11.1762 1.28618 11.1424 1.07982 11.0748 0.950032C10.9682 0.755354 10.7692 0.658014 10.478 0.658014C10.2127 0.658014 9.97083 0.760545 9.75237 0.965607C9.53652 1.17067 9.4286 1.54186 9.4286 2.07917V4.29072H8.72643V0.120701Z" fill="#009B22" />
                            <path d="M17.1447 3.8196C17.4724 3.8196 17.7441 3.68332 17.96 3.41077C18.1784 3.13563 18.2877 2.7255 18.2877 2.1804C18.2877 1.84815 18.2395 1.56262 18.1433 1.32382C17.9613 0.864374 17.6284 0.634653 17.1447 0.634653C16.6584 0.634653 16.3255 0.877352 16.146 1.36275C16.0498 1.62232 16.0017 1.95198 16.0017 2.35172C16.0017 2.67359 16.0498 2.94744 16.146 3.17326C16.3281 3.60415 16.661 3.8196 17.1447 3.8196ZM15.3268 0.140169H16.0095V0.693056C16.1499 0.503569 16.3034 0.356911 16.4698 0.253082C16.7065 0.0973394 16.9847 0.0194679 17.3046 0.0194679C17.7779 0.0194679 18.1797 0.201168 18.51 0.564568C18.8403 0.925373 19.0054 1.44192 19.0054 2.11421C19.0054 3.02271 18.7675 3.67164 18.2916 4.061C17.9899 4.30759 17.6388 4.43089 17.2383 4.43089C16.9236 4.43089 16.6597 4.3621 16.4464 4.22453C16.3216 4.14666 16.1824 4.01298 16.029 3.82349V5.95328H15.3268V0.140169Z" fill="#009B22" />
                            <path d="M20.3669 3.18105C20.3669 3.38352 20.441 3.54315 20.5892 3.65996C20.7374 3.77677 20.913 3.83517 21.1158 3.83517C21.3629 3.83517 21.6022 3.77807 21.8336 3.66385C22.2237 3.47437 22.4188 3.16418 22.4188 2.73329V2.16872C22.3329 2.22323 22.2224 2.26866 22.0872 2.305C21.9519 2.34134 21.8193 2.36729 21.6893 2.38287L21.2641 2.43738C21.0092 2.47112 20.8181 2.52433 20.6906 2.59701C20.4748 2.71901 20.3669 2.91369 20.3669 3.18105ZM22.0677 1.76379C22.2289 1.74302 22.3368 1.67554 22.3915 1.56132C22.4227 1.49903 22.4383 1.40947 22.4383 1.29267C22.4383 1.05386 22.3524 0.881246 22.1808 0.774822C22.0118 0.665801 21.7686 0.611291 21.4513 0.611291C21.0846 0.611291 20.8246 0.709929 20.6711 0.907203C20.5853 1.01622 20.5294 1.17846 20.5034 1.3939H19.848C19.861 0.879948 20.0275 0.523037 20.3474 0.323167C20.6698 0.120701 21.043 0.0194679 21.4669 0.0194679C21.9584 0.0194679 22.3576 0.112914 22.6645 0.299805C22.9688 0.486697 23.1209 0.777417 23.1209 1.17197V3.5743C23.1209 3.64698 23.1352 3.70539 23.1638 3.74951C23.195 3.79364 23.2588 3.8157 23.355 3.8157C23.3862 3.8157 23.4213 3.81441 23.4603 3.81181C23.4993 3.80662 23.5409 3.80013 23.5851 3.79234V4.31019C23.4759 4.34134 23.3927 4.3608 23.3355 4.36859C23.2783 4.37638 23.2002 4.38027 23.1014 4.38027C22.8596 4.38027 22.684 4.29461 22.5748 4.1233C22.5176 4.03245 22.4773 3.90396 22.4539 3.73783C22.3108 3.92472 22.1054 4.08696 21.8375 4.22453C21.5697 4.3621 21.2745 4.43089 20.952 4.43089C20.5645 4.43089 20.2472 4.31408 20.0002 4.08047C19.7557 3.84426 19.6335 3.54964 19.6335 3.19663C19.6335 2.80986 19.7544 2.51006 19.9963 2.29721C20.2381 2.08436 20.5554 1.95328 20.9481 1.90396L22.0677 1.76379Z" fill="#009B22" />
                            <path d="M24.2756 0.120701H24.9427V0.712524C25.1403 0.468527 25.3497 0.293316 25.5707 0.186892C25.7918 0.0804672 26.0375 0.027255 26.308 0.027255C26.9009 0.027255 27.3014 0.233615 27.5095 0.646334C27.6239 0.872161 27.6811 1.19533 27.6811 1.61583V4.29072H26.9673V1.66256C26.9673 1.40818 26.9296 1.20311 26.8541 1.04737C26.7293 0.7878 26.5031 0.658014 26.1754 0.658014C26.0089 0.658014 25.8724 0.674886 25.7658 0.708631C25.5733 0.765737 25.4043 0.879948 25.2586 1.05127C25.1416 1.18884 25.0649 1.3316 25.0285 1.47956C24.9947 1.62492 24.9778 1.83387 24.9778 2.10642V4.29072H24.2756V0.120701Z" fill="#009B22" />
                            <path d="M31.3324 0.120701H32.1087C32.0099 0.38806 31.7901 0.998053 31.4495 1.95068C31.1946 2.6671 30.9813 3.25114 30.8097 3.70279C30.404 4.76703 30.1179 5.41596 29.9515 5.64958C29.7851 5.88319 29.499 6 29.0933 6C28.9945 6 28.9177 5.99611 28.8631 5.98832C28.8111 5.98053 28.7461 5.96626 28.6681 5.94549V5.30694C28.7903 5.34069 28.8787 5.36145 28.9333 5.36924C28.988 5.37703 29.0361 5.38092 29.0777 5.38092C29.2077 5.38092 29.3026 5.35886 29.3624 5.31473C29.4249 5.2732 29.4769 5.22128 29.5185 5.15899C29.5315 5.13822 29.5783 5.0318 29.6589 4.83971C29.7395 4.64763 29.7981 4.50487 29.8345 4.41142L28.2897 0.120701H29.0855L30.2051 3.5159L31.3324 0.120701Z" fill="#009B22" />
                            <path d="M32.7173 0.120701H33.3843V0.712524C33.582 0.468527 33.7913 0.293316 34.0124 0.186892C34.2334 0.0804672 34.4792 0.027255 34.7497 0.027255C35.3426 0.027255 35.7431 0.233615 35.9512 0.646334C36.0656 0.872161 36.1228 1.19533 36.1228 1.61583V4.29072H35.4089V1.66256C35.4089 1.40818 35.3712 1.20311 35.2958 1.04737C35.171 0.7878 34.9447 0.658014 34.617 0.658014C34.4506 0.658014 34.3141 0.674886 34.2074 0.708631C34.015 0.765737 33.8459 0.879948 33.7003 1.05127C33.5833 1.18884 33.5066 1.3316 33.4701 1.47956C33.4363 1.62492 33.4194 1.83387 33.4194 2.10642V4.29072H32.7173V0.120701Z" fill="#009B22" />
                            <path d="M37.7027 3.18105C37.7027 3.38352 37.7768 3.54315 37.925 3.65996C38.0733 3.77677 38.2488 3.83517 38.4517 3.83517C38.6987 3.83517 38.938 3.77807 39.1694 3.66385C39.5595 3.47437 39.7546 3.16418 39.7546 2.73329V2.16872C39.6688 2.22323 39.5582 2.26866 39.423 2.305C39.2878 2.34134 39.1551 2.36729 39.0251 2.38287L38.5999 2.43738C38.345 2.47112 38.1539 2.52433 38.0265 2.59701C37.8106 2.71901 37.7027 2.91369 37.7027 3.18105ZM39.4035 1.76379C39.5647 1.74302 39.6727 1.67554 39.7273 1.56132C39.7585 1.49903 39.7741 1.40947 39.7741 1.29267C39.7741 1.05386 39.6883 0.881246 39.5166 0.774822C39.3476 0.665801 39.1044 0.611291 38.7871 0.611291C38.4205 0.611291 38.1604 0.709929 38.007 0.907203C37.9211 1.01622 37.8652 1.17846 37.8392 1.3939H37.1839C37.1969 0.879948 37.3633 0.523037 37.6832 0.323167C38.0057 0.120701 38.3788 0.0194679 38.8027 0.0194679C39.2943 0.0194679 39.6935 0.112914 40.0003 0.299805C40.3046 0.486697 40.4567 0.777417 40.4567 1.17197V3.5743C40.4567 3.64698 40.4711 3.70539 40.4997 3.74951C40.5309 3.79364 40.5946 3.8157 40.6908 3.8157C40.722 3.8157 40.7571 3.81441 40.7961 3.81181C40.8351 3.80662 40.8768 3.80013 40.921 3.79234V4.31019C40.8117 4.34134 40.7285 4.3608 40.6713 4.36859C40.6141 4.37638 40.5361 4.38027 40.4372 4.38027C40.1954 4.38027 40.0198 4.29461 39.9106 4.1233C39.8534 4.03245 39.8131 3.90396 39.7897 3.73783C39.6467 3.92472 39.4412 4.08696 39.1733 4.22453C38.9055 4.3621 38.6103 4.43089 38.2878 4.43089C37.9003 4.43089 37.583 4.31408 37.336 4.08047C37.0915 3.84426 36.9693 3.54964 36.9693 3.19663C36.9693 2.80986 37.0902 2.51006 37.3321 2.29721C37.5739 2.08436 37.8912 1.95328 38.2839 1.90396L39.4035 1.76379Z" fill="#009B22" />
                            <path d="M41.6114 0.120701H42.3058V0.712524C42.4722 0.507463 42.6231 0.358209 42.7583 0.264763C42.9898 0.106424 43.2524 0.027255 43.5463 0.027255C43.8792 0.027255 44.147 0.10902 44.3499 0.27255C44.4643 0.365996 44.5684 0.503569 44.662 0.685269C44.818 0.462038 45.0014 0.29721 45.212 0.190785C45.4227 0.0817651 45.6593 0.027255 45.922 0.027255C46.4837 0.027255 46.866 0.229721 47.0689 0.634653C47.1781 0.852693 47.2327 1.14601 47.2327 1.5146V4.29072H46.5032V1.3939C46.5032 1.11616 46.433 0.925373 46.2926 0.821544C46.1547 0.717716 45.9857 0.665801 45.7854 0.665801C45.5098 0.665801 45.2718 0.757949 45.0716 0.942245C44.8739 1.12654 44.7751 1.43413 44.7751 1.86502V4.29072H44.0612V1.56911C44.0612 1.28618 44.0274 1.07982 43.9598 0.950032C43.8532 0.755354 43.6542 0.658014 43.363 0.658014C43.0977 0.658014 42.8558 0.760545 42.6374 0.965607C42.4215 1.17067 42.3136 1.54186 42.3136 2.07917V4.29072H41.6114V0.120701Z" fill="#009B22" />
                            <path d="M50.0063 0.027255C50.3027 0.027255 50.5901 0.0973394 50.8684 0.237508C51.1467 0.375081 51.3586 0.554186 51.5042 0.774822C51.6447 0.985075 51.7383 1.23037 51.7851 1.51071C51.8267 1.70279 51.8475 2.00908 51.8475 2.42959H48.7853C48.7983 2.85269 48.8984 3.19273 49.0857 3.44971C49.2729 3.70409 49.5629 3.83128 49.9556 3.83128C50.3223 3.83128 50.6148 3.71058 50.8333 3.46918C50.9581 3.32901 51.0465 3.16677 51.0985 2.98248H51.789C51.7708 3.13563 51.7097 3.30694 51.6057 3.49643C51.5042 3.68332 51.3898 3.83647 51.2624 3.95587C51.0491 4.16353 50.7852 4.3037 50.4705 4.37638C50.3014 4.41791 50.1103 4.43868 49.897 4.43868C49.3769 4.43868 48.9361 4.25049 48.5746 3.87411C48.2131 3.49513 48.0324 2.96561 48.0324 2.28553C48.0324 1.61583 48.2144 1.07203 48.5785 0.654121C48.9426 0.23621 49.4185 0.027255 50.0063 0.027255ZM51.1258 1.87281C51.0972 1.56911 51.0309 1.32641 50.9269 1.14471C50.7345 0.807268 50.4133 0.638546 49.9634 0.638546C49.6409 0.638546 49.3704 0.755354 49.152 0.988968C48.9335 1.21999 48.8178 1.5146 48.8048 1.87281H51.1258Z" fill="#009B22" />
                            <path d="M52.8813 3.44192H53.6966V4.29072H52.8813V3.44192Z" fill="#009B22" />
                            <path d="M56.5482 0C57.0189 0 57.4012 0.114212 57.695 0.342635C57.9915 0.571058 58.1697 0.964309 58.2295 1.52239H57.5468C57.5052 1.26541 57.4103 1.05256 57.262 0.883842C57.1138 0.712524 56.8758 0.626866 56.5482 0.626866C56.1009 0.626866 55.781 0.844906 55.5885 1.28099C55.4637 1.56392 55.4013 1.91304 55.4013 2.32836C55.4013 2.74627 55.4897 3.09799 55.6666 3.38352C55.8434 3.66905 56.1217 3.81181 56.5014 3.81181C56.7926 3.81181 57.0228 3.72356 57.1918 3.54705C57.3635 3.36794 57.4818 3.12395 57.5468 2.81506H58.2295C58.1515 3.36794 57.9564 3.77287 57.6443 4.02985C57.3323 4.28423 56.9331 4.41142 56.4467 4.41142C55.9006 4.41142 55.465 4.21285 55.1399 3.8157C54.8148 3.41596 54.6523 2.91759 54.6523 2.32057C54.6523 1.58858 54.8305 1.01882 55.1867 0.611291C55.543 0.203764 55.9968 0 56.5482 0Z" fill="#009B22" />
                            <path d="M60.5896 3.83907C61.0551 3.83907 61.3736 3.66385 61.5453 3.31343C61.7195 2.96042 61.8066 2.56846 61.8066 2.13757C61.8066 1.74822 61.7442 1.43154 61.6194 1.18754C61.4218 0.803374 61.0811 0.611291 60.5974 0.611291C60.1683 0.611291 59.8562 0.774822 59.6611 1.10188C59.4661 1.42894 59.3686 1.82349 59.3686 2.28553C59.3686 2.7294 59.4661 3.09929 59.6611 3.3952C59.8562 3.69111 60.1656 3.83907 60.5896 3.83907ZM60.6169 0C61.1552 0 61.6103 0.179104 61.9822 0.537313C62.3541 0.895522 62.54 1.42245 62.54 2.11811C62.54 2.7904 62.3762 3.34588 62.0485 3.78456C61.7208 4.22323 61.2124 4.44257 60.5232 4.44257C59.9485 4.44257 59.4921 4.24919 59.154 3.86243C58.8159 3.47307 58.6469 2.95133 58.6469 2.29721C58.6469 1.59637 58.825 1.03829 59.1813 0.622972C59.5376 0.207657 60.0161 0 60.6169 0Z" fill="#009B22" />
                            <path d="M63.3787 0.120701H64.0731V0.712524C64.2395 0.507463 64.3904 0.358209 64.5256 0.264763C64.7571 0.106424 65.0197 0.027255 65.3136 0.027255C65.6465 0.027255 65.9144 0.10902 66.1172 0.27255C66.2316 0.365996 66.3357 0.503569 66.4293 0.685269C66.5853 0.462038 66.7687 0.29721 66.9793 0.190785C67.19 0.0817651 67.4266 0.027255 67.6893 0.027255C68.251 0.027255 68.6333 0.229721 68.8362 0.634653C68.9454 0.852693 69 1.14601 69 1.5146V4.29072H68.2705V1.3939C68.2705 1.11616 68.2003 0.925373 68.0599 0.821544C67.922 0.717716 67.753 0.665801 67.5527 0.665801C67.2771 0.665801 67.0391 0.757949 66.8389 0.942245C66.6412 1.12654 66.5424 1.43413 66.5424 1.86502V4.29072H65.8285V1.56911C65.8285 1.28618 65.7947 1.07982 65.7271 0.950032C65.6205 0.755354 65.4215 0.658014 65.1303 0.658014C64.865 0.658014 64.6231 0.760545 64.4047 0.965607C64.1888 1.17067 64.0809 1.54186 64.0809 2.07917V4.29072H63.3787V0.120701Z" fill="#009B22" />
                        </svg>

                    </div>
                    <div>
                        <img src={file} alt='' className='google-example__photo-card__img'></img>
                        <p className='facebook-example__photo-card__price'>$ 100.90</p>
                    </div>
                </div>
                <img alt='' className='google__circle' src={circle}></img>
            </div>
            <p className='google-example__text'>{google.textAfterCard}</p>
        </motion.div>
    );
}

export default GoogleExample