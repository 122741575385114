import './Blog.css';
import tabsmile from '../../assets/images/blog/tabSmile.webp'
import { useContext, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ScrollToTop from '../../assets/hooks/scrollToTop';
import MainSmallCard from './MainSmallCard/MainSmallCard';
import SubscribeForm from './SubscribeForm/SubscribeForm';


import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Mousewheel, Pagination } from "swiper/modules";
import "swiper/css";
import 'swiper/css/pagination';
import 'swiper/css/autoplay'
import MainBigCard from './MainBigCard/MainBigCard';
import NormalCard from './NormalCard/NormalCard';
import useWindowSize from '../../assets/hooks/useWindowSize';
import mainApi from '../../assets/api/MainApi';
import { CARDS_LIMIT, E_COMMERCE, MARKETING_AND_SALES, ONLINE_STORE, SMM, SOCIAL_BLOG, STARTING_UP, TECH, VALIDE_LANG } from '../../assets/utils/constants';
import arrow from '../../assets/images/blog/Vector.webp';
import MiniPreloader from '../../components/MiniPreloader/MiniPreloader'
import { TranslationContext } from '../../assets/contexts/translationContext';

function Blog({ searchValue = "", theme, searchKey, setSearchKey, setSignupValues, setSignupValidity, language, setLanguage, onEmailChange, email, emailValidity }) {
    const { blog } = useContext(TranslationContext);
    const defaultTabId = 'g0ssabynlaa93j01lly23k6x'

    const tabTitleRef = useRef();
    const [tabsMenu, setTabsMenu] = useState([])
    const [cardsData, setCardsData] = useState([])
    const [sortDataByTags, setSortDataByTags] = useState([])
    const [isPreloaderVisible, setPreloderVisible] = useState(true);
    const [isPreloaderTabsVisible, setPreloderTabsVisible] = useState(true);
    const [isPreloaderSwiperVisible, setPreloderSwiperVisible] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const [isMore, setIsMore] = useState(false);

    const page = Number(searchParams.get("page"));
    const tabs = String(searchParams.get("tag"));
    // console.log(tabs !== String(null))

    function changeTab(thisType, start) {
        setSearchParams(params => {
            params.set("tag", thisType);
            return params;
        });
        getArticlesCards({ start: start, currentTag: thisType, locale: language })
        setSearchParams(params => {
            params.set("page", 1);
            return params;
        });
    }
    async function getArticlesCards({ start, currentTag }) {
        setPreloderVisible(true);
        return mainApi
            .getAllArticlesByTag({
                locale: String(language?.toLowerCase()),
                start: start.toString(),
                limit: (CARDS_LIMIT + 1).toString(),
                currentTag: currentTag ? currentTag : tabs !== String(null) ? tabs : defaultTabId
            })
            .then((res) => {
                setSortDataByTags(res.slice(0, CARDS_LIMIT))
                setIsMore(res.length > CARDS_LIMIT);
                return res
            })
            .catch((err) => {
                console.log(err)
                return err
            })
            .finally(() => {
                setPreloderVisible(false);
            })
    }

    // const navigate = useNavigate();
    // function handleKeyChange(evt) {
    //     setSearchKey(evt.target.value);
    // }
    // function handleSearchSubmit(evt) {
    //     evt.preventDefault();
    //     navigate(`/search?value=${searchKey}`);
    //     setSearchKey("");
    // }

    function getPage(page) {
        if (page <= 1) {
            setSearchParams((params) => {
                params.set("page", 1);
                return params;
            });
        }
    }
    function getTags(tabs) {
        if (tabs === String(null)) {
            setSearchParams((params) => {
                params.set("tag", defaultTabId);
                return params;
            });
        }
    }

    useEffect(() => {
        localStorage.setItem('page', JSON.stringify(page));
        localStorage.setItem('tag', JSON.stringify(tabs));
    }, [page, tabs])

    useEffect(() => {
        if (localStorage.getItem("page")) {
            setSearchParams((params) => {
                params.set("page", JSON.parse(localStorage.getItem("page")));
                return params;
            });
            getPage(page);
        } else {
            const startPage = page > 0 ? page : 1;
            setSearchParams((params) => {
                params.set("page", startPage);
                return params;
            });
            getPage(startPage);
        }

        if (localStorage.getItem("tag") !== null) {
            setSearchParams((params) => {
                params.set("tag", JSON.parse(localStorage.getItem("tag")));
                return params;
            });
            getTags(tabs)
        } else {
            const startTag = tabs !== String(null) ? tabs : defaultTabId;
            setSearchParams((params) => {
                params.set("tag", startTag);
                return params;
            });
            getTags(startTag)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getArticlesCards({ start: page > 1 ? ((page - 1) * CARDS_LIMIT) : 0 })
        // setSearchKey(searchValue);
        setPreloderTabsVisible(true);
        mainApi.getAllTags()
            .then((res) => {
                setTabsMenu(res)
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setPreloderTabsVisible(false);
            })
        setPreloderSwiperVisible(true);
        mainApi.getAllArticles({ locale: String(language.toLowerCase()) })
            .then((res) => {
                setCardsData(res)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setPreloderSwiperVisible(false);
            })
        getArticlesCards({ start: ((page - 1) * CARDS_LIMIT) })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue, language]);

    function scrollToTabs() {
        tabTitleRef.current.scrollIntoView({
            behavior: "smooth",
        });
    }

    function MoreArticles() {
        if (!isMore) return;
        setSearchParams((params) => {
            params.set("page", page + 1);
            return params;
        });
        getArticlesCards({ start: page * CARDS_LIMIT });
        scrollToTabs();
    }

    function LessArticles() {
        if (page === 1) { return null }
        else {
            setSearchParams(params => {
                params.set("page", page - 1);
                return params;
            });
        }
        getArticlesCards({ start: (page - 2) * CARDS_LIMIT });
        scrollToTabs();
    }

    const window = useWindowSize()
    const [progressTime, setProgress] = useState(1)

    const onAutoplayTimeLeft = (s, time, progress) => {
        setProgress(1 - progress)
    };

    const [my_swiper, set_my_swiper] = useState({});
    const tagTitle = tabsMenu.find(tagsName => tagsName.documentId === tabs)
    const totalLimitCards = cardsData.length + 2

    const lang = searchParams.get("lang");

    useEffect(() => {
        if (lang && VALIDE_LANG.includes(lang)) {
            setLanguage(lang);
            localStorage.setItem("language", lang);
        }
        else {
            setSearchParams(params => {
                params.set("lang", language);
                return params;
            });
            localStorage.setItem("language", language);
        }
    }, [])

    return (
        <>
            <ScrollToTop />
            <div className='blog'>
                <p className='blog__title'>{blog.title}</p>
                <div className='blog__main-grid'>
                    {isPreloaderSwiperVisible ?
                        <>
                            <div className='blog__main-grid_small-card'>
                                <div className='blog__preloader-gradient'></div>
                                <div className='blog__preloader-gradient'></div>
                            </div>
                            <div className='blog__preloader-gradient blog__preloader-gradient_long'></div>
                            <div className='blog__main-grid_small-card'>
                                <div className='blog__preloader-gradient'></div>
                                <div className='blog__preloader-gradient'></div>
                            </div>
                        </>
                        :
                        (
                            window.width > 960 ?
                                <>
                                    <div className='blog__main-grid_small-card'>
                                        {cardsData.in_main_4?.slice(0, 2).map((item, i) => {
                                            return (
                                                <MainSmallCard item={item} i={i} key={`small-${item.documentId}`} />
                                            )
                                        })}
                                    </div>
                                    <div className='blog__card-swiper-wrapper'>
                                        <Swiper
                                            className="main-card-swiper"
                                            modules={[Mousewheel, Autoplay, Pagination]}
                                            autoplay={{
                                                delay: 3000,
                                                disableOnInteraction: false,
                                            }}
                                            mousewheel={{
                                                forceToAxis: true,
                                            }}
                                            slidesPerView={1}

                                            onInit={(ev) => {
                                                set_my_swiper(ev)
                                            }}
                                            onAutoplayTimeLeft={onAutoplayTimeLeft}
                                        >
                                            {cardsData?.in_slider?.map((item, i) => {
                                                return (
                                                    <SwiperSlide key={`slide-${item.documentId}`} className='blog__main-grid__img__slide'>
                                                        <MainBigCard item={item} i={i} key={`big-${item.documentId}`} />
                                                    </SwiperSlide>
                                                )
                                            })}
                                        </Swiper>
                                        <div className='autoplay-progress__numbers-box'>
                                            {cardsData?.in_slider?.map((item, i) => {
                                                return (
                                                    <button className='autoplay-progress__numbers' type='button' key={`autoplay-progress__numbers-${item.documentId}`} onClick={() => my_swiper.slideTo(i)}>
                                                        <p className="autoplay-progress__number">{i + 1}</p>
                                                        {my_swiper.activeIndex === i ?
                                                            <div className="autoplay-progress" slot="container-end">
                                                                <svg viewBox="0 0 48 48" style={{ strokeDashoffset: 125.6 * (1 - progressTime) }}>
                                                                    <circle cx="24" cy="24" r="20"></circle>
                                                                </svg>
                                                            </div>
                                                            : null}
                                                    </button>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className='blog__main-grid_small-card'>
                                        {cardsData?.in_main_4?.slice(2, 4).map((item, i) => {
                                            return (
                                                <MainSmallCard item={item} i={i} key={`small-2-${item.documentId}`} />
                                            )
                                        })}
                                    </div>
                                </>
                                :
                                <>
                                    <Swiper
                                        className="main-card-swiper"
                                        modules={[Mousewheel, Autoplay, Pagination]}
                                        autoplay={{
                                            delay: 3000,
                                            disableOnInteraction: false,
                                        }}
                                        mousewheel={{
                                            forceToAxis: true,
                                        }}
                                        slidesPerView={1}
                                        onInit={(ev) => {
                                            set_my_swiper(ev)
                                        }}
                                        onAutoplayTimeLeft={onAutoplayTimeLeft}
                                    >
                                        {cardsData?.in_slider?.map((item, i) => {
                                            return (
                                                <>
                                                    <SwiperSlide className='blog__main-grid__img__slide' key={`blog__main-grid__img__slide-mobile-${item.documentId}`}>
                                                        <MainBigCard item={item} i={i} key={`big-mobile-${item.documentId}`} />
                                                    </SwiperSlide>
                                                </>
                                            )
                                        })}
                                    </Swiper>

                                    <div className='autoplay-progress__numbers-box'>
                                        {cardsData?.in_slider?.map((item, i) => {
                                            return (
                                                <button className='autoplay-progress__numbers' type='button' key={`autoplay-progress__numbers-${item.documentId}`} onClick={() => my_swiper.slideTo(i)}>
                                                    <p className="autoplay-progress__number">{i + 1}</p>
                                                    {my_swiper.activeIndex === i ?
                                                        <div className="autoplay-progress" slot="container-end">
                                                            <svg viewBox="0 0 48 48" style={{ strokeDashoffset: 125.6 * (1 - progressTime) }}>
                                                                <circle cx="24" cy="24" r="20"></circle>
                                                            </svg>
                                                        </div>
                                                        : null}
                                                </button>
                                            )
                                        })}
                                    </div>
                                    <div className='blog__main-grid_small-card'>
                                        {cardsData?.in_main_4?.map((item, i) => {
                                            return (
                                                <MainSmallCard item={item} i={i} key={`small-mobile-${item.documentId}`} />
                                            )
                                        })}
                                    </div>
                                </>
                        )}
                </div>

                {/* <div className="search-bar">
                    <form className="search-bar__form" onSubmit={handleSearchSubmit}>
                        <input
                            className="search-bar__input"
                            id="globalSearch"
                            name="search"
                            type="text"
                            placeholder=""
                            value={searchKey || ""}
                            onChange={handleKeyChange}
                        />
                        <button
                            className="search-bar__submit-btn"
                            type="submit"
                            aria-label="Confirm search"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="36" viewBox="0 0 47 51" fill="none">
                                <path d="M32.4095 19.3985C32.4095 17.5365 32.0542 15.793 31.3436 14.168C30.6669 12.543 29.7364 11.1212 28.5521 9.9024C27.3678 8.68365 25.9805 7.71881 24.3902 7.00787C22.7999 6.29694 21.0911 5.94147 19.264 5.94147C17.4706 5.94147 15.7788 6.29694 14.1885 7.00787C12.5982 7.71881 11.2109 8.68365 10.0266 9.9024C8.84231 11.1212 7.91181 12.543 7.23508 14.168C6.52451 15.793 6.16923 17.5365 6.16923 19.3985C6.16923 21.2266 6.52451 22.9532 7.23508 24.5782C7.91181 26.2032 8.84231 27.6251 10.0266 28.8438C11.2109 30.0626 12.5982 31.0274 14.1885 31.7383C15.7788 32.4493 17.4706 32.8048 19.264 32.8048C21.0911 32.8048 22.7999 32.4493 24.3902 31.7383C25.9805 31.0274 27.3678 30.0626 28.5521 28.8438C29.7364 27.6251 30.6669 26.2032 31.3436 24.5782C32.0542 22.9532 32.4095 21.2266 32.4095 19.3985ZM29.4149 35.6993C27.96 36.6811 26.3696 37.4343 24.644 37.959C22.9183 38.4838 21.125 38.7462 19.264 38.7462C16.6586 38.7462 14.2054 38.2383 11.9045 37.2227C9.63747 36.2071 7.64958 34.8191 5.94083 33.0587C4.23209 31.2982 2.87016 29.2501 1.85507 26.9141C0.873809 24.5443 0.383179 22.0391 0.383179 19.3985C0.383179 16.724 0.873809 14.2019 1.85507 11.8321C2.87016 9.49615 4.23209 7.44798 5.94083 5.68756C7.64958 3.92714 9.63747 2.53912 11.9045 1.5235C14.2054 0.507874 16.6586 6.10352e-05 19.264 6.10352e-05C21.9032 6.10352e-05 24.3564 0.507874 26.6234 1.5235C28.9243 2.53912 30.9291 3.92714 32.6379 5.68756C34.3466 7.44798 35.7085 9.49615 36.7236 11.8321C37.7049 14.2019 38.1955 16.724 38.1955 19.3985C38.1955 21.7344 37.7979 23.9688 37.0028 26.1016C36.2076 28.2344 35.1333 30.1303 33.7798 31.7891L45.5042 45.5508C46.0456 46.1941 46.2909 46.9219 46.2402 47.7344C46.1894 48.5469 45.8595 49.224 45.2504 49.7657C44.6414 50.3074 43.9393 50.5528 43.1441 50.502C42.349 50.4512 41.6807 50.1042 41.1393 49.461L29.4149 35.6993Z" fill="var(--neutral-active)" />
                            </svg>
                        </button>
                    </form>
                </div> */}

                <div className='subscribe__box'>
                    <SubscribeForm {...{ onEmailChange, email, emailValidity, setSignupValues, setSignupValidity }} />
                </div>
                {isPreloaderTabsVisible ? (
                    <MiniPreloader isLinkColor={theme} />
                ) : (
                    <>
                        <div className='tabs-menu'>
                            {tabsMenu.map((item, i) => {
                                return (
                                    <button
                                        key={`tabs-${item.documentId}`}
                                        onClick={() => changeTab(item.documentId, 0)}
                                        className={`tabs ${tabs === item.documentId ? 'tabs_selected' : ''}`}>
                                        {item.title === ONLINE_STORE ? `${blog.tags.onlinestore}` :
                                            item.title === SMM ? `${blog.tags.smm}` :
                                                item.title === STARTING_UP ? `${blog.tags.startup}` :
                                                    item.title === E_COMMERCE ? `${blog.tags.ecomm}` :
                                                        item.title === TECH ? `${blog.tags.tech}` :
                                                            item.title === MARKETING_AND_SALES ? `${blog.tags.marketing}` :
                                                                ''
                                        }
                                    </button>
                                )
                            }
                            )}
                            <img alt='' src={tabsmile} className={`tabs_smile`}></img>
                        </div>

                        <p className='blog__title blog__title_starting' ref={tabTitleRef}>
                            {tagTitle ?
                                tagTitle.title === ONLINE_STORE ? `${blog.tags.onlinestore}` :
                                    tagTitle.title === SMM ? `${blog.tags.smm}` :
                                        tagTitle.title === STARTING_UP ? `${blog.tags.startup}` :
                                            tagTitle.title === E_COMMERCE ? `${blog.tags.ecomm}` :
                                                tagTitle.title === TECH ? `${blog.tags.tech}` :
                                                    tagTitle.title === MARKETING_AND_SALES ? `${blog.tags.marketing}` : '' : ''}
                        </p>
                        <div className='blog__main-grid_starting'>
                            {isPreloaderVisible ?
                                <MiniPreloader isLinkColor={theme} />
                                :
                                sortDataByTags.length > 0 ?
                                    (sortDataByTags?.map((item, i) => {
                                        return (
                                            <NormalCard i={i} item={item} key={`blog__main-grid_starting-${item.documentId}`} />
                                        )
                                    })
                                    )
                                    :
                                    <p>{blog.emptyStatus}</p>
                            }
                        </div>
                        {!isMore && page === 1 ?
                            null :
                            <div className='pagination__box'>
                                <button className={`arrow__box ${page === 1 ? ' arrow__box_inactive' : ''}`} onClick={LessArticles}>
                                    <img src={arrow} className='arrow-back' alt='' />
                                </button>
                                <button className='pagination__box__page'>{page}</button>
                                <button className={`arrow__box ${!isMore ? ' arrow__box_inactive' : ''}`} onClick={MoreArticles}>
                                    <img src={arrow} className={`arrow`} alt='' />
                                </button>
                            </div>
                        }
                    </>
                )}
                <div className='follow-social'>
                    <p className='follow-social__title'>{blog.followInSocial}</p>
                    <div className='follow-social__grid'>
                        {SOCIAL_BLOG.map((item, i) => {
                            return (
                                <a target='_blank' rel='noreferrer' href={item.link} key={`follow-social-${i}`}>
                                    <img alt='' src={item.img} className={`follow-social__icon follow-social__icon-${i + 1}`}></img>
                                </a>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Blog