import { ORDERED_LIST_FORMAT } from "../../../../assets/utils/constants";
import { renderArticleChildren } from "../../../../assets/utils/utils";
import "./ListElement.css";

function ListElement({ data }) {
  const { format, children } = data;

  return (
    <div className="list-element">
      {format === ORDERED_LIST_FORMAT ? (
        <ol className="list-element__list list-element__list_type_ordered">
          {children.map((item, i) => (
            <li className="list-element__item" key={`${format}-${i}`}>
              {renderArticleChildren(item.children)}
            </li>
          ))}
        </ol>
      ) : (
        <ul className="list-element__list">
          {children.map((item, i) => (
            <li className="list-element__item" key={`${format}-${i}`}>
              {renderArticleChildren(item.children)}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default ListElement;
