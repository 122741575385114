import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';

import './Join.css';

// import author_1 from '../../../assets/images/join/author-1.webp'
// import author_2 from '../../../assets/images/join/author-2.webp'
import card_1 from '../../../assets/images/join/card-1.webp'
import card_2 from '../../../assets/images/join/card-2.webp'
import card_3 from '../../../assets/images/join/card-3.webp'
import card_4 from '../../../assets/images/join/card-4.webp'
import card_5 from '../../../assets/images/join/card-5.webp'

import card_6 from '../../../assets/images/join/card-6.webp'
import card_7 from '../../../assets/images/join/card-7.webp'
import card_8 from '../../../assets/images/join/card-8.webp'
import card_9 from '../../../assets/images/join/card-9.webp'
import card_10 from '../../../assets/images/join/card-10.webp'
import card_101 from '../../../assets/images/join/card-101.webp'

import card_11 from '../../../assets/images/join/card-11.webp'
import card_12 from '../../../assets/images/join/card-12.webp'
import card_13 from '../../../assets/images/join/card-13.webp'
import card_14 from '../../../assets/images/join/card-14.webp'
import card_15 from '../../../assets/images/join/card-15.webp'

import card_16 from '../../../assets/images/join/card-16.webp'
import card_17 from '../../../assets/images/join/card-17.webp'
import card_18 from '../../../assets/images/join/card-18.webp'
import card_19 from '../../../assets/images/join/card-19.webp'
import card_20 from '../../../assets/images/join/card-20.webp'

import card_21 from '../../../assets/images/join/card-21.webp'
import card_22 from '../../../assets/images/join/card-22.webp'
import card_23 from '../../../assets/images/join/card-23.webp'
import card_24 from '../../../assets/images/join/card-24.webp'
import card_25 from '../../../assets/images/join/card-25.webp'
import useWindowSize from '../../../assets/hooks/useWindowSize';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import { useSearchParams } from 'react-router-dom';

const JoinCard = ({ item }) => {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });
    const [hoverStyle, setHoverStyle] = useState({ scale: 1, x: 0, y: 0 });

    // Appearance animation variants
    const cardVariants = {
        visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
        hidden: { opacity: 0, scale: 0.95 }
    };

    // Handle mouse move for hover effect
    const handleMouseMove = (e) => {
        const cardRect = e.currentTarget.getBoundingClientRect();
        const centerX = cardRect.left + cardRect.width / 2;
        const centerY = cardRect.top + cardRect.height / 2;
        const deltaX = e.clientX - centerX;
        const deltaY = e.clientY - centerY;

        // Calculate the percentage of the distance from the center to the edge
        const percentX = deltaX / (cardRect.width / 2);
        const percentY = deltaY / (cardRect.height / 2);

        // Define the maximum translation distance (in pixels)
        const maxTranslate = 10;

        // Set the hover style with calculated translation
        setHoverStyle({
            scale: 1.05,
            x: percentX * maxTranslate,
            y: percentY * maxTranslate,
        });
    };

    // Reset the hover style when the mouse leaves the card
    const handleMouseLeave = () => {
        setHoverStyle({ scale: 1, x: 0, y: 0 });
    };


    return (
        <motion.div
            className='join__card'
            ref={ref}
            variants={cardVariants}
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            whileHover={{ ...hoverStyle, transition: { type: 'spring', stiffness: 300, damping: 20 } }}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}

        >
            <img className='join__card-img' src={item.img} alt='' />
            <p className='join__card-title'>{item.title}</p>
            {/* <div className='join__card-author'>
                <img className='join__card-author-img' src={item.author.img} alt='' />
                <p className='join__card-author-name'>{item.author.name}</p>
            </div> */}
        </motion.div>
    );
};

function Join() {
    const [searchParams, setSearchParams] = useSearchParams();
    const lang = searchParams.get("lang");

    const { main } = useContext(TranslationContext);
    const { mainJoin } = main;
    const { joinCards } = mainJoin;
    const { column1, column2, column3, column4, column5 } = joinCards;

    const COLUMN_1 = [
        {
            img: card_1,
            title: column1.title1,
        },
        {
            img: card_2,
            title: column1.title2,
        },
        {
            img: card_3,
            title: column1.title3,
        },
        {
            img: card_4,
            title: column1.title4,
        },
        {
            img: card_5,
            title: column1.title5,
        },

    ]

    const COLUMN_2 = [
        {
            img: card_6,
            title: column2.title1,
        },
        {
            img: card_7,
            title: column2.title2,
        },
        {
            img: card_8,
            title: column2.title3,
        },
        {
            img: card_9,
            title: column2.title4,
        },
        {
            img: card_10,
            title: column2.title5,
        },
        {
            img: card_101,
            title: column2.title6,
        },
    ]


    const COLUMN_3 = [
        {
            img: card_11,
            title: column3.title1,
        },
        {
            img: card_12,
            title: column3.title2,
        },
        {
            img: card_13,
            title: column3.title3,
        },
        {
            img: card_14,
            title: column3.title4,
        },
        {
            img: card_15,
            title: column3.title5,
        },

    ]

    const COLUMN_4 = [
        {
            img: card_16,
            title: column4.title1,
        },
        {
            img: card_17,
            title: column4.title2,
        },
        {
            img: card_18,
            title: column4.title3,
        },
        {
            img: card_19,
            title: column4.title4,
        },
        {
            img: card_20,
            title: column4.title5,
        },

    ]


    const COLUMN_5 = [
        {
            img: card_21,
            title: column5.title1,
        },
        {
            img: card_22,
            title: column5.title2,
        },
        {
            img: card_23,
            title: column5.title3,
        },
        {
            img: card_24,
            title: column5.title4,
        },
        {
            img: card_25,
            title: column5.title5,
        },

    ]
    const COLUMNS_PC = [
        COLUMN_1,
        COLUMN_2,
        COLUMN_3,
        COLUMN_4,
        COLUMN_5
    ]

    const COLUMNS_TAB = [
        COLUMN_1.concat([COLUMN_5[1]]),
        COLUMN_2.concat([COLUMN_5[2], COLUMN_5[0]]),
        COLUMN_3.concat([COLUMN_5[3]]),
        COLUMN_4.concat([COLUMN_5[4]]),
    ]

    const COLUMNS_MOBILE = [
        COLUMN_1.concat([COLUMN_5[1], COLUMN_4[0], COLUMN_4[1]]),
        COLUMN_2.concat([COLUMN_5[2], COLUMN_5[0], COLUMN_4[2], COLUMN_4[4]]),
        COLUMN_3.concat([COLUMN_5[3], COLUMN_5[4], COLUMN_4[3]]),
    ]

    const { width } = useWindowSize()

    const COLUMNS = width >= 1050 ? COLUMNS_PC : width >= 700 ? COLUMNS_TAB : COLUMNS_MOBILE
    const controls = useAnimation();
    const ref = useRef(null);

    // Splitting the title into individual letters
    // const title = "Join the ranks of thousands of businesses that already thrive with Sellavi";
    const titleLetters = Array.from(mainJoin.title);

    // Letter animation variants
    const letterVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 },
    };

    useEffect(() => {
        // Observer for detecting when the title comes into view
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    // Start the animation for each letter
                    controls.start("visible");
                    // Disconnect observer after triggering animation
                    observer.disconnect();
                }
            },
            { threshold: 0.1 } // Adjust threshold based on when you want the animation to start
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [controls]);

    const refType = useRef(null)
    const isVisible = useOnScreen(refType)

    function useOnScreen() {
        const [isIntersecting, setIntersecting] = useState(false)

        const observer = useMemo(() => new IntersectionObserver(
            ([entry]) => { setIntersecting(entry.isIntersecting) }, { threshold: 0.1 }
        ), [ref])

        useEffect(() => {
            observer.observe(ref.current)
            return () => observer.disconnect()
        }, [])

        return isIntersecting
    }
    const [currentText, setCurrentText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);

    const TypedAnimation = ({ text, delay }) => {
        useEffect(() => {
            if (currentIndex < text.length) {
                const timeout = setTimeout(() => {
                    setCurrentText(prevText => prevText + text[currentIndex]);
                    setCurrentIndex(prevIndex => prevIndex + 1);
                }, delay);

                return () => clearTimeout(timeout);
            }
        }, [currentIndex, delay, text]);

        return <span>{currentText}</span>;
    };

    useEffect(() => {
        setCurrentIndex(0)
        setCurrentText('')
    }, [lang])

    return (
        <div className='join' ref={ref} id='examples' data-section>
            <h3 className='join__title'>
                {/* {titleLetters.map((letter, i) => (
                    <motion.span
                        key={i}
                        variants={letterVariants}
                        initial="hidden"
                        animate={controls}
                        transition={{ delay: i * 0.02, type: 'tween' }}
                    >
                        {letter}
                    </motion.span>
                ))} */}
                {isVisible ?
                    <TypedAnimation ref={refType} text={mainJoin.title} delay={60} />
                    : currentText}
            </h3>
            <div className='join__content' style={{
                gridTemplateColumns: `repeat(${COLUMNS.length}, minMax(0, 1fr))`,
            }}>
                {COLUMNS.map((column, column_i) => (
                    <div className='join__content-column' key={`column_${column_i}`}>
                        {column.map((item, i) => (
                            <JoinCard key={`join__card_column_${column_i}_${i}`} item={item} />
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Join