import { useContext, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import { DropArrowIcon } from '../../../assets/icons/price';
import './FAQ.css';
import { INFO_EMAIL } from '../../../assets/utils/constants';
import { TranslationContext } from '../../../assets/contexts/translationContext';

function FAQ() {
    const { prices } = useContext(TranslationContext);
    const { faq } = prices;
    const { questionAnswer } = faq;
    const QUESTIONS = [
        {
            _id: 1,
            question: `${questionAnswer.question1}`,
            answer: `${questionAnswer.answer1}`,
        },
        {
            _id: 2,
            question: `${questionAnswer.question2}`,
            answer: `${questionAnswer.answer2}`,
        },
        {
            _id: 3,
            question: `${questionAnswer.question3}`,
            answer: `${questionAnswer.answer3}`
        },
        {
            _id: 4,
            question: `${questionAnswer.question4}`,
            answer: `${questionAnswer.answer4}`
        },
        {
            _id: 5,
            question: `${questionAnswer.question5}`,
            answer: `${questionAnswer.answer5}`
        },
        {
            _id: 6,
            question: `${questionAnswer.question6}`,
            answer: `${questionAnswer.answer6}`
        },
        {
            _id: 7,
            question: `${questionAnswer.question7}`,
            answer: `${questionAnswer.answer7}`
        },
        {
            _id: 8,
            question: `${questionAnswer.question8}`,
            answer: `${questionAnswer.answer8}`
        },
        {
            _id: 9,
            question: `${questionAnswer.question9}`,
            answer: `${questionAnswer.answer9}`
        },
    ]

    const [openItemId, setOpenItemId] = useState(QUESTIONS[0]._id);

    const toggleItem = (id) => {
        if (openItemId === id) {
            setOpenItemId(null);
        } else {
            setOpenItemId(id);
        }
    };

    return (
        <div className='faq'>
            <h4 className='faq__title'>{faq.title}</h4>
            <p className='faq__subtitle'>{faq.text}</p>
            <div className='faq__items'>
                {QUESTIONS.map((item, i) => (
                    <button className={`faq__item ${openItemId === item._id ? 'faq__item_selected' : ''}`} type='button' key={`faq__item${item._id}`} onClick={() => toggleItem(item._id)}>
                        <div className='faq__item-info'>
                            <p className='faq__item-info-question'
                                dangerouslySetInnerHTML={{
                                    __html: item.question,
                                }}
                            ></p>
                            <AnimatePresence>
                                {openItemId === item._id && (
                                    <motion.div
                                        initial={{ opacity: 0, height: 0, y: -20, }}
                                        animate={{ opacity: 1, height: 'auto', y: 0, }}
                                        exit={{ opacity: 0, height: 0, y: -20, }}
                                        transition={{ duration: 0.3 }}
                                    >
                                        <p className='faq__item-info-answer'
                                            dangerouslySetInnerHTML={{
                                                __html: item.answer,
                                            }}
                                        ></p>
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </div>
                        <DropArrowIcon
                            mainClassName={'faq__item-arrow'}
                            strokeClassName={'faq__item-arrow-stroke'}
                        />
                    </button>
                ))}

            </div>
        </div>
    );
}

export default FAQ