import { motion } from "framer-motion";
import { CheckIcon } from "../../assets/icons/icons";
import "./Checkbox.css";

function Checkbox({ onChange, ...props }) {
  return (
    <div className={`checkbox`}>
      <div className="checkbox__container">
        <label className="checkbox__box" htmlFor={props.name}>
          <input
            className="checkbox__invisible-input"
            id={props.name}
            name={props.name}
            type="checkbox"
            value={props.checked}
            checked={props.checked}
            onChange={onChange}
          />
          <span
            className={`checkbox__pseudo-item ${
              props.checked ? "checkbox__pseudo-item_checked" : ""
            }`}
          >
            {CheckIcon({
              mainClassName: "checkbox__check-icon",
              strokeClassName: "checkbox__check-icon-stroke",
            })}
          </span>
        </label>
        <span
          className="checkbox__label"
          dangerouslySetInnerHTML={{ __html: props.label }}
        />
      </div>

      <motion.div
        className="checkbox__error-box"
        initial={{
          height: "0px",
          marginTop: "0px",
          opacity: 0,
          visibility: "hidden",
        }}
        animate={{
          height: props.error ? "14px" : "0px",
          marginTop: props.error ? "4px" : "0px",
          opacity: props.error ? 1 : 0,
          visibility: props.error ? "visible" : "hidden",
        }}
        transition={{ duration: 0.15 }}
      >
        {props.error ? <p className="checkbox__error">{props.error}</p> : null}
      </motion.div>
    </div>
  );
}

export default Checkbox;
